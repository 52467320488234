import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    territoriesBoundaries: {},
    projectsCentroid: {}
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_TERRITOTIES_BOUNDARIES_SUCCESS:
        case actionTypes.GET_TERRITOTIES_BOUNDARIES_FAIL:
            return false;
        case actionTypes.GET_TERRITOTIES_BOUNDARIES_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const territoriesBoundaries = (state = initialState.territoriesBoundaries, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_TERRITOTIES_BOUNDARIES_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.territoriesBoundaries;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getTerritoriesBoundaries = state => get(state, 'territoriesBoundaries', initialState.territoriesBoundaries);

const reducer = combineReducers({
    isLoading,
    territoriesBoundaries
});

export default reducer;
