import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const BASE_I18N_KEY = 'activityDetailsActions';

const propTypes = {
    variant: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    payload: PropTypes.shape({})
};

const defaultProps = {
    payload: {}
};

const ProjectActivityDetailsLabel = ({ variant, label, payload }) => {
    const { t } = useTranslation();

    const getI18nLabel = () => `${BASE_I18N_KEY}.${label}`;

    return (
        <Typography variant={variant}>
            {t(getI18nLabel(), payload)}
        </Typography>
    );
};

ProjectActivityDetailsLabel.propTypes = propTypes;
ProjectActivityDetailsLabel.defaultProps = defaultProps;

export default ProjectActivityDetailsLabel;
