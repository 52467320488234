import * as Yup from 'yup';

const formOptions = {
    firstName: {
        required: true
    },
    lastName: {
        required: true
    },
    email: {
        required: true
    },
    phoneNumber: {
        required: true
    },
    jobRole: {
        required: false,
        type: 'text'
    }
};

const getValidationSchema = () => {
    return Yup.object().shape({
        firstName: Yup.string().required('mandatory_field'),
        lastName: Yup.string().required('mandatory_field'),
        email: Yup.string().email('invalid_email').required('mandatory_field'),
        phoneNumber: Yup.string().required('mandatory_field'),
        jobRole: Yup.string().optional()
    });
};

export default {
    formOptions,
    getValidationSchema
};
