import * as actionTypes from './actionTypes';
import { uuidv4 } from '../../../../utils/uuid';
import { dateSeparatorByDots } from '../../../../utils/date-time';

import { actions as notificationsActions } from '../../../notifications';

export const getTimelinePhotos = projectId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_TIMELINE_START });

    try {
        const response = await apiSdk.projectTimeline.list(projectId);
        const { data } = response;

        const photos = data.map(photo => ({
            filename: uuidv4(),
            caption: dateSeparatorByDots(photo.date),
            fileUrl: photo.fileUrl
        }));

        dispatch({ type: actionTypes.GET_TIMELINE_SUCCESS, payload: photos });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchTimeline'));
        dispatch({ type: actionTypes.GET_TIMELINE_FAIL });
    }
};

export const reset = () => ({
    type: actionTypes.RESET
});
