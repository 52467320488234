import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../../redux/modal-manager';
import { dateSeparatorByDots } from '../../../utils/date-time';
import ImageGrid from '../../../components/image-grid';
import useStyles from './ProjectAlbumRow.styles';

const propTypes = {
    album: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        createdAt: PropTypes.string,
        files: PropTypes.arrayOf(
            PropTypes.shape({
                fileUrl: PropTypes.string,
                filename: PropTypes.string,
                caption: PropTypes.string,
                albumId: PropTypes.number,
                createdAt: PropTypes.string
            })
        )
    }).isRequired,
    hasActions: PropTypes.bool
};

const defaultProps = {
    hasActions: false
};

const ProjectAlbumRow = ({ album, hasActions }) => {
    const { id, name, createdAt, files } = album;
    const classes = useStyles();
    const dispatch = useDispatch();

    const openImagePreview = useCallback(
        image => {
            dispatch(modalManagerActions.openModal(modalManagerEnumerators.IMAGE_PREVIEW, image));
        },
        [dispatch]
    );

    const onEditAlbum = useCallback(() => {
        dispatch(
            modalManagerActions.openModal(modalManagerEnumerators.CREATE_ALBUM, { type: modalManagerEnumerators.TYPE_EDIT, selectedAlbum: album })
        );
    }, [dispatch, album]);

    const gridItemSizes = !hasActions ? { xs: 6, sm: 4, md: 4, lg: 4 } : undefined;

    return (
        <Box key={id.toString()} className={classes.container}>
            <Box flex={1} className={classes.header}>
                <Typography variant="h3" className={classes.albumTitle}>
                    {name}
                    <span className={classes.albumDate}>{` - ${dateSeparatorByDots(createdAt)}`}</span>
                </Typography>

                {hasActions && (
                    <IconButton aria-label="edit" className={classes.actionBtn} onClick={onEditAlbum}>
                        <EditIcon />
                    </IconButton>
                )}
            </Box>

            <Box flex={1}>
                <ImageGrid images={files} onGridItemClick={openImagePreview} gridItemSizes={gridItemSizes} />
            </Box>
        </Box>
    );
};

ProjectAlbumRow.propTypes = propTypes;
ProjectAlbumRow.defaultProps = defaultProps;

export default ProjectAlbumRow;
