import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './BaseLoading.styles';

const BaseLoading = ({ ...rest }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress {...rest} />
        </div>
    );
};

export default BaseLoading;
