/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
import createApiSdk from './api-sdk';

export default function thunkBwApiSdkMiddleware() {
    return ({ dispatch, getState }) => next => action => {
        if (typeof action !== 'function') {
            return next(action);
        }

        createApiSdk(apiSdk => action(dispatch, getState, { apiSdk }));
    };
}
