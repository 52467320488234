import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@material-ui/core';

import useStyles from './BaseTabs.styles';

const propTypes = {
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func,
    variant: PropTypes.string
};

const defaultProps = {
    onChange: null,
    variant: 'fullWidth'
};

const BaseTabs = ({ children, onChange, variant }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = useCallback(
        (event, newValue) => {
            setValue(newValue);

            if (onChange) {
                onChange(newValue);
            }
        },
        [setValue, onChange]
    );

    return (
        <Tabs classes={classes} variant={variant} value={value} onChange={handleChange}>
            {children}
        </Tabs>
    );
};

BaseTabs.propTypes = propTypes;
BaseTabs.defaultProps = defaultProps;

export default BaseTabs;
