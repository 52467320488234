/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

const propTypes = {
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.object.isRequired,
    optionLabel: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool
};

const defaultProps = {
    required: false
};

const BaseAutoComplete = ({ options, selectedOption, optionLabel, id, onChange, required, ...rest }) => {
    const { t } = useTranslation();

    const defaultOption = {
        [optionLabel]: ''
    };

    return (
        <Autocomplete
            {...rest}
            id={id}
            disableClearable
            options={[defaultOption, ...options]}
            getOptionSelected={option => option[optionLabel] === selectedOption[optionLabel]}
            getOptionLabel={option => option[optionLabel] || ''}
            filterOptions={filterOptions => filterOptions.filter(option => option[optionLabel] !== '')}
            value={selectedOption}
            noOptionsText={t('no_options')}
            onChange={onChange(id)}
            renderInput={params => <TextField {...params} required label={t(id)} key={id} id={id} multiline={false} />}
        />
    );
};

BaseAutoComplete.propTypes = propTypes;
BaseAutoComplete.defaultProps = defaultProps;

export default BaseAutoComplete;
