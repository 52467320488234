import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Box, Typography, Button, IconButton } from '@material-ui/core';
import { Add as AddIcon, Close as EraseIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import config from '../../../../_config';

import { actions as formProjectActions, selectors as formProjectSelectors } from '../../../../redux/form/project';

import BaseInput, { BaseInputLatitude, BaseInputLongitude } from '../../../base-input';
import SurveillanceAreaMap from '../surveillance-area-map';

import useStyles from './SurveillanceArea.styles';
import surveillanceAreaHelper from './helper';

const coordinateInitialState = {
    latitude: '',
    longitude: ''
};

const SurveillanceArea = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { mapConfigs } = config;

    // state
    const [error, setError] = useState('');
    const [newCoordinates, setNewCoordinates] = useState(coordinateInitialState);

    // state props
    const coordinatesList = useSelector(formProjectSelectors.getCoordinates);
    const changeCluster = useSelector(formProjectSelectors.getChangeCluster);

    const handleChangeCoordinates = useCallback(
        type => ev => {
            setNewCoordinates({
                ...newCoordinates,
                [type]: ev.target.value
            });
            setError('');
        },
        [newCoordinates, setNewCoordinates, setError]
    );

    const handleResetCoordinates = useCallback(() => setNewCoordinates(coordinateInitialState), [setNewCoordinates]);

    const handleInsertPoint = useCallback(() => {
        if (!surveillanceAreaHelper.checkCoordinates(newCoordinates.latitude, newCoordinates.longitude)) {
            setError('projects.createModal.invalidCoordinate');
        } else if (surveillanceAreaHelper.existCoordinates(newCoordinates, coordinatesList)) {
            setError('projects.createModal.coordinateAlreadyExists');
        } else {
            dispatch(formProjectActions.insertCoordinate(newCoordinates));
            setNewCoordinates(coordinateInitialState);
        }
    }, [dispatch, newCoordinates, setNewCoordinates, coordinatesList]);

    const handleRemoveCoordinate = useCallback(
        index => {
            dispatch(formProjectActions.removeCoordinate(index));
        },
        [dispatch]
    );

    return (
        <Box className={classes.container}>
            <Typography variant="h3" className={classes.title}>
                {t('projects.createModal.surveillanceArea')}
            </Typography>
            <Typography variant="caption" className={classes.caption} paragraph>
                {t('projects.createModal.minCoordsPairs')}
            </Typography>
            <Box className={classes.coordinatesContainer}>
                <Box className={classes.coordinatesInputs}>
                    <BaseInputLatitude
                        className={classes.inputLeft}
                        required
                        value={newCoordinates.latitude}
                        onChange={handleChangeCoordinates('latitude')}
                    />
                    <BaseInputLongitude
                        className={classes.inputRight}
                        required
                        value={newCoordinates.longitude}
                        onChange={handleChangeCoordinates('longitude')}
                    />
                    <IconButton className={classes.deleteButton} onClick={handleResetCoordinates}>
                        <EraseIcon />
                    </IconButton>
                </Box>
                {error && (
                    <Typography variant="subtitle2" className={classes.error}>
                        {t(error)}
                    </Typography>
                )}
                {!isEmpty(coordinatesList) &&
                    coordinatesList.map((coordinate, index) => (
                        <Box className={classes.coordinateListContainer} key={`${coordinate.latitude}-${coordinate.longitude}`}>
                            <Box className={classes.coordinatesInputs}>
                                <BaseInput
                                    className={classes.inputLeft}
                                    key={`lat-${coordinate.latitude}`}
                                    label={t('latitude')}
                                    name={`lat-${coordinate.latitude}`}
                                    required
                                    value={coordinate.latitude}
                                    disabled
                                />
                                <BaseInput
                                    className={classes.inputRight}
                                    key={`long-${coordinate.longitude}`}
                                    label={t('longitude')}
                                    name={`long-${coordinate.longitude}`}
                                    required
                                    value={coordinate.longitude}
                                    disabled
                                />
                            </Box>
                            <IconButton className={classes.deleteButton} onClick={() => handleRemoveCoordinate(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                <Button
                    onClick={handleInsertPoint}
                    startIcon={<AddIcon />}
                    disabled={
                        newCoordinates.latitude === '' ||
                        newCoordinates.longitude === '' ||
                        !surveillanceAreaHelper.checkCoordinates(newCoordinates.latitude, newCoordinates.longitude)
                    }
                    color="primary"
                    variant="contained"
                    className={classes.insertPointButton}
                >
                    {t('projects.createModal.addNewPoint')}
                </Button>
                <MapContainer
                    className={classes.mapContainer}
                    scrollWheelZoom={false}
                    center={mapConfigs.center}
                    zoomControl={mapConfigs.zoomControl}
                >
                    <>
                        <TileLayer attribution={mapConfigs.disclaimer.title} url={mapConfigs.disclaimer.link} />
                        <SurveillanceAreaMap geoJSON={changeCluster} />
                    </>
                </MapContainer>
            </Box>
        </Box>
    );
};

export default SurveillanceArea;
