import enTranslations from './en.json';
import ptTranslations from './pt.json';

const resources = {
    en: {
        translation: {
            ...enTranslations
        }
    },
    pt: {
        translation: {
            ...ptTranslations
        }
    }
};

export default resources;
