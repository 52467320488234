import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    link: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textDecoration: 'none',
        '&:hover, &:focus': {}
    },
    linkActive: {},
    linkNested: {
        paddingLeft: 0,
        '&:hover, &:focus': {}
    },
    linkIcon: {
        marginLeft: theme.spacing(0.1),
        color: 'black',
        transition: theme.transitions.create('color'),
        display: 'flex',
        justifyContent: 'center',
        minWidth: '35px'
    },
    linkIconActive: {
        color: theme.palette.primary.main
    },
    linkTextParent: {
        marginBottom: 0
    },
    linkText: {
        padding: 0,
        color: 'black',
        transition: theme.transitions.create(['opacity', 'color']),
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: theme.spacing(-1)
    },
    linkTextActive: {
        color: theme.palette.text.link
    },
    linkTextHidden: {
        opacity: 0
    },
    nestedList: {
        paddingLeft: theme.spacing(2) + 30
    },
    sectionTitle: {
        marginLeft: theme.spacing(4.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        height: 1,
        backgroundColor: '#D8D8D880'
    }
}));
