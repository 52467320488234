import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    childButton: {
        color: theme.palette.black,
        '&:not(:last-child)': {
            marginRight: '1rem'
        }
    }
}));

export default useStyles;
