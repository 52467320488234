import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../../modal-manager/actionTypes';

import { actions as notificationsActions } from '../../notifications';
import { actions as priorCommunicationActions } from '../../projects/project/prior-communication';

export const updatePriorCommunication = (projectId, priorCommunicationToSubmit) => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.UPDATE_PRIOR_COMMUNICATION_START });

    try {
        await apiSdk.projectCommunication.update(projectId, priorCommunicationToSubmit);

        dispatch({ type: actionTypes.UPDATE_PRIOR_COMMUNICATION_SUCCESS });
        dispatch({ type: modalActionTypes.CLOSE_PRIOR_COMMUNICATION_INFO });
        dispatch(priorCommunicationActions.getPriorCommunication(projectId));
        dispatch(notificationsActions.openSuccessNotification('success.updatePriorCommunication'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.updatePriorCommunication'));

        dispatch({ type: actionTypes.UPDATE_PRIOR_COMMUNICATION_FAIL });
    }
};
