const baseUrl = '/territories';

const getCountries = client => {
    const url = `${baseUrl}/countries`;

    return client.get(url, {});
};

const getDistricts = (client, countryId) => {
    const url = `${baseUrl}/districts`;

    return client.get(url, { params: { countryId } });
};

const getCounties = (client, districtId) => {
    const url = `${baseUrl}/counties`;

    return client.get(url, { params: { districtId } });
};

const getParishes = (client, countyId) => {
    const url = `${baseUrl}/parishes`;

    return client.get(url, { params: { countyId } });
};

export default client => ({
    getCountries: () => getCountries(client),
    getDistricts: countryId => getDistricts(client, countryId),
    getCounties: districtId => getCounties(client, districtId),
    getParishes: countyId => getParishes(client, countyId)
});
