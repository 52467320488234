import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    drawer: {
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.drawer - 1,
        width: '60vw',
        paddingTop: '64px',
        maxWidth: '650px',
        marginLeft: '90px',
        [theme.breakpoints.down('md')]: {
            width: '80vw'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            maxWidth: '100vw',
            marginLeft: 0
        }
    },
    topBar: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));
