import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    users: {
        count: 0,
        limit: 8,
        page: 1,
        pages: 0,
        rows: []
    }
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_USERS_SUCCESS:
        case actionTypes.GET_USERS_FAIL:
            return false;
        case actionTypes.GET_USERS_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const users = (state = initialState.users, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_USERS_SUCCESS:
            return action.payload;
        case actionTypes.SET_CURRENT_PAGE:
            return { ...state, page: action.payload };
        case actionTypes.RESET:
            return initialState.users;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getUsers = state => get(state, 'users', initialState.users);

const reducer = combineReducers({
    isLoading,
    users
});

export default reducer;
