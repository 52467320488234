import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90%',
        flexDirection: 'column'
    },
    spacing: {
        marginBottom: theme.spacing(4)
    },
    title: {
        color: theme.palette.unfulfilledTitle
    },
    subtitle: {
        color: theme.palette.cityooText
    }
}));
