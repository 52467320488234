import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../../../modal-manager/actionTypes';

import { actions as listProjectsActions } from '../../list';
import { actions as notificationsActions } from '../../../notifications';

export const reset = () => ({
    type: actionTypes.RESET
});

export const getProject = projectId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.RESET });
    dispatch({ type: actionTypes.GET_PROJECT_START });

    try {
        const response = await apiSdk.projects.get(projectId);
        const { data } = response;

        data.changeCluster.geometry.coordinates[0].pop();

        dispatch({
            type: actionTypes.GET_PROJECT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchProjectDetails'));
        dispatch({ type: actionTypes.GET_PROJECT_FAIL });
    }
};

export const getProjectByClusterId = clusterId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.RESET });
    dispatch({ type: actionTypes.GET_PROJECT_START });

    try {
        const response = await apiSdk.projects.getByClusterId(clusterId);
        const { data } = response;

        dispatch({
            type: actionTypes.GET_PROJECT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchProjectDetails'));
        dispatch({ type: actionTypes.GET_PROJECT_FAIL });
    }
};

export const setLegalClassification = projectId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.SET_LEGAL_CLASSIFICATION_START });

    try {
        await apiSdk.projects.setLegal(projectId);

        dispatch({ type: modalActionTypes.CLOSE_CLASSIFICATION_PROJECT });
        dispatch({ type: actionTypes.SET_LEGAL_CLASSIFICATION_SUCCESS, payload: { id: projectId } });
        dispatch(notificationsActions.openSuccessNotification('success.setLegalClassification'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('success.setLegalClassification'));
        dispatch({ type: actionTypes.SET_LEGAL_CLASSIFICATION_FAIL });
    }
};

export const setIllegalClassification = projectId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.SET_ILLEGAL_CLASSIFICATION_START });

    try {
        await apiSdk.projects.setIllegal(projectId);

        dispatch({ type: modalActionTypes.CLOSE_CLASSIFICATION_PROJECT });
        dispatch({ type: actionTypes.SET_ILLEGAL_CLASSIFICATION_SUCCESS, payload: { id: projectId } });
        dispatch(notificationsActions.openSuccessNotification('success.setIllegalClassification'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('success.setIllegalClassification'));
        dispatch({ type: actionTypes.SET_ILLEGAL_CLASSIFICATION_FAIL });
    }
};

export const deleteProject = projectId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.DELETE_PROJECT_START });

    try {
        await apiSdk.projects.remove(projectId);

        dispatch({ type: modalActionTypes.CLOSE_CONFIRM_MODAL });
        dispatch({ type: actionTypes.DELETE_PROJECT_SUCCESS, payload: { id: projectId } });
        dispatch(listProjectsActions.getProjects());
        dispatch(reset());
        dispatch(notificationsActions.openSuccessNotification('success.deleteProject'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.deleteProject'));
        dispatch({ type: actionTypes.DELETE_PROJECT_FAIL });
    }
};
