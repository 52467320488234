import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../../modal-manager/actionTypes';
import modalActionEnumeratos from '../../modal-manager/enumerators';

const initialState = {
    isLoading: false,
    countries: []
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_COUNTRIES_SUCCESS:
        case actionTypes.GET_COUNTRIES_FAIL:
            return false;
        case actionTypes.GET_COUNTRIES_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const countries = (state = initialState.countries, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case modalActionTypes.OPEN_CREATE_PROJECT: {
            if (action.payload?.type !== modalActionEnumeratos.TYPE_EDIT) {
                return initialState.countries;
            }

            return [action.payload.selectProject?.territory?.country] ?? initialState.countries;
        }
        case actionTypes.GET_COUNTRIES_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.countries;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getCountries = state => get(state, 'countries', initialState.countries);

const reducer = combineReducers({
    isLoading,
    countries
});

export default reducer;
