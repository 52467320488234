import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import EmailLink from './email-link';

import useStyles from './FloatingLabel.styles';

export const CONTENT_TYPES = {
    TEXT: 'TEXT',
    EMAIL: 'EMAIL'
};

export const SPACING_TYPES = {
    SMALL: 'SMALL',
    LARGE: 'LARGE'
};

const propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string,
    spacing: PropTypes.oneOf(Object.values(SPACING_TYPES)),
    contentType: PropTypes.oneOf(Object.values(CONTENT_TYPES))
};

const defaultProps = {
    content: '-',
    contentType: CONTENT_TYPES.TEXT,
    spacing: SPACING_TYPES.LARGE
};

const FloatingLabel = ({ spacing, label, content, contentType }) => {
    const classes = useStyles();

    const handleContentType = () => {
        switch (contentType) {
            case CONTENT_TYPES.EMAIL:
                return <EmailLink email={content} />;
            case CONTENT_TYPES.TEXT:
            default:
                return content;
        }
    };

    return (
        <div className={spacing === SPACING_TYPES.LARGE ? classes.root : classes.rootSmallSpacing}>
            <Typography variant="body1" className={classes.label}>
                {label}
            </Typography>
            <Typography variant="body2">{handleContentType()}</Typography>
        </div>
    );
};

FloatingLabel.propTypes = propTypes;
FloatingLabel.defaultProps = defaultProps;

export default FloatingLabel;
