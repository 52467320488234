import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    coordinatesContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    coordinatesInputs: {
        display: 'flex',
        flex: 1
    },
    inputLeft: {
        flex: 1,
        marginRight: theme.spacing(1)
    },
    inputRight: {
        flex: 1,
        marginLeft: theme.spacing(1)
    },
    title: {
        color: theme?.palette?.text?.secondary,
        marginTop: theme.spacing(4)
    },
    insertPointButton: {
        marginTop: theme.spacing(2),
        width: '12rem',
        height: '2rem',
        alignSelf: 'flex-end',
        fontSize: '0.875rem'
    },
    error: {
        color: theme.palette.text.error,
        fontWeight: 600
    },
    deleteButton: {
        padding: 0,
        width: '2rem'
    },
    mapContainer: {
        marginTop: theme.spacing(2),
        height: '20rem'
    },
    coordinateListContainer: {
        display: 'flex'
    },
    caption: {
        color: theme.palette.cityooText,
        fontStyle: 'italic'
    }
}));
