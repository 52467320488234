import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        background: theme.palette.background.white,
        boxShadow: theme.customShadows.medium,
        padding: '1.5rem 0.5rem 0.5rem',
        marginBottom: '0.5rem',
        borderRadius: '0.5rem'
    },
    gap: {
        marginRight: '2rem'
    },
    tilesContainer: {
        marginBottom: '1.5rem',
        '&.withShadow': {
            background: theme.palette.background.white,
            boxShadow: theme.customShadows.medium,
            padding: '1rem',
            borderRadius: '0.25rem'
        }
    },
    tilesTitle: {
        fontSize: '1rem',
        fontWeight: '600',
        color: theme.palette.text
    },
    mapContainer: {
        margin: '1rem 0',
        height: '20rem'
    }
}));
