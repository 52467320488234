import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { MapContainer, TileLayer } from 'react-leaflet';

import { dateSeparatorByDots } from '../../../utils/date-time';
import config from '../../../_config';

import { selectors as projectSelectors } from '../../../redux/projects/project/details';
import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../../redux/modal-manager';

import FloatingLabel from '../../../components/floating-label';
import SurveillanceAreaMap from '../../../components/modals/create-project/surveillance-area-map';

import useStyles from './ProjectIdentification.styles';

const ProjectIdentification = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { mapConfigs } = config;
    const selectProject = useSelector(projectSelectors.getProject);
    const selectProjectCoordinates = useSelector(projectSelectors.getProjectCoordinates);
    const projectChangeCluster = useSelector(projectSelectors.getProjectChangeCluster);

    const onEditProject = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.CREATE_PROJECT, { type: modalManagerEnumerators.TYPE_EDIT, selectProject }));
    }, [dispatch, selectProject]);

    return (
        <>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <Typography variant="h3" className={classes.title}>
                        {t('projects.identification.projectData')}
                    </Typography>
                    <IconButton aria-label="edit" className={classes.actionBtn} onClick={onEditProject}>
                        <EditIcon />
                    </IconButton>
                </Box>
                {selectProject && (
                    <Box display="flex" flexDirection="row">
                        <Box flex={1}>
                            <FloatingLabel label={t('projects.identification.name')} content={selectProject.name} />
                            <FloatingLabel label={t('projects.identification.createdAt')} content={dateSeparatorByDots(selectProject.createdAt)} />
                            <FloatingLabel label={t('projects.identification.projectResponsible')} content={selectProject.projectResponsible} />
                            <FloatingLabel label={t('projects.identification.address')} content={selectProject.address} />
                            <FloatingLabel label={t('projects.identification.postalCode')} content={selectProject.postalCode} />
                        </Box>
                        <Box flex={1}>
                            <FloatingLabel label={t('projects.identification.parish')} content={selectProject.territory?.parish?.name} />
                            <FloatingLabel label={t('projects.identification.county')} content={selectProject.territory?.county?.name} />
                            <FloatingLabel label={t('projects.identification.district')} content={selectProject.territory?.district?.name} />
                            <FloatingLabel label={t('projects.identification.country')} content={selectProject.territory?.country?.name} />
                        </Box>
                    </Box>
                )}
            </Box>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <Typography variant="h3" className={classes.title}>
                        {t('projects.identification.surveillanceArea')}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flex={1}>
                        <Box flex={1}>
                            {selectProjectCoordinates.map(coordinates => (
                                <FloatingLabel key={coordinates.latitude} label={t('latitude')} content={coordinates.latitude.toString()} />
                            ))}
                        </Box>
                        <Box flex={1}>
                            {selectProjectCoordinates.map(coordinates => (
                                <FloatingLabel key={coordinates.longitude} label={t('longitude')} content={coordinates.longitude.toString()} />
                            ))}
                        </Box>
                    </Box>
                    <Box flex={1} paddingBottom="2rem">
                        <MapContainer
                            className={classes.mapContainer}
                            scrollWheelZoom={false}
                            center={mapConfigs.center}
                            zoomControl={mapConfigs.zoomControl}
                        >
                            <>
                                <TileLayer attribution={mapConfigs.disclaimer.title} url={mapConfigs.disclaimer.link} />
                                <SurveillanceAreaMap geoJSON={projectChangeCluster} />
                            </>
                        </MapContainer>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ProjectIdentification;
