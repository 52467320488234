import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';
import * as modalManagerActionTypes from '../../modal-manager/actionTypes';

import { removeEmptyInfoAndRemovePropFromObject } from '../../../utils/encoders';

const initialStateLicenseDetails = {
    county: '',
    issueDate: null,
    possibleConstruction: '',
    issueNumber: '',
    licenseHolder: '',
    landRegistryOffice: '',
    registryNumber: '',
    registryArticle: '',
    parish: '',
    approvedBy: '',
    approvalDate: null,
    buildingArea: '',
    buildingVolumetry: '',
    buildingHeight: '',
    floorsAboveQuota: '',
    floorsBelowQuota: '',
    possibleEndUse: '',
    completionDeadline: ''
};

const initialState = {
    isLoading: false,
    licenseDetails: initialStateLicenseDetails
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.UPDATE_LICENSE_START:
            return true;
        case actionTypes.UPDATE_LICENSE_SUCCESS:
        case actionTypes.UPDATE_LICENSE_FAIL:
            return false;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const licenseDetails = (state = initialState.licenseDetails, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case modalManagerActionTypes.OPEN_LICENSE_INFO: {
            const licenseInfo = removeEmptyInfoAndRemovePropFromObject(action.payload, ['project', 'projectId']);

            return Object.keys(initialStateLicenseDetails).reduce((acc, licenseDetailKey) => {
                const existLicenseInfo = Object.keys(licenseInfo).find(licenseInfoKey => licenseInfoKey === licenseDetailKey);

                if (existLicenseInfo) {
                    acc[licenseDetailKey] = licenseInfo[existLicenseInfo];
                } else {
                    acc[licenseDetailKey] = initialStateLicenseDetails[licenseDetailKey];
                }

                return acc;
            }, {});
        }
        case actionTypes.RESET:
            return initialState.licenseDetails;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getLicenseDetails = state => get(state, 'licenseDetails', initialState.licenseDetails);

const reducer = combineReducers({
    isLoading,
    licenseDetails
});

export default reducer;
