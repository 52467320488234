import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import { STATUSES_MAP_CONSTANTS } from '../../../utils/project-status';
import * as actionTypes from './actionTypes';
import * as projectFormActionTypes from '../../form/project/actionTypes';
import * as projectActionTypes from '../project/details/actionTypes';

const initialState = {
    isLoading: false,
    projects: []
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PROJECTS_SUCCESS:
        case actionTypes.GET_PROJECTS_FAIL:
            return false;
        case actionTypes.GET_PROJECTS_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const projects = (state = initialState.projects, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case projectFormActionTypes.UPDATE_SELECT_PROJECT:
            return state.map(project => {
                if (project.id === action.payload.id) {
                    return {
                        ...project,
                        ...action.payload
                    };
                }

                return project;
            });
        case projectActionTypes.SET_LEGAL_CLASSIFICATION_SUCCESS:
            return state.map(project => {
                if (project.id === action.payload.id) {
                    return {
                        ...project,
                        status: STATUSES_MAP_CONSTANTS.LEGAL
                    };
                }

                return project;
            });
        case projectActionTypes.SET_ILLEGAL_CLASSIFICATION_SUCCESS:
            return state.map(project => {
                if (project.id === action.payload.id) {
                    return {
                        ...project,
                        status: STATUSES_MAP_CONSTANTS.ILLEGAL
                    };
                }

                return project;
            });
        case actionTypes.GET_PROJECTS_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.projects;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getProjects = state => get(state, 'projects', initialState.projects);

const reducer = combineReducers({
    isLoading,
    projects
});

export default reducer;
