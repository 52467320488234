import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    territoriesBoundaries: {},
    projectsCentroid: {}
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PROJECTS_CENTROID_SUCCESS:
        case actionTypes.GET_PROJECTS_CENTROID_FAIL:
            return false;
        case actionTypes.GET_PROJECTS_CENTROID_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const projectsCentroid = (state = initialState.projectsCentroid, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PROJECTS_CENTROID_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.projectsCentroid;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getProjectsCentroid = state => get(state, 'projectsCentroid', initialState.projectsCentroid);

const reducer = combineReducers({
    isLoading,
    projectsCentroid
});

export default reducer;
