const enumerators = {
    TYPE_CREATE: 'create',
    TYPE_EDIT: 'edit',
    CREATE_PROJECT: 'CREATE_PROJECT',
    CREATE_ALBUM: 'CREATE_ALBUM',
    IMAGE_PREVIEW: 'IMAGE_PREVIEW',
    CLASSIFICATION_PROJECT: 'CLASSIFICATION_PROJECT',
    UNFULFILLED: 'UNFULFILLED',
    CREATE_USER: 'CREATE_USER',
    LICENSE_INFO: 'LICENSE_INFO',
    CONFIRM_MODAL: 'CONFIRM_MODAL',
    PRIOR_COMMUNICATION: 'PRIOR_COMMUNICATION'
};

export default enumerators;
