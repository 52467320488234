import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        background: theme.palette.background.white,
        boxShadow: theme.customShadows.medium,
        padding: '2rem',
        '&.dashboard': {
            padding: '1rem',
            borderRadius: '0.25rem'
        }
    }
}));
