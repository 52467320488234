import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, useTheme, useMediaQuery } from '@material-ui/core';

import ROUTES, { getProjectUrl } from '../../../utils/routes';

import { actions as projectActions, selectors as projectSelectors } from '../../../redux/projects/project/details';
import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../../redux/modal-manager';

import PageHeader from '../../../components/page-header';
import Loading from '../../../components/base-loading';
import BaseTabs, { LinkTab } from '../../../components/base-tabs';
import DropdownRouter from '../../../components/dropdown-router';

import ProjectActivity from '../project-activity';
import ProjectDashboard from '../project-dashboard';
import ProjectTimeline from '../project-timeline';
import ProjectIdentification from '../project-identification';
import ProjectAlbums from '../project-albums';
import ProjectLicense from '../project-license';

import useStyles from './ProjectDetails.styles';

const propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        })
    }).isRequired
};

const ProjectDetails = ({ match }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const classes = useStyles();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const isProjectLoading = useSelector(projectSelectors.getIsLoading);
    const selectedProject = useSelector(projectSelectors.getProject);

    const routes = [
        { name: t('projects.tabs.dashboard'), url: getProjectUrl.dashboard(selectedProject?.id) },
        { name: t('projects.tabs.timeline'), url: getProjectUrl.timeline(selectedProject?.id) },
        { name: t('projects.tabs.identification'), url: getProjectUrl.identification(selectedProject?.id) },
        { name: t('projects.tabs.license'), url: getProjectUrl.license(selectedProject?.id) },
        { name: t('projects.tabs.gallery'), url: getProjectUrl.gallery(selectedProject?.id) },
        { name: t('projects.tabs.activity'), url: getProjectUrl.activity(selectedProject?.id) }
    ];

    const changeRoute = useCallback(
        event => {
            const url = event.target.value;
            history.push(url);
        },
        [history]
    );

    const onExportClick = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.UNFULFILLED, t('features.export')));
    }, [dispatch, t]);

    useEffect(() => {
        dispatch(projectActions.getProject(match?.params?.id));
    }, [dispatch, match.params.id]);

    return (
        <div className={classes.root}>
            {isProjectLoading || !selectedProject ? (
                <div className={classes.loadingContainer}>
                    <Loading />
                </div>
            ) : (
                <>
                    <PageHeader title={selectedProject.name} variant="small">
                        <Button variant="contained" color="primary" size="small" onClick={onExportClick}>
                            {t('projects.export')}
                        </Button>
                    </PageHeader>

                    {isTablet ? (
                        <DropdownRouter routes={routes} active={location.pathname} onChange={changeRoute} />
                    ) : (
                        <BaseTabs>
                            {routes.map(({ name, url }) => (
                                <LinkTab key={name} label={name} href={url} />
                            ))}
                        </BaseTabs>
                    )}

                    <Switch>
                        <Route exact path={match.url} component={ProjectDashboard} />
                        <Route path={ROUTES.PROJECTS_ID_TIMELINE} component={ProjectTimeline} />
                        <Route path={ROUTES.PROJECTS_ID_IDENTIFICATION} component={ProjectIdentification} />
                        <Route path={ROUTES.PROJECTS_ID_GALLERY}>
                            <ProjectAlbums hasActions />
                        </Route>
                        <Route path={ROUTES.PROJECTS_ID_LICENSE} component={ProjectLicense} />
                        <Route path={ROUTES.PROJECTS_ID_ACTIVITY} component={ProjectActivity} />
                    </Switch>
                </>
            )}
        </div>
    );
};

ProjectDetails.propTypes = propTypes;

export default withRouter(ProjectDetails);
