/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';

import { dateSeparatorByDots } from '../../../../utils/date-time';

import { selectors as projectSelectors } from '../../../../redux/projects/project/details';
import {
    actions as priorCommunicationActions,
    selectors as priorCommunicationSelectors
} from '../../../../redux/projects/project/prior-communication';

import FloatingLabel from '../../../../components/floating-label';
import BaseLoading from '../../../../components/base-loading';

import useStyles from './PriorCommunication.styles';

const propTypes = {
    onEditPriorCommunication: PropTypes.func.isRequired
};

const PriorCommunication = ({ onEditPriorCommunication }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const priorCommunicationDetail = useSelector(priorCommunicationSelectors.getPriorCommunication);
    const isLoadingPriorCommunicationDetail = useSelector(priorCommunicationSelectors.getIsLoading);
    const projectId = useSelector(projectSelectors.getProjectId);

    useEffect(() => {
        dispatch(priorCommunicationActions.getPriorCommunication(projectId));
    }, [dispatch, projectId]);

    const PriorCommunicationHeader = () => (
        <Box className={classes.header}>
            <Typography variant="h3" className={classes.title}>
                {t('projects.priorCommunication.priorCommunication')}
            </Typography>
            <IconButton aria-label="edit" className={classes.actionBtn} onClick={onEditPriorCommunication}>
                <EditIcon />
            </IconButton>
        </Box>
    );

    const renderPriorCommunicationContent = value => (value ? t(value) : t('projects.priorCommunication.notDetected'));

    const renderPriorCommunicationDate = date => (date ? dateSeparatorByDots(date) : t('projects.priorCommunication.notDetected'));

    if (isLoadingPriorCommunicationDetail) {
        return (
            <>
                <PriorCommunicationHeader />
                <Box display="flex" justifyContent="center" minHeight="10rem" alignItems="center">
                    <BaseLoading />
                </Box>
            </>
        );
    }

    return (
        <>
            <Box className={classes.container}>
                <PriorCommunicationHeader>
                    <IconButton aria-label="edit" className={classes.actionBtn} onClick={onEditPriorCommunication}>
                        <EditIcon />
                    </IconButton>
                </PriorCommunicationHeader>
                {!isEmpty(priorCommunicationDetail) ? (
                    <Box display="flex" flexDirection="row">
                        <Box flex={1}>
                            <FloatingLabel
                                label={t('projects.priorCommunication.county')}
                                content={renderPriorCommunicationContent(priorCommunicationDetail.county)}
                            />
                            <FloatingLabel
                                label={t('projects.priorCommunication.date')}
                                content={renderPriorCommunicationDate(priorCommunicationDetail.date)}
                            />
                        </Box>
                        <Box flex={1}>
                            <FloatingLabel
                                label={t('projects.priorCommunication.processNumber')}
                                content={renderPriorCommunicationContent(priorCommunicationDetail.processNumber)}
                            />
                            <FloatingLabel
                                label={t('projects.priorCommunication.intendedPurpose')}
                                content={renderPriorCommunicationContent(priorCommunicationDetail.intendedPurpose)}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="center" minHeight="10rem" alignItems="center">
                        <Typography variant="h4">{t('projects.priorCommunication.infoNotAvailable')}</Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};

PriorCommunication.propTypes = propTypes;

export default PriorCommunication;
