import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';

import { removeEmptyInfoAndRemovePropFromObject } from '../../../utils/encoders';

import {
    actions as modalManagerActions,
    selectors as modalManagerSelectors,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';
import { actions as priorCommunicationActions, selectors as priorCommunicationSelectors } from '../../../redux/form/prior-communication';
import { selectors as projectSelectors } from '../../../redux/projects/project/details';

import BaseDialog from '../../base-dialog';
import Loading from '../../base-loading';
import BaseInput from '../../base-input';
import BaseDatePicker from '../../base-date-picker';

import useStyles from './PriorCommunicationInfo.styles';
import priorCommunicationHelper from './helper';

const PriorCommunicationInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const priorCommunicationInfoModal = useSelector(modalManagerSelectors.getPriorCommunication);
    const priorCommunicationDetails = useSelector(priorCommunicationSelectors.getPriorCommunicationDetails);
    const isPriorCommunicationLoading = useSelector(priorCommunicationSelectors.getIsLoading);
    const projectId = useSelector(projectSelectors.getProjectId);

    const handleCloseDialog = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.PRIOR_COMMUNICATION));
    }, [dispatch]);

    const handleSubmitLicense = useCallback(
        values => {
            dispatch(priorCommunicationActions.updatePriorCommunication(projectId, removeEmptyInfoAndRemovePropFromObject(values)));
        },
        [dispatch, projectId]
    );

    const renderField = (fieldKey, { errors, touched, values, handleChange, handleBlur, setFieldValue }) => {
        if (priorCommunicationHelper.isDateField(fieldKey)) {
            return (
                <BaseDatePicker
                    key={fieldKey}
                    name={fieldKey}
                    InputLabelProps={{
                        shrink: true
                    }}
                    className={classes.datePicker}
                    value={values[fieldKey]}
                    onChange={value => {
                        setFieldValue(fieldKey, value);
                    }}
                    label={t(`projects.priorCommunication.${fieldKey}`)}
                />
            );
        }

        return (
            <BaseInput
                key={fieldKey}
                label={t(`projects.priorCommunication.${fieldKey}`)}
                id={fieldKey}
                name={fieldKey}
                required={priorCommunicationHelper.formOptions.hasOwnProperty(fieldKey) && priorCommunicationHelper.formOptions[fieldKey].required}
                value={values[fieldKey]}
                type={priorCommunicationHelper.formOptions[fieldKey].type}
                helperText={errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey) ? t(errors[fieldKey]) : ''}
                error={!!(errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey))}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    };

    return (
        <BaseDialog open={priorCommunicationInfoModal.isOpen} onClose={handleCloseDialog} title={t('projects.priorCommunication.edit')}>
            {isPriorCommunicationLoading ? (
                <Box className={classes.loadingContainer}>
                    <Loading />
                </Box>
            ) : (
                <Formik
                    initialValues={priorCommunicationDetails}
                    onSubmit={handleSubmitLicense}
                    validationSchema={priorCommunicationHelper.getValidationSchema()}
                >
                    {({ handleSubmit, ...formVariables }) => (
                        <Form>
                            <Box className={classes.container}>
                                {Object.keys(formVariables.values).map(fieldKey => renderField(fieldKey, formVariables))}
                            </Box>

                            <div className={classes.actions}>
                                <Button onClick={handleCloseDialog} color="secondary" variant="contained">
                                    {t('cancel')}
                                </Button>
                                <Button onClick={handleSubmit} variant="outlined" className={classes.submitButton}>
                                    {t('save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </BaseDialog>
    );
};

export default PriorCommunicationInfo;
