import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Box, Menu, MenuItem, Typography, IconButton } from '@material-ui/core';
import { PermIdentity as AccountIcon, Menu as MenuIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext';
import { actions as accountActions, selectors as accountSelectors } from '../../redux/account';

import logo from '../../assets/logo.svg';

import useStyles from './Header.styles';

const Header = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const layoutDispatch = useLayoutDispatch();

    const { isFloatingSidebar } = useLayoutState();

    // state
    const [anchorEl, setAnchorEl] = useState(null);

    // state props
    const user = useSelector(accountSelectors.getUser);

    const handleLogout = useCallback(() => {
        dispatch(accountActions.logout());
    }, [dispatch]);

    const handleClick = useCallback(
        event => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <img src={logo} alt="logo" className={classes.logo} />
                <div className={classes.grow} />

                <div>
                    {isFloatingSidebar ? (
                        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                            <MenuIcon style={{ color: 'white' }} />
                        </IconButton>
                    ) : (
                        <>
                            <Box onClick={handleClick} className={classes.accountDetailsContainer}>
                                <AccountIcon style={{ color: 'white' }} />
                                <Typography variant="caption" className={classes.accountName}>
                                    {user?.fullName}
                                </Typography>
                            </Box>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ horizontal: 'center' }}
                            >
                                <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
                            </Menu>
                        </>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
