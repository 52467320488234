import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import useStyles from './ProjectAlbums.styles';

import {
    selectors as modalManagerSelectors,
    actions as modalManagerActions,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';
import { selectors as projectSelectors } from '../../../redux/projects/project/details';
import { selectors as albumsSelectors, actions } from '../../../redux/projects/project/albums';

import ProjectAlbumRow from './ProjectAlbumRow';
import Loading from '../../../components/base-loading';
import ImageLightbox from '../../../components/image-lightbox/ImageLightbox';

const propTypes = {
    hasActions: PropTypes.bool
};

const defaultProps = {
    hasActions: false
};

const ProjectAlbums = ({ hasActions }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const projectId = useSelector(projectSelectors.getProjectId);
    const albums = useSelector(albumsSelectors.getAlbums);
    const isLoading = useSelector(albumsSelectors.getIsLoading);
    const imagePreviewModal = useSelector(modalManagerSelectors.getImagePreview);

    useEffect(() => {
        dispatch(actions.getAlbums(projectId));
    }, [dispatch, projectId]);

    const onCreateAlbum = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.CREATE_ALBUM));
    }, [dispatch]);

    const onCloseImagePreview = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.IMAGE_PREVIEW));
    }, [dispatch]);

    return (
        <>
            {hasActions && (
                <Box className={classes.container}>
                    <Box className={classes.header}>
                        <Button variant="contained" aria-label="create" color="primary" size="small" onClick={onCreateAlbum}>
                            <AddIcon />
                            {t('projects.albums.createAlbum')}
                        </Button>
                    </Box>
                </Box>
            )}
            <Box>
                {isLoading ? (
                    <Loading />
                ) : (
                    albums?.length > 0 && albums.map(album => <ProjectAlbumRow hasActions={hasActions} key={album.id} album={album} />)
                )}
            </Box>

            {imagePreviewModal.data && (
                <ImageLightbox
                    open={imagePreviewModal.isOpen}
                    onClose={onCloseImagePreview}
                    src={imagePreviewModal.data.fileUrl}
                    alt={imagePreviewModal.data.filename}
                />
            )}
        </>
    );
};

ProjectAlbums.propTypes = propTypes;
ProjectAlbums.defaultProps = defaultProps;

export default ProjectAlbums;
