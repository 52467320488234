import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { isEmpty } from 'lodash';

import * as fromReducer from './reducer';

const getNotificationsFromReducer = get('notifications');

export const getNotification = state => fromReducer.getNotifications(getNotificationsFromReducer(state));

export const isNotificationOpen = createSelector([getNotification], notification => !isEmpty(notification.message));
