/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { isEmpty, camelCase } from 'lodash';

import { dateSeparatorByDots } from '../../../../utils/date-time';

import { selectors as projectSelectors } from '../../../../redux/projects/project/details';
import { actions as licenseActions, selectors as licenseSelectors } from '../../../../redux/projects/project/licensing';

import FloatingLabel from '../../../../components/floating-label';
import BaseLoading from '../../../../components/base-loading';

import licenseInfoHelper from '../../../../components/modals/license-info/helper';
import useStyles from './License.styles';

const propTypes = {
    onEditLicense: PropTypes.func.isRequired
};

const License = ({ onEditLicense }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const licenseDetail = useSelector(licenseSelectors.getLicensing);
    const isLoadingLicenseDetail = useSelector(licenseSelectors.getIsLoading);
    const projectId = useSelector(projectSelectors.getProjectId);

    useEffect(() => {
        dispatch(licenseActions.getLicensing(projectId));
    }, [dispatch, projectId]);

    const renderLicenseDetailContent = value => (value ? t(value) : t('projects.license.notDetected'));

    const renderLicensePossibleConstruction = possibleConstructionText =>
        possibleConstructionText
            ? t(`projects.license.possibleConstruction.${camelCase(possibleConstructionText)}`)
            : t('projects.license.notDetected');

    const renderLicensePossibleEndUse = possibleEndUseText =>
        possibleEndUseText ? t(`projects.license.possibleEndUse.${camelCase(possibleEndUseText)}`) : t('projects.license.notDetected');

    const renderLicenseDate = date => (date ? dateSeparatorByDots(date) : t('projects.license.notDetected'));

    const LicenseHeader = ({ children }) => (
        <Box className={classes.header}>
            <Typography variant="h3" className={classes.title}>
                {t('projects.license.license')}
            </Typography>
            {children}
        </Box>
    );

    if (isLoadingLicenseDetail) {
        return (
            <>
                <LicenseHeader />
                <Box display="flex" justifyContent="center" minHeight="10rem" alignItems="center">
                    <BaseLoading />
                </Box>
            </>
        );
    }

    return (
        <>
            <Box className={classes.container}>
                <LicenseHeader>
                    <IconButton aria-label="edit" className={classes.actionBtn} onClick={onEditLicense}>
                        <EditIcon />
                    </IconButton>
                </LicenseHeader>
                {!isEmpty(licenseDetail) ? (
                    <Box display="flex" flexDirection="row">
                        <Box flex={1}>
                            <FloatingLabel label={t('projects.license.county')} content={renderLicenseDetailContent(licenseDetail.county)} />
                            <FloatingLabel label={t('projects.license.issueDate')} content={renderLicenseDate(licenseDetail.issueDate)} />
                            <FloatingLabel
                                label={t('projects.license.landRegistryOffice')}
                                content={renderLicenseDetailContent(licenseDetail.landRegistryOffice)}
                            />
                            <FloatingLabel
                                label={t('projects.license.registryNumber')}
                                content={renderLicenseDetailContent(licenseDetail.registryNumber)}
                            />
                            <FloatingLabel
                                label={t('projects.license.licenseHolder')}
                                content={renderLicenseDetailContent(licenseDetail.licenseHolder)}
                            />
                            <FloatingLabel
                                label={t('projects.license.registryNumber')}
                                content={renderLicenseDetailContent(licenseDetail.registryNumber)}
                            />
                            <FloatingLabel
                                label={t('projects.license.registryArticle')}
                                content={renderLicenseDetailContent(licenseDetail.registryArticle)}
                            />
                            <FloatingLabel
                                label={t('projects.license.completionDeadline')}
                                content={renderLicenseDetailContent(licenseDetail.registryArticle)}
                            />
                            <FloatingLabel
                                label={t('projects.license.possibleConstruction.title')}
                                content={renderLicensePossibleConstruction(
                                    licenseInfoHelper.getPossibleConstructionText(licenseDetail.possibleConstruction)
                                )}
                            />
                        </Box>
                        <Box flex={1}>
                            <FloatingLabel label={t('projects.license.parish')} content={renderLicenseDetailContent(licenseDetail.parish)} />
                            <FloatingLabel label={t('projects.license.approvalDate')} content={renderLicenseDate(licenseDetail.approvalDate)} />
                            <FloatingLabel
                                label={t('projects.license.buildingArea')}
                                content={renderLicenseDetailContent(licenseDetail.buildingArea)}
                            />
                            <FloatingLabel
                                label={t('projects.license.buildingVolumetry')}
                                content={renderLicenseDetailContent(licenseDetail.buildingVolumetry)}
                            />
                            <FloatingLabel
                                label={t('projects.license.buildingHeight')}
                                content={renderLicenseDetailContent(licenseDetail.buildingHeight)}
                            />
                            <FloatingLabel
                                label={t('projects.license.floorsAboveQuota')}
                                content={renderLicenseDetailContent(licenseDetail.floorsAboveQuota)}
                            />
                            <FloatingLabel
                                label={t('projects.license.floorsBelowQuota')}
                                content={renderLicenseDetailContent(licenseDetail.floorsBelowQuota)}
                            />
                            <FloatingLabel
                                label={t('projects.license.possibleEndUse.title')}
                                content={renderLicensePossibleEndUse(licenseInfoHelper.getPossibleEndUseText(licenseDetail.possibleEndUse))}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="center" minHeight="10rem" alignItems="center">
                        <Typography variant="h4">{t('projects.license.infoNotAvailable')}</Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};

License.propTypes = propTypes;

export default License;
