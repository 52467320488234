import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import classnames from 'classnames';

// styles
import useStyles from './Error.styles';

const Error = () => {
    const classes = useStyles();
    return (
        <Paper classes={{ root: classes.paperRoot }}>
            <Typography variant="h1" color="primary" className={classnames(classes.textRow, classes.errorCode)}>
                404
            </Typography>
        </Paper>
    );
};

export default Error;
