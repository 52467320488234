import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useMap, GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import config from '../../../../_config';
import { getGeoJSONStyle, GEOMETRY_TYPE } from '../../../../utils/geolocation';

const propTypes = {
    geoJSON: PropTypes.shape({
        type: PropTypes.string,
        geometry: PropTypes.shape({
            type: PropTypes.string,
            coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)))
        }),
        // eslint-disable-next-line react/forbid-prop-types
        properties: PropTypes.object
    }).isRequired
};

const SurveillanceAreaMap = ({ geoJSON }) => {
    const map = useMap();
    const geoJsonLayer = useRef(null);
    const { mapConfigs } = config;

    const getLatLngBounds = useCallback(() => {
        if (geoJSON.geometry) {
            const { type, coordinates } = geoJSON.geometry;

            if (type === GEOMETRY_TYPE.POLYGON && coordinates.length) {
                const latLngs = coordinates[0].map(position => {
                    return L.latLng(position[1], position[0]);
                });
                const bounds = L.latLngBounds(latLngs);

                return bounds;
            }
        }

        return null;
    }, [geoJSON]);

    useEffect(() => {
        if (isEmpty(geoJSON.geometry.coordinates)) {
            map.setView(mapConfigs.center, mapConfigs.zoom);
        } else {
            if (geoJsonLayer && geoJsonLayer.current && geoJsonLayer.current.leafletElement) {
                geoJsonLayer.current.leafletElement.clearLayers().addData(geoJSON);
            }
            const latLngBounds = getLatLngBounds();
            if (latLngBounds) {
                map.fitBounds(latLngBounds);
            }
        }
    }, [getLatLngBounds, map, mapConfigs.center, mapConfigs.zoom, geoJSON]);

    useEffect(() => {
        if (geoJSON && geoJsonLayer.current) {
            geoJsonLayer.current.clearLayers().addData(geoJSON);
        }
    }, [geoJsonLayer, geoJSON]);

    return geoJSON ? <GeoJSON data={geoJSON} style={getGeoJSONStyle()} ref={geoJsonLayer} /> : null;
};

SurveillanceAreaMap.propTypes = propTypes;

export default SurveillanceAreaMap;
