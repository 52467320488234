import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        padding: theme.spacing(2)
    },
    image: {
        width: 'auto',
        maxWidth: '100%',
        height: '100%'
    }
}));
