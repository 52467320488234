import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import config from '../../../_config';

import { actions as projectActions } from '../../../redux/projects/project/details';
import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../../redux/modal-manager';

import { RoundedButton } from '../../../components/base-button';

import useStyles from './ProjectsMapView.styles';
import 'leaflet/dist/leaflet.css';

const ProjectsMapView = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { mapConfigs } = config;

    const onCreateProject = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.CREATE_PROJECT));
    }, [dispatch]);

    useEffect(() => {
        dispatch(projectActions.reset());
    }, [dispatch]);

    return (
        <div className={classes.root}>
            <MapContainer
                className={classes.mapContainer}
                center={mapConfigs.center}
                zoom={mapConfigs.zoom}
                scrollWheelZoom={mapConfigs.scrollWheelZoom}
                zoomControl={mapConfigs.zoomControl}
            >
                <TileLayer attribution={mapConfigs.disclaimer.title} url={mapConfigs.disclaimer.link} />
            </MapContainer>
            <Box className={classes.buttonContainer}>
                <RoundedButton title={t('projects.create')} startIcon={<AddIcon />} onClick={onCreateProject} />
            </Box>
        </div>
    );
};

export default ProjectsMapView;
