import * as Yup from 'yup';

const formOptions = {
    county: { required: false, type: 'text' },
    issueDate: { required: false, type: 'date' },
    possibleConstruction: { required: false, type: 'number' },
    issueNumber: { required: false, type: 'text' },
    licenseHolder: { required: false, type: 'text' },
    landRegistryOffice: { required: false, type: 'text' },
    registryNumber: { required: false, type: 'text' },
    registryArticle: { required: false, type: 'text' },
    parish: { required: false, type: 'text' },
    approvedBy: { required: false, type: 'text' },
    approvalDate: { required: false, type: 'date' },
    buildingArea: { required: false, type: 'number' },
    buildingVolumetry: { required: false, type: 'number' },
    buildingHeight: { required: false, type: 'number' },
    floorsAboveQuota: { required: false, type: 'number' },
    floorsBelowQuota: { required: false, type: 'number' },
    possibleEndUse: { required: false, type: 'number' },
    completionDeadline: { required: false, type: 'text' }
};

const getValidationSchema = () => {
    return Yup.object().shape({});
};

const POSSIBLE_CONSTRUCTIONS = [
    { value: 1, text: 'NEW_CONSTRUCTION' },
    { value: 2, text: 'REHABILITATION' },
    { value: 3, text: 'CHANGE' },
    { value: 4, text: 'ENLARGEMENT' },
    { value: 5, text: 'CONVERVATION' },
    { value: 6, text: 'DEMOLITIONS' },
    { value: 7, text: 'URBANIZATION_WORKS' },
    { value: 8, text: 'ALLOTMENT_WORKS' },
    { value: 9, text: 'URBAN_OPERATIONS_DEVELOPMENT' },
    { value: 10, text: 'URBAN_WORKS_LOW_RELEVANCE' },
    { value: 11, text: 'LAND_REMODELING_WORK' }
];

const POSSIBLE_END_USES = [
    { value: 1, text: 'PUBLIC_ADMINISTRATION' },
    { value: 2, text: 'AGRICULTURE' },
    { value: 3, text: 'LARGE_SPORT_ACTIVITIES' },
    { value: 4, text: 'SMALL_SPORT_ACTIVITIES' },
    { value: 5, text: 'RECREATIONAL_AND_CULTURAL_ACTIVITIES' },
    { value: 6, text: 'COMERCIAL_CENTERS' },
    { value: 7, text: 'TRADITIONAL_COMMERCE' },
    { value: 8, text: 'COMMUNICATIONS' },
    { value: 9, text: 'COEXISTENCES' },
    { value: 10, text: 'WORSHIP_AND_BURIAL' },
    { value: 11, text: 'TEACHING_AND_SCIENTIFIC_RESEARCH' },
    { value: 12, text: 'CHILD_SUPPORT_EQUIPMENT' },
    { value: 13, text: 'SUPPORT_EQUIPMENT_ELDERLY' },
    { value: 14, text: 'OFFICES' },
    { value: 15, text: 'FOOD_BEVERAGE_ESTABLISHMENT' },
    { value: 16, text: 'HOTEL_TURISM_ESTABLISHMENT_RURAL' },
    { value: 17, text: 'FAMILY_HOUSING' },
    { value: 18, text: 'EXTRACTIVE_INDUSTRY' },
    { value: 19, text: 'MANUFACTURING_INDUSTRY' },
    { value: 20, text: 'CAR_PARKS_INTERFACES' },
    { value: 21, text: 'FISHING' },
    { value: 22, text: 'MEDICAL_SERVICES' },
    { value: 23, text: 'TRANSPORT' },
    { value: 24, text: 'COMMERCIAL_UNITS_RELEVANT_SIZE' },
    { value: 25, text: 'GENERAL_USE' }
];

const getDropdownOptions = fieldKey => {
    switch (fieldKey) {
        case 'possibleEndUse':
            return POSSIBLE_END_USES;

        case 'possibleConstruction':
            return POSSIBLE_CONSTRUCTIONS;

        default:
            return [];
    }
};

const getPossibleConstructionText = value => POSSIBLE_CONSTRUCTIONS.find(possibleConstruction => possibleConstruction.value === value)?.text;

const getPossibleEndUseText = value => POSSIBLE_END_USES.find(possibleEndUse => possibleEndUse.value === value)?.text;

const dropdownItems = ['possibleEndUse', 'possibleConstruction'];

const isDropDownField = fieldKey => dropdownItems.includes(fieldKey);

const dateItems = ['approvalDate', 'issueDate'];

const isDateField = fieldKey => dateItems.includes(fieldKey);

export default {
    getPossibleEndUseText,
    getPossibleConstructionText,
    getDropdownOptions,
    isDateField,
    isDropDownField,
    formOptions,
    getValidationSchema
};
