import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    albums: []
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_ALBUMS_SUCCESS:
        case actionTypes.GET_ALBUMS_FAIL:
            return false;
        case actionTypes.GET_ALBUMS_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const albums = (state = initialState.albums, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_ALBUMS_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.albums;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getAlbums = state => get(state, 'albums', initialState.albums);

const reducer = combineReducers({
    isLoading,
    albums
});

export default reducer;
