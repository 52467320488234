import { get } from 'lodash/fp';

import * as fromReducer from './reducer';

const getSelectedOptionsFromReducer = get('territories.selectedOptions');

export const getSelectedCountry = state => fromReducer.getSelectedCountry(getSelectedOptionsFromReducer(state));

export const getSelectedDistrict = state => fromReducer.getSelectedDistrict(getSelectedOptionsFromReducer(state));

export const getSelectedCounty = state => fromReducer.getSelectedCounty(getSelectedOptionsFromReducer(state));

export const getSelectedParish = state => fromReducer.getSelectedParish(getSelectedOptionsFromReducer(state));
