import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import useStyles from './RoundedButton.styles';

const propTypes = {
    title: PropTypes.string.isRequired
};

const RoundedButton = ({ title, ...rest }) => {
    const classes = useStyles();

    return (
        <Button {...rest} className={classes.button}>
            {title}
        </Button>
    );
};

RoundedButton.propTypes = propTypes;

export default RoundedButton;
