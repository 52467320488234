/* eslint-disable quotes */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        borderWidth: 0,
        width: '18rem',
        height: '3.75rem',
        borderRadius: '0.625rem',
        boxShadow: theme.customShadows.light,
        backgroundColor: theme.palette.background.cityooCustomElements,
        color: theme.palette.text.white,
        fontSize: '1.25rem',
        fontFamily: "'Hind', sans-serif'",
        fontWeight: 500,
        fontStretch: 'normal',
        letterSpacing: '3px',
        textAlign: 'center',
        outline: 0,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.cityooCustomElements
        }
    }
}));

export default useStyles;
