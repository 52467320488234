import * as Yup from 'yup';

const formOptions = {
    name: {
        required: true
    }
};

const getValidationSchema = ({ isEditMode = false }) => {
    return Yup.object().shape({
        name: Yup.string().required('mandatory_field'),
        comments: Yup.string().required('mandatory_field'),
        files: !isEditMode ? Yup.array().required('mandatory_field') : Yup.array()
    });
};

export default {
    formOptions,
    getValidationSchema
};
