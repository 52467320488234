const TYPE = {
    FEATURE: 'Feature'
};

export const GEOMETRY_TYPE = {
    POINT: 'Point',
    LINE_STRING: 'LineString',
    POLYGON: 'Polygon',
    MULTI_LINE_STRING: 'MultiLineString',
    MULTI_POLYGON: 'MultiPolygon'
};

export const getGeoJSONStyle = () => ({
    stroke: true,
    color: '#6F0308',
    opacity: 1,
    weight: 3,
    dashArray: '1',
    fill: true,
    fillColor: '#6F0308',
    fillOpacity: 0.1
});

const isGeometryTypeValid = type => {
    return Object.values(GEOMETRY_TYPE).includes(type);
};

export const createGeoJSON = (type, coordinates = [], properties = {}) => {
    if (!isGeometryTypeValid(type) || !Array.isArray(coordinates)) {
        return null;
    }

    return {
        type: TYPE.FEATURE,
        geometry: { type, coordinates },
        properties
    };
};
