import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { STATUSES_MAP } from '../../../utils/project-status';
import { dateSeparatorByDots } from '../../../utils/date-time';

import useStyles from './ProjectStatusBanner.styles';

const propTypes = {
    status: PropTypes.number.isRequired,
    date: PropTypes.string,
    onClick: PropTypes.func
};

const defaultProps = {
    date: null,
    onClick: null
};

const ProjectStatusBanner = ({status, date, onClick}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getLabel = () => {
        return `projects.statuses.${STATUSES_MAP[status]}`;
    };

    return (
        <Box 
            display="flex"
            flexDirection="row"
            alignItems="center"
            className={classnames(classes.root, classes[STATUSES_MAP[status]])}
        >
            <Box flex={1}>
                <Box className={classes.titleContainer}>
                    <Typography variant="body1" className={classes.title}>
                        {t(getLabel())}
                    </Typography>
                    { date && (
                        <Typography variant="body1" className={classes.subtitle}>
                            {dateSeparatorByDots(date)}
                        </Typography>
                    )}
                </Box>
            </Box>
            { onClick && (
                <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button className={classes.button} onClick={onClick}>{t('projects.dashboard.changeStatus')}</Button>
                </Box>
            )}
        </Box>
    );
};

ProjectStatusBanner.propTypes = propTypes;
ProjectStatusBanner.defaultProps = defaultProps;

export default ProjectStatusBanner;
