/* eslint-disable quotes */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        borderWidth: 0,
        width: 'fit-content',
        height: '2.5rem',
        borderRadius: '2.5rem',
        boxShadow: theme.customShadows.light,
        backgroundColor: theme.palette.background.cityooCustomElements,
        color: theme.palette.text.white,
        fontSize: '1.25rem',
        fontFamily: "'Hind', sans-serif'",
        fontWeight: 500,
        fontStretch: 'normal',
        letterSpacing: 'inherit',
        textAlign: 'center',
        outline: 0,
        cursor: 'pointer',
        padding: '0.5rem 1.5rem',
        '&:hover': {
            backgroundColor: theme.palette.background.cityooCustomElements
        }
    }
}));

export default useStyles;
