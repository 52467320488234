export const STATUSES_MAP = {
    0: 'new',
    1: 'pending',
    2: 'legal',
    3: 'illegal',
    4: 'recurrent',
    5: 'archived'
};

export const STATUSES_MAP_CONSTANTS = {
    NEW: 0,
    PENDING: 1,
    LEGAL: 2,
    ILLEGAL: 3,
    RECURRENT: 4,
    ARCHIVED: 5
};
