import React from 'react';
import PropTypes from 'prop-types';
import { Box, Select, MenuItem } from '@material-ui/core';
import useStyles from './DropdownRouter.styles';

const propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string
        })
    ).isRequired,
    active: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const DropdownRouter = ({ routes, active, onChange }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container} flex={1}>
            <Select id="dropdown-router" className={classes.dropdown} value={active} onChange={onChange}>
                {routes.map(({ name, url }) => (
                    <MenuItem key={name} value={url}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

DropdownRouter.propTypes = propTypes;

export default DropdownRouter;
