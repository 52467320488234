import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';

import useStyles from './PageHeader.styles';

const VARIANT = {
    SMALL: 'small'
};

const propTypes = {
    title: PropTypes.string.isRequired,
    variant: PropTypes.string,
    children: PropTypes.node
};

const defaultProps = {
    variant: null,
    children: <></>
};

const PageHeader = ({ title, variant, children }) => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            alignItems="center"
            className={classnames(classes.root, { small: variant === VARIANT.SMALL })}
        >
            <Typography
                className={classnames(classes.title, { small: variant === VARIANT.SMALL })}
                variant="h2"
            >
                {title}
            </Typography>
            <Box
                className={classes.actionContainer}
                flexShrink={0}
            >
                {children}
            </Box>
        </Box>
    );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default PageHeader;
