import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';

import {
    selectors as modalManagerSelectors,
    actions as modalManagerActions,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';

import BaseDialog from '../../base-dialog';

import useStyles from './ConfirmModal.styles';

const ConfirmDialog = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    // state props
    const confirmModal = useSelector(modalManagerSelectors.getConfirmModal);

    if (!confirmModal.isOpen) {
        return null;
    }

    const handleClose = () => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CONFIRM_MODAL));
    };

    const { title, message, buttons } = confirmModal.data;

    const renderButtons = () =>
        buttons.map(item => (
            <Button key={item.text} color={item.color} disabled={item.disabled} onClick={item.action} variant={item.variant}>
                {t(item.text)}
            </Button>
        ));

    return (
        <BaseDialog disableBackdropClick open small actions={renderButtons()} onClose={handleClose} title={title}>
            <Typography variant="h3" className={classes.bodyText}>
                {message}
            </Typography>
        </BaseDialog>
    );
};

export default ConfirmDialog;
