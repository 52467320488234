import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../../modal-manager/actionTypes';

import { actions as projectsActions } from '../../projects/list';
import { actions as projectActions } from '../../projects/project/details';
import { actions as notificationsActions } from '../../notifications';

export const submitProject = projectToSubmit => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.SUBMIT_PROJECT_START });

    const dataToSubmit = {
        name: projectToSubmit.reference,
        projectResponsible: projectToSubmit.projectResponsible,
        address: projectToSubmit.address,
        postalCode: projectToSubmit.postalCode,
        changeCluster: projectToSubmit.changeCluster,
        parishId: projectToSubmit.parishId
    };

    try {
        await apiSdk.projects.insert(dataToSubmit);

        dispatch({ type: modalActionTypes.CLOSE_CREATE_PROJECT });
        dispatch(projectsActions.getProjects());
        dispatch(notificationsActions.openSuccessNotification('success.createProject'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.createProject'));
        dispatch({ type: actionTypes.SUBMIT_PROJECT_FAIL });
    }
};

export const updateSelectProject = projectData => ({
    type: actionTypes.UPDATE_SELECT_PROJECT,
    payload: projectData
});

export const updateProject = (projectToSubmit, projectId) => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.SUBMIT_PROJECT_START });

    const dataToSubmit = {
        name: projectToSubmit.reference,
        projectResponsible: projectToSubmit.projectResponsible,
        address: projectToSubmit.address,
        postalCode: projectToSubmit.postalCode,
        changeCluster: projectToSubmit.changeCluster
    };

    try {
        const response = await apiSdk.projects.update(dataToSubmit, projectId);
        const { data } = response;

        dispatch({ type: modalActionTypes.CLOSE_CREATE_PROJECT });
        dispatch(projectActions.getProject(data.id));
        dispatch(notificationsActions.openSuccessNotification('success.updateProject'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.updateProject'));
        dispatch({ type: actionTypes.SUBMIT_PROJECT_FAIL });
    }
};

export const submitParishId = parishId => ({
    type: actionTypes.SUBMIT_PARISH_ID,
    payload: parishId
});

export const insertCoordinate = coordinate => ({
    type: actionTypes.INSERT_COORDINATES,
    payload: {
        ...coordinate
    }
});

export const removeCoordinate = index => ({
    type: actionTypes.REMOVE_COORDINATES,
    payload: index
});
