import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20rem'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 6rem'
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 0,
        paddingTop: theme.spacing(4),
        minHeight: '0.75rem'
    },
    submitButton: {
        marginLeft: theme.spacing(1)
    }
}));
