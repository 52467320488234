import validators from '../../../../utils/validators';

const existCoordinates = (newCoordinate, coordinatesList) =>
    coordinatesList.some(
        coordinate => coordinate.latitude === Number(newCoordinate.latitude) && coordinate.longitude === Number(newCoordinate.longitude)
    );

const checkCoordinates = (lat, lng) =>
    !(
        !validators.floatRegex.test(lat) ||
        !validators.floatRegex.test(lng) ||
        parseFloat(lat) > 90 ||
        parseFloat(lat) < -90 ||
        parseFloat(lng) > 180 ||
        parseFloat(lng) < -180
    );

export default {
    existCoordinates,
    checkCoordinates
};
