import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { actions as accountActions, selectors as accountSelectors } from '../../redux/account';

import logo from '../../assets/logo.svg';
import ROUTES from '../../utils/routes';
import useQuery from '../../utils/use-query';
import BaseLoading from '../../components/base-loading';
import { BaseInputCityoo } from '../../components/base-input';
import { BaseButtonCityoo } from '../../components/base-button';
import LandingBackground from '../../components/landing-background';

import useStyles from './PasswordReset.styles';

const propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

const PasswordReset = ({ history }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const query = useQuery();
    const resetPasswordToken = query.get('resetPasswordToken');

    // state
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [visibilityPassword, setVisibilityPassword] = useState(false);
    const [visibilityConfirmPassword, setVisibilityConfirmPassword] = useState(false);
    const [formError, setFormError] = useState('');

    // state props
    const resetPasswordData = useSelector(accountSelectors.getResetPassword);

    const handleChangePassword = useCallback(
        ev => {
            setFormError('');
            setPassword(ev.target.value);
        },
        [setPassword, setFormError]
    );

    const handleChangeConfirmPassword = useCallback(
        ev => {
            setFormError('');
            setConfirmPassword(ev.target.value);
        },
        [setConfirmPassword, setFormError]
    );

    const handleChangeVisibilityConfirmPassword = useCallback(() => {
        setVisibilityConfirmPassword(!visibilityConfirmPassword);
    }, [setVisibilityConfirmPassword, visibilityConfirmPassword]);

    const handleChangeVisibilityPassword = useCallback(() => {
        setVisibilityPassword(!visibilityPassword);
    }, [setVisibilityPassword, visibilityPassword]);

    const handleSubmitResetPassword = useCallback(() => {
        if (password !== confirmPassword) {
            setFormError('error_reset_password_form');
        } else {
            dispatch(
                accountActions.resetPassword({
                    token: resetPasswordToken,
                    password
                })
            );
        }
    }, [password, confirmPassword, dispatch, resetPasswordToken, setFormError]);

    const handleGoToLogin = useCallback(() => {
        history.push(ROUTES.LOGIN);
    }, [history]);

    return (
        <LandingBackground>
            <Box container justify="center" className={classes.container}>
                <Box item className={classes.loginBox} spacing={5}>
                    <Box item className={classes.logoContainer}>
                        <img src={logo} alt={logo} className={classes.logo} />
                    </Box>
                    {resetPasswordData.isLoading ? (
                        <Box className={classes.loadingContainer}>
                            <BaseLoading color="secondary" />
                        </Box>
                    ) : (
                        <>
                            <BaseInputCityoo
                                autoComplete="password"
                                placeholder={t('choose_password')}
                                value={password}
                                onChange={handleChangePassword}
                                isPasswordVisible={visibilityPassword}
                                onChangePasswordVisibility={handleChangeVisibilityPassword}
                            />
                            <BaseInputCityoo
                                autoComplete="password"
                                placeholder={t('confirm_password')}
                                value={confirmPassword}
                                onChange={handleChangeConfirmPassword}
                                isPasswordVisible={visibilityConfirmPassword}
                                onChangePasswordVisibility={handleChangeVisibilityConfirmPassword}
                            />
                        </>
                    )}

                    <Typography variant="subtitle1" className={resetPasswordData.success ? classes.success : classes.error}>
                        {formError !== '' ? t(formError) : t(resetPasswordData.message)}
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <BaseButtonCityoo disabled={resetPasswordData.isLoading} title={t('send')} onClick={handleSubmitResetPassword} />
                    </div>
                    <Typography onClick={handleGoToLogin} variant="subtitle2" className={classes.resetPassword}>
                        {t('go_to_login')}
                    </Typography>
                </Box>
            </Box>
        </LandingBackground>
    );
};

PasswordReset.propTypes = propTypes;

export default withRouter(PasswordReset);
