import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        width: 'fit-content',
        minWidth: '4rem',
        color: theme.palette.text.white,
        fontSize: '0.625rem',
        lineHeight: '0.875rem',
        padding: '0 0.5rem',
        marginTop: '0.25rem',
        textTransform: 'uppercase',
        borderRadius: '0.5rem',
        textAlign: 'center'
    },
    new: {
        background: theme.palette.statuses.new
    },
    pending: {
        background: theme.palette.statuses.pending
    },
    legal: {
        background: theme.palette.statuses.legal
    },
    illegal: {
        background: theme.palette.statuses.illegal
    },
    recurrent: {
        background: theme.palette.statuses.recurrent
    },
    archived: {
        background: theme.palette.statuses.archived
    }
}));
