const baseUrl = '/upload';
const upload = client => {
    return client.post(baseUrl);
};

const sendFileToS3 = async (client, body, file) => {
    const {
        data: { uploadHttpMethod, uploadUrl, uploadHeaders }
    } = body;

    const method = uploadHttpMethod.toLowerCase();

    return client[method](uploadUrl, file, {
        headers: { ...uploadHeaders, 'Content-Type': file.type },
        transformRequest: [
            (data, headers) => {
                // eslint-disable-next-line no-param-reassign
                delete headers.Authorization;

                return data;
            }
        ]
    });
};

export default client => ({
    upload: () => upload(client),
    sendFileToS3: (body, file) => sendFileToS3(client, body, file)
});
