import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
    root: {
        padding: '1rem 2rem'
    },
    loadingContainer: {
        marginTop: '0.5rem'
    }
}));
