import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
        margin: theme.spacing(-3),
        display: 'flex',
        minHeight: 'calc(100% - 1rem)'
    },
    projectsList: {
        minWidth: '19rem',
        width: '24rem',
        background: theme.palette.background.white,
        '&.mobile': {
            width: '100%'
        }
    },
    projectContainer: {
        flex: 1,
        position: 'relative'
    },
    loadingContainer: {
        marginTop: '0.5rem'
    },
    emptyBox: {
        height: '3rem',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: theme.palette.secondary.main
    }
}));
