import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.white
    },
    childElement: {
        flex: 1,
        '&.fixed': {
            flex: '1 1 100%'
        }
    }
}));

export default useStyles;
