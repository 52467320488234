import React from 'react';
import { Box } from '@material-ui/core';

import BaseLoading from '../base-loading';
import logo from '../../assets/logo.svg';

import useStyles from './Fallback.styles';

const SuspenseFallback = () => {
    const classes = useStyles();

    return (
        <Box container justify="center" className={classes.container}>
            <Box item className={classes.loginBox} spacing={5}>
                <Box item className={classes.logoContainer}>
                    <img src={logo} alt={logo} className={classes.logo} />
                </Box>
                <Box className={classes.loadingContainer}>
                    <BaseLoading color="secondary" />
                </Box>
            </Box>
        </Box>
    );
};

export default SuspenseFallback;
