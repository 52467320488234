import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { actions as accountActions, selectors as accountSelectors } from '../../redux/account';

import logo from '../../assets/logo.svg';
import ROUTES from '../../utils/routes';
import BaseLoading from '../../components/base-loading';
import { BaseInputCityoo } from '../../components/base-input';
import { BaseButtonCityoo } from '../../components/base-button';
import LandingBackground from '../../components/landing-background';

import useStyles from './PasswordRecovery.styles';
import recoverPasswordHelper from './helper';

const propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

const PasswordRecovery = ({ history }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // state
    const [email, setEmail] = useState('');
    const [formError, setFormError] = useState('');

    // state props
    const recoverPasswordData = useSelector(accountSelectors.getRecoverPassword);

    const handleRecoverPassword = useCallback(() => {
        dispatch(accountActions.recoverPassword(email));
    }, [dispatch, email]);

    const handleChangeEmail = useCallback(
        ev => {
            setEmail(ev.target.value);
        },
        [setEmail]
    );

    const handleValidateFields = useCallback(() => {
        recoverPasswordHelper
            .getValidationSchema()
            .validate({ email })
            .then(() => {
                handleRecoverPassword();
                setFormError('');
            })
            .catch(err => {
                setFormError(err.errors);
            });
    }, [email, setFormError, handleRecoverPassword]);

    const handleGoToLogin = useCallback(() => {
        history.push(ROUTES.LOGIN);
    }, [history]);

    return (
        <LandingBackground>
            <Box container justify="center" className={classes.container}>
                <Box item className={classes.loginBox} spacing={5}>
                    <Box item className={classes.logoContainer}>
                        <img src={logo} alt={logo} className={classes.logo} />
                    </Box>
                    {recoverPasswordData.isLoading ? (
                        <Box className={classes.loadingLoginContainer}>
                            <BaseLoading color="secondary" />
                        </Box>
                    ) : (
                        <BaseInputCityoo placeholder={t('email')} value={email} onChange={handleChangeEmail} />
                    )}

                    <Typography variant="subtitle1" className={recoverPasswordData.success ? classes.success : classes.error}>
                        {formError !== '' ? t(formError) : t(recoverPasswordData.message)}
                    </Typography>
                    <div className={classes.loginButtonContainer}>
                        <BaseButtonCityoo disabled={recoverPasswordData.isLoading} title={t('send')} onClick={handleValidateFields} />
                    </div>
                    <Typography onClick={handleGoToLogin} variant="subtitle2" className={classes.recoverPassword}>
                        {t('go_to_login')}
                    </Typography>
                </Box>
            </Box>
        </LandingBackground>
    );
};

PasswordRecovery.propTypes = propTypes;

export default withRouter(PasswordRecovery);
