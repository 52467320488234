import React, { useCallback, useEffect } from 'react';
import { Route, Switch, withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import ROUTES, { getProjectUrl } from '../../utils/routes';

import { actions as projectsActions, selectors as projectsSelectors } from '../../redux/projects/list';
import { actions as projectActions, selectors as projectSelectors } from '../../redux/projects/project/details';

import Loading from '../../components/base-loading';

import ProjectDetails from './project-details';
import ProjectsMapView from './projects-map-view';
import ProjectTile from './project-tile';
import CreateAlbum from '../../components/modals/project-albums';
import CreateProject from '../../components/modals/create-project';
import ProjectClassification from '../../components/modals/project-classification';
import UnfulfilledModal from '../../components/modals/unfulfilled-modal';

import useStyles from './Projects.styles';

const propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired
    }).isRequired
};

const Projects = ({ match }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const projects = useSelector(projectsSelectors.getProjects);
    const isProjectsListLoading = useSelector(projectsSelectors.getIsLoading);
    const isProjectsDetailsLoading = useSelector(projectSelectors.getIsLoading);
    const selectProject = useSelector(projectSelectors.getProject);

    const shouldRenderProjectsList = (!isMobile || (!selectProject && !isProjectsDetailsLoading));

    const onProjectClick = useCallback(({ id }) => history.push(getProjectUrl.dashboard(id)), [history]);

    useEffect(() => {
        dispatch(projectsActions.getProjects());
    }, [dispatch]);
    
    useEffect(() => {
        if (!selectProject && !isProjectsDetailsLoading) {
            history.push(ROUTES.PROJECTS);
        } 
    }, [history, selectProject, isProjectsDetailsLoading]);

    useEffect(() => {
        if (selectProject && history.location.pathname === ROUTES.PROJECTS) {
            dispatch(projectActions.reset());
        }
    }, [dispatch, history.location.pathname, selectProject]);

    return (
        <div className={classes.root}>
            {shouldRenderProjectsList && (
                <div className={classnames(classes.projectsList, { mobile: isMobile })}>
                    {/* TODO: replace by search box and actions box */}
                    <Box className={classes.emptyBox} />
                    <Box className={classes.emptyBox} />
                    <div>
                        {isProjectsListLoading ? (
                            <div className={classes.loadingContainer}>
                                <Loading />
                            </div>
                        ) : (
                            projects.map(project => (
                                <ProjectTile
                                    key={project.id}
                                    project={project}
                                    onClick={onProjectClick}
                                    isSelected={selectProject && selectProject.id === project.id}
                                />
                            ))
                        )}
                    </div>
                </div>
            )}
            <div className={classes.projectContainer}>
                <Switch>
                    <Route exact path={match.url} component={() => !isMobile ? <ProjectsMapView /> : null} />
                    <Route path={ROUTES.PROJECTS_ID} component={ProjectDetails} />
                </Switch>
            </div>
            <CreateProject />
            <CreateAlbum />
            <ProjectClassification />
            <UnfulfilledModal />
        </div>
    );
};

Projects.propTypes = propTypes;

export default withRouter(Projects);
