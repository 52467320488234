/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { GeoJSON } from 'react-leaflet';

import { getGeoJSONStyle } from '../../../utils/geolocation';

const FeautrePolygonShape = PropTypes.arrayOf(
    PropTypes.shape({
        type: PropTypes.string,
        geometry: PropTypes.shape({
            coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
            type: PropTypes.string
        }),
        properties: PropTypes.object
    })
);

const propTypes = {
    data: PropTypes.shape({
        type: PropTypes.string,
        features: FeautrePolygonShape
    }),
    style: PropTypes.object
};

const defaultProps = {
    data: {},
    style: {}
};

const MapLayerBoundaries = ({ data, style }) => (
    <>{data.features && data.features.length && <GeoJSON data={data} style={isEmpty(style) ? getGeoJSONStyle() : style} />}</>
);

MapLayerBoundaries.propTypes = propTypes;
MapLayerBoundaries.defaultProps = defaultProps;

export default MapLayerBoundaries;
