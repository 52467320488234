const reducerName = '@cityoo-tenant/countries/selectedOptions';

export const RESET = `${reducerName}/RESET`;

export const SET_SELECTED_COUNTRY = `${reducerName}/SET_SELECTED_COUNTRY`;
export const SET_SELECTED_DISTRICT = `${reducerName}/SET_SELECTED_DISTRICT`;
export const SET_SELECTED_COUNTY = `${reducerName}/SET_SELECTED_COUNTY`;
export const SET_SELECTED_PARISH = `${reducerName}/SET_SELECTED_PARISH`;

export const RESET_SELECTED_DISTRICT = `${reducerName}/RESET_SELECTED_DISTRICT`;
export const RESET_SELECTED_COUNTY = `${reducerName}/RESET_SELECTED_COUNTY`;
export const RESET_SELECTED_PARISH = `${reducerName}/RESET_SELECTED_PARISH`;
