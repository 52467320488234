import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
    root: {
        position: 'relative',
        height: '100%'
    },
    mapContainer: {
        height: '100%'
    },
    buttonContainer: {
        position: 'absolute',
        top: '2rem',
        right: '1.25rem',
        zIndex: '500'
    }
}));
