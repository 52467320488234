import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.white,
        minHeight: '2rem',
        margin: '0.5rem 0 1.25rem',
        padding: '0.25rem 2.5rem',
        borderRadius: '0.3125rem',
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)'
    }
}));

export default useStyles;
