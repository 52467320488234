import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

import * as fromReducer from './reducer';

const getProjectTimelineFromReducer = get('projects.project.timeline');

export const getIsLoading = state => fromReducer.getIsLoading(getProjectTimelineFromReducer(state));

export const getTimelinePhotos = (limit) => createSelector(
    [state => fromReducer.getTimelinePhotos(getProjectTimelineFromReducer(state))],
    files => limit ? files.slice(0, limit) : files
);
