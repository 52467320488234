import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    appBar: {
        width: '100vw',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        background: theme.palette.primary.main
    },
    logo: {
        height: '40px'
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3)
    },
    grow: {
        flexGrow: 1
    },
    accountDetailsContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer'
    },
    accountName: {
        color: theme.palette.text.white
    }
}));
