/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../redux/modal-manager';

import ProjectSidepanelHeader from './ProjectSidepanelHeader';
import DropdownRouter from '../dropdown-router';
import { ProjectStatusBanner } from '../project-status';

import useStyles from './ProjectSidepanelContent.styles';
import ROUTES, { getSidepanelUrl } from '../../utils/routes';

import ProjectActivity from '../../pages/projects/project-activity';
import ProjectDashboard from '../../pages/projects/project-dashboard';
import ProjectTimeline from '../../pages/projects/project-timeline';
import ProjectIdentification from '../../pages/projects/project-identification';
import ProjectAlbums from '../../pages/projects/project-albums';

const propTypes = {
    selectedProject: PropTypes.object.isRequired
};

const ProjectSidepanelContent = ({ selectedProject }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const classes = useStyles();
    const dispatch = useDispatch();

    const routes = [
        {
            exact: true,
            name: t('projects.tabs.dashboard'),
            url: getSidepanelUrl.dashboard(selectedProject.id),
            path: ROUTES.PROJECT_SIDEPANEL,
            component: () => <ProjectDashboard isSidePanel />
        },
        {
            name: t('projects.tabs.timeline'),
            url: getSidepanelUrl.timeline(selectedProject.id),
            path: ROUTES.PROJECT_SIDEPANEL_TIMELINE,
            component: () => <ProjectTimeline isSmall />
        },
        {
            name: t('projects.tabs.identification'),
            url: getSidepanelUrl.identification(selectedProject.id),
            path: ROUTES.PROJECT_SIDEPANEL_IDENTIFICATION,
            component: ProjectIdentification
        },
        {
            name: t('projects.tabs.gallery'),
            url: getSidepanelUrl.gallery(selectedProject.id),
            path: ROUTES.PROJECT_SIDEPANEL_GALLERY,
            component: ProjectAlbums
        },
        {
            name: t('projects.tabs.activity'),
            url: getSidepanelUrl.activity(selectedProject.id),
            path: ROUTES.PROJECT_SIDEPANEL_ACTIVITY,
            component: () => <ProjectActivity isSmall />
        }
    ];

    const [activeTab, setActiveTab] = useState(history.location.pathname);

    const onTabChange = useCallback(
        e => {
            const { value } = e.target;
            history.push(value);

            setActiveTab(value);
        },
        [history]
    );

    const onProjectStatusChange = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.CLASSIFICATION_PROJECT, { projectId: selectedProject.id }));
    }, [dispatch, selectedProject.id]);

    return (
        <Box className={classes.container}>
            <Box className={classes.spacing}>
                <ProjectSidepanelHeader title={selectedProject.name} />
            </Box>
            <Box className={classes.spacing}>
                <ProjectStatusBanner status={selectedProject.status} date={selectedProject.createdAt} onClick={onProjectStatusChange} />
            </Box>

            {activeTab && <DropdownRouter active={activeTab} routes={routes} onChange={onTabChange} />}

            <Switch>
                {routes.map(({ exact, path, url, component }) => (
                    <Route key={url} exact={exact} path={path} component={component} />
                ))}
            </Switch>
        </Box>
    );
};

ProjectSidepanelContent.propTypes = propTypes;

export default ProjectSidepanelContent;
