import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    activity: {
        count: 5,
        limit: 8,
        page: 1,
        pages: 1,
        rows: [
            {
                id: 1,
                date: '2020-03-18T12:33:00',
                user: 'Ana Ferreira',
                details: {
                    label: 'addProjectPhotos',
                    payload: {
                        total: 3
                    }
                },
                status: null
            },
            {
                id: 2,
                date: '2020-03-14T11:30:00',
                user: 'Pedro Soares',
                details: {
                    label: 'archiveProject'
                },
                status: null
            },
            {
                id: 3,
                date: '2020-03-11T11:59:00',
                user: 'Pedro Soares',
                details: {
                    label: 'addProjectComment'
                },
                status: null
            },
            {
                id: 4,
                date: '2020-03-11T11:30:00',
                user: 'Sofia Marques',
                details: {
                    label: 'addProjectInfo'
                },
                status: null
            },
            {
                id: 5,
                date: '2020-03-10T15:56:00',
                user: 'Ana Ferreira',
                details: {
                    label: 'setProjectStatus'
                },
                status: 3
            }
        ]
    }
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PROJECT_ACTIVITY_SUCCESS:
        case actionTypes.GET_PROJECT_ACTIVITY_FAIL:
            return false;
        case actionTypes.GET_PROJECT_ACTIVITY_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const activity = (state = initialState.activity, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PROJECT_ACTIVITY_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.activity;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getActivity = state => get(state, 'activity', initialState.activity);

const reducer = combineReducers({
    isLoading,
    activity
});

export default reducer;
