import { combineReducers } from 'redux';

import listReducer from './list';
import projectReducer from './project';

export const projectsReducer = combineReducers({
    list: listReducer,
    project: projectReducer
});

export default projectsReducer;
