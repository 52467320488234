import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { camelCase } from 'lodash';
import { Formik, Form, Field } from 'formik';

import { removeEmptyInfoAndRemovePropFromObject } from '../../../utils/encoders';

import {
    actions as modalManagerActions,
    selectors as modalManagerSelectors,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';
import { actions as formLicenseActions, selectors as formLicenseSelectors } from '../../../redux/form/license';
import { selectors as projectSelectors } from '../../../redux/projects/project/details';

import BaseDialog from '../../base-dialog';
import Loading from '../../base-loading';
import BaseInput from '../../base-input';
import BaseDatePicker from '../../base-date-picker';

import useStyles from './LicenseInfo.styles';
import licenseHelper from './helper';

const LicenseInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    // state props
    const licenseInfoModal = useSelector(modalManagerSelectors.getLicenseInfo);
    const licenseDetails = useSelector(formLicenseSelectors.getLicenseDetails);
    const isLicenseLoading = useSelector(formLicenseSelectors.getIsLoading);
    const projectId = useSelector(projectSelectors.getProjectId);

    const handleCloseDialog = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.LICENSE_INFO));
    }, [dispatch]);

    const handleSubmitLicense = useCallback(
        values => {
            dispatch(formLicenseActions.updateLicense(projectId, removeEmptyInfoAndRemovePropFromObject(values)));
        },
        [dispatch, projectId]
    );

    const renderField = (fieldKey, { errors, touched, values, handleChange, handleBlur }) => {
        if (licenseHelper.isDropDownField(fieldKey)) {
            return (
                <FormControl className={classes.dropdownRoot}>
                    <InputLabel shrink>{t(`projects.license.${fieldKey}.title`)}</InputLabel>
                    <Select key={fieldKey} id={fieldKey} name={fieldKey} value={values[fieldKey]} onChange={handleChange}>
                        {licenseHelper.getDropdownOptions(fieldKey).map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {t(`projects.license.${fieldKey}.${camelCase(option.text)}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        }

        if (licenseHelper.isDateField(fieldKey)) {
            return (
                <Field
                    component={({ form: { setFieldValue } }) => (
                        <BaseDatePicker
                            name={fieldKey}
                            InputLabelProps={{ shrink: true }}
                            className={classes.datePicker}
                            value={values[fieldKey]}
                            onChange={value => {
                                setFieldValue(fieldKey, value);
                            }}
                            label={t(`projects.license.${fieldKey}`)}
                        />
                    )}
                    name={fieldKey}
                />
            );
        }

        return (
            <BaseInput
                key={fieldKey}
                label={t(`projects.license.${fieldKey}`)}
                id={fieldKey}
                name={fieldKey}
                required={licenseHelper.formOptions.hasOwnProperty(fieldKey) && licenseHelper.formOptions[fieldKey].required}
                value={values[fieldKey]}
                type={licenseHelper.formOptions[fieldKey].type}
                helperText={errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey) ? t(errors[fieldKey]) : ''}
                error={!!(errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey))}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    };

    return (
        <BaseDialog open={licenseInfoModal.isOpen} onClose={handleCloseDialog} title={t('projects.license.edit')}>
            <Formik initialValues={licenseDetails} onSubmit={handleSubmitLicense} validationSchema={licenseHelper.getValidationSchema()}>
                {({ handleSubmit, ...formVariables }) => (
                    <Form>
                        {isLicenseLoading ? (
                            <Box className={classes.loadingContainer}>
                                <Loading />
                            </Box>
                        ) : (
                            <>
                                <Box className={classes.container}>
                                    {Object.keys(formVariables.values).map(fieldKey => renderField(fieldKey, formVariables))}
                                </Box>

                                <div className={classes.actions}>
                                    <Button onClick={handleCloseDialog} color="secondary" variant="contained">
                                        {t('cancel')}
                                    </Button>
                                    <Button onClick={handleSubmit} disabled={false} variant="outlined" className={classes.submitButton}>
                                        {t('save')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </BaseDialog>
    );
};

export default LicenseInfo;
