import React from 'react';
import PropTypes from 'prop-types';

import ListHeader from './list-header';

const propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            width: PropTypes.string
        })
    ).isRequired,
    children: PropTypes.node.isRequired,
    hideListHeader: PropTypes.bool
};

const defaultProps = {
    hideListHeader: false
};

const List = ({ headers, children, hideListHeader }) => {
    return (
        <>
            {!hideListHeader && <ListHeader headers={headers} />}
            {children}
        </>
    );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
