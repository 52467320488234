import React, { useCallback, useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { isEmpty } from 'lodash';
import config from '../../_config';

import { MapLayerBoundaries, MapLayerPoints } from '../../components/map-layers';

import { actions as territoriesBoundariesActions, selectors as territoriesBoundariesSelectors } from '../../redux/map/territories-boundaries';
import { actions as projectsCentroidActions, selectors as projectsCentroidSelectors } from '../../redux/map/projects-centroid';
import { actions as projectActions } from '../../redux/projects/project/details';

import useStyles from './Map.styles';
import ProjectSidepanel from '../../components/project-sidepanel/ProjectSidepanel';
import ROUTES from '../../utils/routes';

const Map = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id: projectIdParam } = useParams();
    const history = useHistory();
    const [changeClusterId, setChangeClusterId] = useState();
    const [selectedProjectId, setSelectedProjectId] = useState();

    const { mapConfigs } = config;

    const territoriesBoundaries = useSelector(territoriesBoundariesSelectors.getTerritoriesBoundaries);
    const projectsCentroid = useSelector(projectsCentroidSelectors.getProjectsCentroid);

    useEffect(() => {
        dispatch(territoriesBoundariesActions.fetchTerritoriesBoundaries());
        dispatch(projectsCentroidActions.fetchProjectsCentroid());
    }, [dispatch]);

    // open sidepanel by accessing /map/projects/:id route
    useEffect(() => {
        if (!isEmpty(projectIdParam)) {
            setSelectedProjectId(projectIdParam);
        }
    }, [history, projectIdParam]);

    const openSidePanel = useCallback(event => {
        const layer = event.target;
        const { changeClusterId: id } = layer.feature.properties;
        setSelectedProjectId(null);
        setChangeClusterId(id.toString());
    }, []);

    const closeProjectSidepanel = useCallback(() => {
        dispatch(projectActions.reset());
        setChangeClusterId(null);
        setSelectedProjectId(null);
        history.push(ROUTES.MAP);
    }, [history, dispatch]);

    return (
        <div className={classes.root}>
            <MapContainer
                className={classes.mapContainer}
                center={mapConfigs.center}
                zoom={mapConfigs.zoom}
                scrollWheelZoom={mapConfigs.scrollWheelZoom}
                zoomControl={mapConfigs.zoomControl}
            >
                <TileLayer attribution={mapConfigs.disclaimer.title} url={mapConfigs.disclaimer.link} />
                <MapLayerBoundaries data={territoriesBoundaries} />
                <MapLayerPoints data={projectsCentroid} onFeatureClick={openSidePanel} withClusters />
            </MapContainer>
            {(changeClusterId || selectedProjectId) && (
                <ProjectSidepanel changeClusterId={changeClusterId} projectId={selectedProjectId} onClose={closeProjectSidepanel} />
            )}
        </div>
    );
};

export default Map;
