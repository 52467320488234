import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    markerClusterCustom: {
        background: theme.palette.background.cityooCustomElements,
        borderRadius: '50%',
        color: theme.palette.text.white,
        fontSize: '1.2rem',
        fontWeight: 500,
        height: '2.5rem',
        lineHeight: '2.313rem',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        width: '2.5rem',
        borderCollapse: 'separate',
        borderSpacing: '1.563rem',
        '&::after': {
            content: '""',
            position: 'absolute',
            width: 'calc(100% + 1.25rem)',
            height: 'calc(100% + 1.25rem)',
            border: `4px solid ${theme.palette.background.cityooCustomElements}`,
            borderRadius: '50%'
        }
    }
}));
