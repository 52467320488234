import { get } from 'lodash/fp';

import { createSelector } from 'reselect';
import * as fromReducer from './reducer';

const getModalManagerState = get('modalManager');

// Create project
export const getCreateProject = state => fromReducer.getCreateProject(getModalManagerState(state));

export const getCreateProjectType = state => {
    const createProjectModal = getCreateProject(state);

    return createProjectModal.data?.type ?? null;
};

// Create project album
export const getCreateAlbum = state => fromReducer.getCreateAlbum(getModalManagerState(state));

export const getCreateAlbumType = state => {
    const createAlbumModal = getCreateAlbum(state);

    return createAlbumModal.data?.type ?? null;
};

// Image preview
export const getImagePreview = state => fromReducer.getImagePreview(getModalManagerState(state));

// Classification project
export const getClassificationProject = state => fromReducer.getClassificationProject(getModalManagerState(state));

export const getProjectIdFromClassificationProject = createSelector(
    [getClassificationProject],
    classificationProject => classificationProject?.data?.projectId ?? null
);

// Unfilfilled
export const getUnfulfilledModal = state => fromReducer.getUnfulfilledModal(getModalManagerState(state));

// Create user
export const getCreateUser = state => fromReducer.getCreateUser(getModalManagerState(state));

export const getCreateUserType = state => {
    const createProjectModal = getCreateUser(state);

    return createProjectModal.data?.type ?? null;
};

// License Info
export const getLicenseInfo = state => fromReducer.getLicenseInfo(getModalManagerState(state));

// Confirmation
export const getConfirmModal = state => fromReducer.getConfirmModal(getModalManagerState(state));

// Prior Communication
export const getPriorCommunication = state => fromReducer.getPriorCommunication(getModalManagerState(state));
