import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme, IconButton, MenuItem } from '@material-ui/core';

import ActionsGroupContext from '../../../context/ActionsGroupContext';

import useStyles from './ActionGroupItem.styles';

const propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    row: PropTypes.shape({})
};

const defaultProps = {
    row: null
};

const ActionGroupItem = ({ children, title, onClick, row }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const { handleMenuClose } = useContext(ActionsGroupContext);

    const onMenuItemClick = useCallback((ev) => {
        if (handleMenuClose) {
            handleMenuClose(ev);
        }
        onClick(ev, row);
    }, [handleMenuClose, onClick, row]);

    return (
        isMobile ?
            (
                <MenuItem onClick={onMenuItemClick}>
                    {title}
                </MenuItem>
            ) :
            (
                <IconButton
                    className={classes.childButton}
                    aria-label={title}
                    onClick={onMenuItemClick}
                >
                    {children}
                </IconButton>
            )
    );
};

ActionGroupItem.propTypes = propTypes;
ActionGroupItem.defaultProps = defaultProps;

export default ActionGroupItem;
