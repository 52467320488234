import * as Yup from 'yup';

const formOptions = {
    email: {
        required: true
    }
};

const getValidationSchema = () => {
    return Yup.object().shape({
        email: Yup.string().required('mandatory_field').email('invalid_email')
    });
};

export default {
    formOptions,
    getValidationSchema
};
