import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../notifications';

export const fetchTerritoriesBoundaries = () => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_TERRITOTIES_BOUNDARIES_START });

    try {
        const response = await apiSdk.tenantSpatial.list();
        const { data } = response;

        dispatch({ type: actionTypes.GET_TERRITOTIES_BOUNDARIES_SUCCESS, payload: data });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchTerritoriesBoundaries'));
        dispatch({ type: actionTypes.GET_TERRITOTIES_BOUNDARIES_FAIL });
    }
};
