import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),
        marginBot: theme.spacing(1)
    },
    list: {
        marginTop: theme.spacing(4)
    },
    squareAvatar: {
        borderRadius: '0'
    }
}));
