import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Drawer, Box, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import classNames from 'classnames';

import { actions as projectActions, selectors as projectSelectors } from '../../redux/projects/project/details';

import useStyles from './ProjectSidepanel.styles';
import ProjectSidepanelContent from './ProjectSidepanelContent';
import { getSidepanelUrl } from '../../utils/routes';
import BaseLoading from '../base-loading';
import CreateProject from '../modals/create-project';
import ProjectClassification from '../modals/project-classification';

const propTypes = {
    changeClusterId: PropTypes.string,
    projectId: PropTypes.string,
    onClose: PropTypes.func.isRequired
};

const defaultProps = {
    changeClusterId: null,
    projectId: null
};

const ProjectSidepanel = ({ onClose, changeClusterId, projectId }) => {
    const history = useHistory();

    const selectedProject = useSelector(projectSelectors.getProject);
    const isLoadingProject = useSelector(projectSelectors.getIsLoading);

    const [isOpen, setIsOpen] = useState(false);
    const [isRouteUpdated, setIsRouteUpdated] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const openSidepanelWithAnimation = () => {
        setTimeout(() => {
            setIsOpen(true);
        }, 200);
    };

    useEffect(() => {
        if (changeClusterId) {
            dispatch(projectActions.getProjectByClusterId(changeClusterId));
            openSidepanelWithAnimation();
        }
    }, [changeClusterId, dispatch]);

    useEffect(() => {
        if (projectId) {
            dispatch(projectActions.getProject(projectId));
            openSidepanelWithAnimation();
        }
    }, [projectId, dispatch]);

    useEffect(() => {
        if (selectedProject) {
            const openedByPermalink = !isEmpty(projectId);

            if (!openedByPermalink) {
                history.push(getSidepanelUrl.dashboard(selectedProject.id));
            }

            setIsRouteUpdated(true);
        }
    }, [history, selectedProject, setIsRouteUpdated, projectId]);

    if (!changeClusterId && !projectId) return null;

    // eslint-disable-next-line react/prop-types
    const SidepanelContent = ({ project }) => {
        // We have to wait for route to be updated because of the content's internal router Switch
        if (!project || !isRouteUpdated) return null;

        return <ProjectSidepanelContent selectedProject={project} />;
    };

    return (
        <>
            <Drawer
                variant="persistent"
                classes={{
                    paper: classNames(classes.drawer)
                }}
                open={isOpen}
            >
                <Box className={classes.topBar}>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {isLoadingProject ? <BaseLoading /> : <SidepanelContent project={selectedProject} />}
            </Drawer>
            {selectedProject && (
                <>
                    <CreateProject />
                    <ProjectClassification />
                </>
            )}
        </>
    );
};

ProjectSidepanel.defaultProps = defaultProps;
ProjectSidepanel.propTypes = propTypes;

export default ProjectSidepanel;
