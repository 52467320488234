import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
    Add as AddIcon,
    Block as BlockIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    SettingsBackupRestore as SettingsBackupRestoreIcon
} from '@material-ui/icons';

import { actions as usersActions, selectors as usersSelectors } from '../../redux/users';
import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../redux/modal-manager';

import ActionsGroup, { ActionGroupItem } from '../../components/actions-group';
import CollapsibleList, { CollapsibleListItem, CollapsibleListItemHeader, CollapsibleListItemBody } from '../../components/collapsible-list';
import ConfirmModal from '../../components/modals/confirm-modal/ConfirmModal';
import CreateUser from '../../components/modals/create-user';
import FloatingLabel, { CONTENT_TYPES } from '../../components/floating-label';
import Loading from '../../components/base-loading';
import PageHeader from '../../components/page-header';
import PaginationControls from '../../components/pagination-controls';
import UnfulfilledModal from '../../components/modals/unfulfilled-modal';

import useStyles from './Users.styles';

const Users = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const headers = [
        { key: 'fullName', title: t('users.list.fullName') },
        { key: 'email', title: t('users.list.email'), hideOnOpen: true },
        { key: 'phoneNumber', title: t('users.list.phoneNumber'), hideOnOpen: true },
        { key: 'actions', title: t('options'), width: '20%' }
    ];

    const users = useSelector(usersSelectors.getUsers);
    const isLoading = useSelector(usersSelectors.getIsLoading);

    const handleCreateUser = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.CREATE_USER, { type: modalManagerEnumerators.TYPE_CREATE }));
    }, [dispatch]);

    const onEditUser = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.UNFULFILLED));
    }, [dispatch]);

    const onBlockUser = useCallback(
        (ev, user) => {
            ev.stopPropagation();

            dispatch(
                modalManagerActions.openModal(modalManagerEnumerators.CONFIRM_MODAL, {
                    buttons: [
                        {
                            action: () => dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CONFIRM_MODAL)),
                            color: 'secondary',
                            variant: 'contained',
                            text: 'cancel'
                        },
                        {
                            action: () => dispatch(usersActions.blockUser(user.id)),
                            variant: 'outlined',
                            text: 'confirm'
                        }
                    ],
                    title: t('users.confirmModal.blockConfirmationTitle'),
                    message: `${t('users.confirmModal.blockConfirmation')} ${user.fullName}?`
                })
            );
        },
        [dispatch, t]
    );

    const onUnblockUser = useCallback(
        (ev, user) => {
            ev.stopPropagation();

            dispatch(
                modalManagerActions.openModal(modalManagerEnumerators.CONFIRM_MODAL, {
                    buttons: [
                        {
                            action: () => dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CONFIRM_MODAL)),
                            color: 'secondary',
                            variant: 'contained',
                            text: 'cancel'
                        },
                        {
                            action: () => dispatch(usersActions.unblockUser(user.id)),
                            variant: 'outlined',
                            text: 'confirm'
                        }
                    ],
                    title: t('users.confirmModal.unblockConfirmationTitle'),
                    message: `${t('users.confirmModal.unblockConfirmation')} ${user.fullName}?`
                })
            );
        },
        [dispatch, t]
    );

    const onDeleteUser = useCallback(
        (ev, user) => {
            ev.stopPropagation();

            dispatch(
                modalManagerActions.openModal(modalManagerEnumerators.CONFIRM_MODAL, {
                    buttons: [
                        {
                            action: () => dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CONFIRM_MODAL)),
                            color: 'secondary',
                            variant: 'contained',
                            text: 'cancel'
                        },
                        {
                            action: () => dispatch(usersActions.deleteUser(user.id)),
                            variant: 'outlined',
                            text: 'confirm'
                        }
                    ],
                    title: t('users.confirmModal.deleteConfirmationTitle'),
                    message: `${t('users.confirmModal.deleteConfirmation')} ${user.fullName}?`
                })
            );
        },
        [dispatch, t]
    );

    const onPageChange = useCallback((ev, page) => {
        if (page !== users.page) {
            dispatch(usersActions.setPage(page));
        }
    }, [dispatch, users.page]);

    useEffect(() => {
        dispatch(usersActions.getUsers({ limit: users.limit, page: users.page }));
    }, [dispatch, users.limit, users.page]);
    

    return (
        <div>
            <PageHeader title={t('pages.users')}>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleCreateUser}>
                    {t('newUser')}
                </Button>
            </PageHeader>

            <CollapsibleList headers={headers}>
                {isLoading ? (
                    <div className={classes.loadingContainer}>
                        <Loading />
                    </div>
                ) : (
                    users?.rows.map(user => (
                        <CollapsibleListItem key={`list-users-${user.id}`}>
                            <CollapsibleListItemHeader headers={headers}>
                                <Typography key="fullName" variant="body1" className={classnames({ [classes.disabled]: !user.isActive })}>
                                    {user.fullName}
                                </Typography>
                                <Typography key="email" variant="body1" className={classnames({ [classes.disabled]: !user.isActive })}>
                                    {user.email}
                                </Typography>
                                <Typography key="phoneNumber" variant="body1" className={classnames({ [classes.disabled]: !user.isActive })}>
                                    {user.phoneNumber}
                                </Typography>
                                <ActionsGroup key="actions">
                                    <ActionGroupItem key="edit" title={t('edit')} row={user} onClick={onEditUser}>
                                        <EditIcon fontSize="small" className={classnames({ [classes.disabled]: !user.isActive })} />
                                    </ActionGroupItem>
                                    {user.isActive ? (
                                        <ActionGroupItem key="block" title={t('block')} row={user} onClick={onBlockUser}>
                                            <BlockIcon fontSize="small" />
                                        </ActionGroupItem>
                                    ) : (
                                        <ActionGroupItem key="unblock" title={t('unblock')} row={user} onClick={onUnblockUser}>
                                            <SettingsBackupRestoreIcon fontSize="small" className={classes.disabled} />
                                        </ActionGroupItem>
                                    )}
                                    <ActionGroupItem key="remove" title={t('delete')} row={user} onClick={onDeleteUser}>
                                        <DeleteIcon fontSize="small" className={classnames({ [classes.disabled]: !user.isActive })} />
                                    </ActionGroupItem>
                                </ActionsGroup>
                            </CollapsibleListItemHeader>
                            <CollapsibleListItemBody>
                                <Box display="flex" flexDirection="row">
                                    <Box flexGrow={1}>
                                        <FloatingLabel label={t('users.list.firstName')} content={user.firstName} />
                                        <FloatingLabel label={t('users.list.lastName')} content={user.lastName} />
                                        <FloatingLabel label={t('users.list.email')} content={user.email} contentType={CONTENT_TYPES.EMAIL} />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <FloatingLabel label={t('users.list.tenant')} content={user.tenant?.name} />
                                        <FloatingLabel label={t('users.list.phoneNumber')} content={user.phoneNumber} />
                                        <FloatingLabel label={t('users.list.jobRole')} content={user.jobRole} />
                                    </Box>
                                </Box>
                            </CollapsibleListItemBody>
                        </CollapsibleListItem>
                    ))
                )}
            </CollapsibleList>

            <PaginationControls pages={users.pages} page={users.page} onChange={onPageChange} />

            <CreateUser />
            <UnfulfilledModal />
            <ConfirmModal />
        </div>
    );
};

export default Users;
