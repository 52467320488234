/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/styles';

import ROUTES from '../../utils/routes';

// styles
import useStyles from './Layout.styles';

// components
import Header from '../header';
import Sidebar from '../sidebar';

// pages
import ProjectsPage from '../../pages/projects';
import MapPage from '../../pages/map';
import Error from '../../pages/error';
import DashboardPage from '../../pages/dashboard';
import UsersPage from '../../pages/users';

// context
import { useLayoutState, useLayoutDispatch, openSidebar, setFloatingSideBar } from '../../context/LayoutContext';
import { selectors as accountSelectors } from '../../redux/account';

const Layout = ({ history }) => {
    const classes = useStyles();
    const theme = useTheme();

    // global
    const { isSidebarOpened, isFloatingSidebar } = useLayoutState();
    const layoutDispatch = useLayoutDispatch();

    const isAuthenticated = useSelector(accountSelectors.isAuthenticated);

    const handleWindowWidthChange = () => {
        const windowWidth = window.innerWidth;
        const breakpointWidth = theme?.breakpoints.values.sm;
        const isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && !isFloatingSidebar) {
            setFloatingSideBar(layoutDispatch, true);
        } else if (!isSmallScreen) {
            if (!isSidebarOpened) {
                openSidebar(layoutDispatch);
            }

            if (isFloatingSidebar) {
                setFloatingSideBar(layoutDispatch, false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowWidthChange);
        handleWindowWidthChange();

        return () => {
            window.removeEventListener('resize', handleWindowWidthChange);
        };
    });

    return (
        <div className={classes.root}>
            {isAuthenticated ? (
                <>
                    <Header history={history} />
                    <Sidebar />
                    <div
                        className={classnames(classes.content, {
                            [classes.contentShift]: isSidebarOpened
                        })}
                    >
                        <div className={classes.fakeToolbar} />
                        <Switch>
                            <Route exact path={ROUTES.HOME} render={() => <Redirect to={ROUTES.MAP} />} />
                            <Route path={ROUTES.PROJECT_SIDEPANEL} component={MapPage} />
                            <Route path={ROUTES.MAP} component={MapPage} />
                            <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
                            <Route path={ROUTES.PROJECTS} component={ProjectsPage} />
                            <Route path={ROUTES.USERS} component={UsersPage} />
                            <Route component={Error} />
                        </Switch>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default withRouter(Layout);
