import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
        margin: '0.5rem 0',
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.1)',
        background: 'transparent',
        borderRadius: '0.3125rem',
        minHeight: '2.875rem',
        height: '2.875rem'
    },
    fixed: {
        background: theme.palette.background.white,
        borderRadius: '0.3125rem'
    },
    indicator: {
        display: 'none'
    }
}));
