const reducerName = '@cityoo-tenant/notifications';

export const RESET = `${reducerName}/RESET`;

export const OPEN_NOTIFICATION = `${reducerName}/OPEN_NOTIFICATION`;
export const OPEN_SUCCESS_NOTIFICATION = `${reducerName}/OPEN_SUCCESS_NOTIFICATION`;
export const OPEN_WARNING_NOTIFICATION = `${reducerName}/OPEN_WARNING_NOTIFICATION`;
export const OPEN_ERROR_NOTIFICATION = `${reducerName}/OPEN_ERROR_NOTIFICATION`;
export const OPEN_INFO_NOTIFICATION = `${reducerName}/OPEN_INFO_NOTIFICATION`;
export const CLOSE_NOTIFICATION = `${reducerName}/CLOSE_NOTIFICATION`;
