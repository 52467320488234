import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../constants';
import * as actionTypes from './actionTypes';
import notificationsState from './enumerators';

const initialState = {
    notification: {}
};

export const notification = (state = initialState.notification, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_NOTIFICATION:
            return {
                ...state,
                severity: action.payload.severity,
                message: action.payload.message
            };
        case actionTypes.OPEN_SUCCESS_NOTIFICATION:
            return {
                ...state,
                severity: notificationsState.SUCCESS,
                message: action.payload.message
            };
        case actionTypes.OPEN_WARNING_NOTIFICATION:
            return {
                ...state,
                severity: notificationsState.WARNING,
                message: action.payload.message
            };
        case actionTypes.OPEN_ERROR_NOTIFICATION:
            return {
                ...state,
                severity: notificationsState.ERROR,
                message: action.payload.message
            };
        case actionTypes.OPEN_INFO_NOTIFICATION:
            return {
                ...state,
                severity: notificationsState.INFO,
                message: action.payload.message
            };
        case actionTypes.CLOSE_NOTIFICATION:
            return {
                ...initialState.notification,
                severity: state.severity
            };
        case actionTypes.RESET:
            return initialState.notification;
        default:
            return state;
    }
};

export const getNotifications = state => get(state, 'notification', initialState.notification);

const reducer = combineReducers({
    notification
});

export default reducer;
