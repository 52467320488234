import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        borderRadius: '1.125rem',
        padding: theme.spacing(3)
    },
    spacing: {
        marginBottom: theme.spacing(3)
    }
}));
