import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    priorCommunication: {}
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PRIOR_COMMUNICATION_SUCCESS:
        case actionTypes.GET_PRIOR_COMMUNICATION_FAIL:
            return false;
        case actionTypes.GET_PRIOR_COMMUNICATION_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const priorCommunication = (state = initialState.priorCommunication, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PRIOR_COMMUNICATION_SUCCESS:
            return action.payload;
        case actionTypes.GET_PRIOR_COMMUNICATION_FAIL:
        case actionTypes.RESET:
            return initialState.priorCommunication;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getPriorCommunication = state => get(state, 'priorCommunication', initialState.priorCommunication);

const reducer = combineReducers({
    isLoading,
    priorCommunication
});

export default reducer;
