/* eslint-disable quotes */
import tinycolor from 'tinycolor2';

const primary = '#a2c9e3';
const secondary = '#f0f0f0';
const warning = '#FF9800';
const success = '#4CAF4F';
const successText = '#269972';
const info = '#2296F3';
const white = '#ffffff';
const cityooText = '#878787';
const black = '#000000';
const cityooCustomElements = '#66a6d1';
const error = '#c00000';
const tabsSubtitles = '#5c5c5c';
const contrastText = '#425867';
const secondaryContainedText = '#757575';
const unfulfilledTitle = '#5c5c5c';

const lightenRate = 7.5;
const darkenRate = 15;

export default {
    palette: {
        primary: {
            main: primary,
            light: tinycolor(primary).lighten(lightenRate).toHexString(),
            dark: tinycolor(primary).darken(darkenRate).toHexString(),
            contrastText: white
        },
        secondary: {
            main: secondary,
            white,
            light: tinycolor(secondary).lighten(lightenRate).toHexString(),
            dark: tinycolor(secondary).darken(darkenRate).toHexString(),
            contrastText
        },
        warning: {
            main: warning,
            light: tinycolor(warning).lighten(lightenRate).toHexString(),
            dark: tinycolor(warning).darken(darkenRate).toHexString()
        },
        success: {
            main: success,
            light: tinycolor(success).lighten(lightenRate).toHexString(),
            dark: tinycolor(success).darken(darkenRate).toHexString()
        },
        info: {
            main: info,
            light: tinycolor(info).lighten(lightenRate).toHexString(),
            dark: tinycolor(info).darken(darkenRate).toHexString()
        },
        error: {
            main: error,
            light: tinycolor(error).lighten(lightenRate).toHexString(),
            dark: tinycolor(error).darken(darkenRate).toHexString()
        },
        text: {
            primary: black,
            secondary: '#6E6E6E',
            hint: '#B9B9B9',
            link: primary,
            white,
            cityooCustomElements,
            error,
            success: successText,
            tabsSubtitles
        },
        background: {
            default: '#f2f2f2',
            light: '#F4F6FE',
            cityooCustomElements,
            white,
            primary,
            selected: '#edf5ff'
        },
        captGrey: {
            main: '#707182'
        },
        cityooText,
        unfulfilledTitle,
        black,
        statuses: {
            new: '#0000ff',
            pending: '#00248f',
            legal: '#189982',
            illegal: '#ff921e',
            recurrent: '#c00000',
            archived: '#d0d0d9'
        }
    },
    customShadows: {
        light: '0 4px 4px 0 rgba(0, 0, 0, 0.1)',
        medium: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)'
    },
    overrides: {
        typography: {
            fontFamily: "'Hind', sans-serif"
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    backgroundColor: primary,
                    color: white
                }
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: primary
            }
        },
        MuiPickersDay: {
            day: {
                color: black,
                backgroundColor: white,
                borderRadius: '1.125rem'
            },
            daySelected: {
                backgroundColor: primary,
                color: white
            },
            dayDisabled: {
                color: cityooText
            },
            current: {
                color: ''
            }
        },
        MuiTextField: {
            root: {
                margin: '.4rem 0',
                fontSize: '1rem'
            }
        },
        MuiInput: {
            input: {
                fontSize: '1.125rem',
                color: black
            },
            underline: {
                '&::before': {
                    borderBottomColor: cityooText
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: cityooText,
                fontSize: '1.125rem'
            }
        },
        MuiStepper: {
            root: {
                padding: '0.75rem'
            }
        },
        MuiStepLabel: {
            iconContainer: {
                marginRight: 8,
                paddingRight: 0,
                borderWidth: 0
            },
            active: {
                '& $label': {
                    color: cityooText
                }
            },
            label: {
                color: cityooText,
                '&$active': {
                    color: cityooText
                },
                fontSize: '0.875rem'
            }
        },
        MuiStepIcon: {
            root: {
                '&$active': {
                    borderWidth: 0,
                    color: contrastText,
                    '& $text': {
                        fill: white
                    }
                },
                '&$completed': {
                    borderWidth: 0,
                    color: contrastText,
                    '& $text': {
                        fill: white
                    }
                },
                border: `1px solid ${contrastText}`,
                borderRadius: '50%',
                color: white
            },
            text: {
                fill: primary
            }
        },
        MuiButton: {
            outlined: {
                color: contrastText,
                border: `solid 0.5px ${secondary}`,
                boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.1)'
            },
            outlinedPrimary: {
                color: primary,
                border: `solid 0.5px ${secondary}`,
                boxShadow: '4px 4px 4px 0 rgba(0, 0, 0, 0.1)',
                '&:hover': {
                    border: `solid 0.5px ${secondary}`
                }
            },
            containedSecondary: {
                color: secondaryContainedText
            },
            root: {
                minWidth: '7rem',
                height: '2.188rem',
                alignItems: 'center'
            },
            label: {
                textTransform: 'none'
            }
        },
        MuiDialogContent: {
            root: {
                height: '100%',
                display: 'flex'
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: '#4A4A4A1A'
            }
        },
        MuiMenu: {
            paper: {
                boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
            }
        },
        MuiSelect: {
            icon: {
                color: '#B9B9B9'
            }
        },
        MuiTouchRipple: {
            child: {
                backgroundColor: 'white'
            }
        },
        MuiTableRow: {
            root: {
                height: 56
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(224, 224, 224, .5)'
            },
            head: {
                fontSize: '0.95rem'
            },
            body: {
                fontSize: '0.95rem'
            }
        },
        MuiTab: {
            root: {
                '@media (min-width: 600px)': {
                    minWidth: '134px'
                }
            }
        },
        MuiDropzoneArea: {
            icon: {
                width: 0,
                height: 0
            },
            text: {
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem'
            },
            textContainer: {
                height: '0.625rem'
            }
        },
        MuiDropzonePreviewList: {
            root: {
                alignContent: 'center'
            },
            image: {
                height: '12.5rem'
            },
            removeButton: {
                left: 0,
                top: '40%',
                position: 'absolute',
                margin: 'auto',
                display: 'block'
            }
        },
        MuiAlert: {
            root: {
                minWidth: '25rem'
            },
            message: {
                fontSize: '1rem'
            }
        },
        MuiPaginationItem: {
            root: {
                background: white,
                boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
                color: black,
                width: '3.125rem',
                height: '2.5rem'
            },
            ellipsis: {
                background: 'transparent',
                boxShadow: 'none'
            },
            textPrimary: {
                '&$selected': {
                    color: white,
                    backgroundColor: contrastText
                }
            }
        }
    }
};
