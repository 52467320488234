import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';
import * as modalManagerActionTypes from '../../modal-manager/actionTypes';
import modalActionEnumerators from '../../modal-manager/enumerators';

const initialAlbumDetails = {
    name: '',
    comments: '',
    files: []
};

const initialState = {
    isLoading: false,
    albumDetails: initialAlbumDetails
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_ALBUM_START:
            return true;
        case actionTypes.SUBMIT_ALBUM_SUCCESS:
        case actionTypes.SUBMIT_ALBUM_FAIL:
            return false;
        case modalManagerActionTypes.OPEN_CREATE_ALBUM:
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const albumDetails = (state = initialState.albumDetails, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case modalManagerActionTypes.OPEN_CREATE_ALBUM: {
            if (action.payload?.type !== modalActionEnumerators.TYPE_EDIT) {
                return initialState.albumDetails;
            }

            const {
                selectedAlbum: { id, name, comments, files }
            } = action.payload;

            return {
                id,
                name: name ?? initialState.name,
                comments: comments ?? initialState.comments,
                files: files ?? initialState.files
            };
        }
        case actionTypes.RESET:
            return initialState.albumDetails;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getAlbumDetails = state => get(state, 'albumDetails', initialState.albumDetails);

const reducer = combineReducers({
    isLoading,
    albumDetails
});

export default reducer;
