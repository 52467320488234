/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useMap, GeoJSON } from 'react-leaflet';
import * as L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import locationIconUrl from '../../../assets/location.svg';

import { getGeoJSONStyle } from '../../../utils/geolocation';

import useStyles from './MapLayerPoints.styles';

const FeautrePointShape = PropTypes.arrayOf(
    PropTypes.shape({
        type: PropTypes.string,
        geometry: PropTypes.shape({
            coordinates: PropTypes.array,
            type: PropTypes.string
        }),
        properties: PropTypes.object
    })
);

const propTypes = {
    data: PropTypes.shape({
        type: PropTypes.string,
        features: FeautrePointShape
    }),
    onFeatureClick: PropTypes.func,
    fitBounds: PropTypes.bool,
    withClusters: PropTypes.bool,
    style: PropTypes.object
};

const defaultProps = {
    data: {},
    fitBounds: true,
    withClusters: false,
    style: {},
    onFeatureClick: null
};

const locationIcon = new L.Icon({
    iconUrl: locationIconUrl,
    iconSize: [40, 40],
    popupAnchor: [0, -15],
    shadowAnchor: [13, 28]
});

const MapLayer = ({ data, fitBounds, style, onFeatureClick, withClusters }) => {
    const map = useMap();
    const classes = useStyles();

    const createClusterCustomIcon = cluster => {
        return L.divIcon({
            html: `<div>${cluster.getChildCount()}</div>`,
            className: classes.markerClusterCustom,
            iconSize: L.point(40, 40, true)
        });
    };

    const onEachFeature = (_feature, layer) => {
        layer.on({
            click: onFeatureClick
        });
    };

    const pointToLayer = (_feature, latlng) =>
        L.marker(latlng, {
            icon: locationIcon
        });

    useEffect(() => {
        if (!isEmpty(data?.features) && fitBounds) {
            const geojsonLayer = L.geoJson(data);
            map.fitBounds(geojsonLayer.getBounds());
        }
    }, [data, map, fitBounds]);

    if (withClusters) {
        return (
            <>
                {data.features && data.features.length && (
                    <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
                        <GeoJSON
                            data={data}
                            style={isEmpty(style) ? getGeoJSONStyle() : style}
                            onEachFeature={onEachFeature}
                            pointToLayer={pointToLayer}
                        />
                    </MarkerClusterGroup>
                )}
            </>
        );
    }

    return (
        <>
            {data.features && data.features.length && (
                <GeoJSON data={data} style={isEmpty(style) ? getGeoJSONStyle() : style} onEachFeature={onEachFeature} pointToLayer={pointToLayer} />
            )}
        </>
    );
};

MapLayer.propTypes = propTypes;
MapLayer.defaultProps = defaultProps;

export default MapLayer;
