import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';
import * as modalManagerActionTypes from '../../modal-manager/actionTypes';

import { removeEmptyInfoAndRemovePropFromObject } from '../../../utils/encoders';

const initialStatePriorCommunicationDetails = {
    county: '',
    intendedPurpose: '',
    date: null,
    processNumber: ''
};

const initialState = {
    isLoading: false,
    priorCommunicationDetails: initialStatePriorCommunicationDetails
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.UPDATE_PRIOR_COMMUNICATION_START:
            return true;
        case actionTypes.UPDATE_PRIOR_COMMUNICATION_SUCCESS:
        case actionTypes.UPDATE_PRIOR_COMMUNICATION_FAIL:
            return false;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const priorCommunicationDetails = (state = initialState.priorCommunicationDetails, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case modalManagerActionTypes.OPEN_PRIOR_COMMUNICATION_INFO: {
            const priorCommunicationInfo = removeEmptyInfoAndRemovePropFromObject(action.payload, ['id', 'projectId']);

            return Object.keys(initialStatePriorCommunicationDetails).reduce((acc, priorCommunicationDetailKey) => {
                const existPriorCommunicationInfo = Object.keys(priorCommunicationInfo).find(
                    priorCommunicationKey => priorCommunicationKey === priorCommunicationDetailKey
                );

                if (existPriorCommunicationInfo) {
                    const priorCommunicationValue = priorCommunicationInfo[existPriorCommunicationInfo];

                    return { ...acc, [priorCommunicationDetailKey]: priorCommunicationValue };
                }

                return { ...acc, [priorCommunicationDetailKey]: initialStatePriorCommunicationDetails[priorCommunicationDetailKey] };
            }, {});
        }
        case actionTypes.RESET:
            return initialState.priorCommunicationDetails;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getPriorCommunicationDetails = state => get(state, 'priorCommunicationDetails', initialState.priorCommunicationDetails);

const reducer = combineReducers({
    isLoading,
    priorCommunicationDetails
});

export default reducer;
