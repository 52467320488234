/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';

const propTypes = {
    value: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const BaseDatePicker = ({ value, onChange, label, ...rest }) => (
    <DatePicker {...rest} autoOk label={label} format="DD/MM/yyyy" value={value} onChange={onChange} />
);

BaseDatePicker.propTypes = propTypes;

export default BaseDatePicker;
