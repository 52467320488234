import * as actionTypes from './actionTypes';
import config from '../../_config';

export const login = loginData => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.LOGIN_START });

    try {
        const response = await apiSdk.auth.login(loginData);
        const { data } = response;

        localStorage.setItem(config.TOKENS, JSON.stringify({ token: data.token, refreshToken: data.refreshToken }));

        dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: data.user });
    } catch (error) {
        if (error?.response?.data?.description?.isActive === false) {
            dispatch({ type: actionTypes.LOGIN_FAIL, payload: 'error.blockedUser' });
        } else {
            dispatch({ type: actionTypes.LOGIN_FAIL, payload: 'login_error' });
        }
    }
};

export const logout = () => async dispatch => {
    localStorage.setItem(config.TOKENS, null);

    dispatch({ type: actionTypes.RESET });
};

export const getMe = () => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_ME_START });

    try {
        const response = await apiSdk.auth.getMe();
        const { data } = response;

        dispatch({ type: actionTypes.GET_ME_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: actionTypes.GET_ME_FAIL });
    }
};

export const recoverPassword = email => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.RECOVER_PASSWORD_START });

    try {
        await apiSdk.auth.recoverPassword(email);

        dispatch({ type: actionTypes.RECOVER_PASSWORD_SUCCESS });
    } catch (error) {
        dispatch({ type: actionTypes.RECOVER_PASSWORD_FAIL });
    }
};

export const resetPassword = data => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.RESET_PASSWORD_START });

    try {
        await apiSdk.auth.resetPassword(data);

        dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
    } catch (error) {
        dispatch({ type: actionTypes.RESET_PASSWORD_FAIL });
    }
};
