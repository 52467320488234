/* eslint-disable quotes */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        height: '3.75rem',
        width: '100%',
        backgroundColor: theme.palette.background.white,
        marginBottom: theme.spacing(4),
        borderRadius: '0.625rem',
        boxShadow: theme.customShadows.light
    },
    input: {
        height: '3.75rem',
        width: '100%',
        borderWidth: 0,
        fontFamily: "'Hind', sans-serif'",
        borderRadius: '0.625rem',
        fontSize: '1.25rem',
        fontWeight: 500,
        fontStretch: 'normal',
        letterSpacing: '3px',
        textAlign: 'center',
        color: theme.palette.text.cityooCustomElements,
        '&::-webkit-input-placeholder': { color: theme.palette.text.cityooCustomElements },
        '&:indeterminate': {
            backgroundColor: 'blue'
        }
    },
    iconVisibility: {
        display: 'flex',
        justifyContent: 'center',
        width: '4rem',
        height: '3.45rem',
        margin: '0.10rem 0.1rem 0.10rem 0',
        borderRadius: '0.625rem',
        backgroundColor: 'transparent',
        position: 'absolute',
        right: 0
    }
}));

export default useStyles;
