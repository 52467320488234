import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../../modal-manager/actionTypes';

import { actions as notificationsActions } from '../../notifications';
import { actions as licensingActions } from '../../projects/project/licensing';

export const updateLicense = (projectId, licenseToSubmit) => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.UPDATE_LICENSE_START });

    try {
        await apiSdk.projectLicensing.update(projectId, licenseToSubmit);

        dispatch({ type: actionTypes.UPDATE_LICENSE_SUCCESS });
        dispatch({ type: modalActionTypes.CLOSE_LICENSE_INFO });

        dispatch(licensingActions.getLicensing(projectId));

        dispatch(notificationsActions.openSuccessNotification('success.updateLicense'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.updateLicense'));

        dispatch({ type: actionTypes.UPDATE_LICENSE_FAIL });
    }
};
