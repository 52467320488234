import { get } from 'lodash';
import { combineReducers } from 'redux';
import { DEFAULT_ACTION } from '../constants';

import * as actionTypes from './actionTypes';

const initalStateWithData = {
    isOpen: false,
    data: null
};

const initialState = {
    createAlbum: initalStateWithData,
    createProject: initalStateWithData,
    classificationProject: initalStateWithData,
    imagePreview: initalStateWithData,
    createUser: initalStateWithData,
    licenseInfo: initalStateWithData,
    priorCommunication: initalStateWithData,
    unfulfilledModal: {
        isOpen: false,
        feature: ''
    },
    confirmModal: initalStateWithData
};

export const createAlbum = (state = initialState.createAlbum, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_CREATE_ALBUM:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_CREATE_ALBUM:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.createAlbum;
        default:
            return state;
    }
};

export const createProject = (state = initialState.createProject, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_CREATE_PROJECT:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_CREATE_PROJECT:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.createProject;
        default:
            return state;
    }
};

export const classificationProject = (state = initialState.classificationProject, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_CLASSIFICATION_PROJECT:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_CLASSIFICATION_PROJECT:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.classificationProject;
        default:
            return state;
    }
};

export const imagePreview = (state = initialState.imagePreview, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_IMAGE_PREVIEW:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_IMAGE_PREVIEW:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.imagePreview;
        default:
            return state;
    }
};

export const unfulfilledModal = (state = initialState.unfulfilledModal, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_UNFULFILLED_MODAL:
            return { isOpen: true, feature: action.payload };
        case actionTypes.CLOSE_UNFULFILLED_MODAL:
            return { isOpen: false };
        case actionTypes.RESET:
            return initialState.unfulfilledModal;
        default:
            return state;
    }
};

export const createUser = (state = initialState.createUser, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_CREATE_USER:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_CREATE_USER:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.createUser;
        default:
            return state;
    }
};

export const licenseInfo = (state = initialState.licenseInfo, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_LICENSE_INFO:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_LICENSE_INFO:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.licenseInfo;
        default:
            return state;
    }
};

export const confirmModal = (state = initialState.confirmModal, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_CONFIRM_MODAL:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_CONFIRM_MODAL:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.confirmModal;
        default:
            return state;
    }
};

export const priorCommunication = (state = initialState.priorCommunication, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_PRIOR_COMMUNICATION_INFO:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_PRIOR_COMMUNICATION_INFO:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.priorCommunication;
        default:
            return state;
    }
};

export const getCreateAlbum = state => get(state, 'createAlbum', initialState.createAlbum);

export const getCreateProject = state => get(state, 'createProject', initialState.createProject);

export const getClassificationProject = state => get(state, 'classificationProject', initialState.classificationProject);

export const getImagePreview = state => get(state, 'imagePreview', initialState.imagePreview);

export const getUnfulfilledModal = state => get(state, 'unfulfilledModal', initialState.unfulfilledModal);

export const getCreateUser = state => get(state, 'createUser', initialState.createUser);

export const getLicenseInfo = state => get(state, 'licenseInfo', initialState.licenseInfo);

export const getConfirmModal = state => get(state, 'confirmModal', initialState.confirmModal);

export const getPriorCommunication = state => get(state, 'priorCommunication', initialState.priorCommunication);

const reducer = combineReducers({
    createAlbum,
    createProject,
    classificationProject,
    imagePreview,
    unfulfilledModal,
    createUser,
    licenseInfo,
    confirmModal,
    priorCommunication
});

export default reducer;
