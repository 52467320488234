import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        background: theme.palette.background.white,
        boxShadow: theme.customShadows.medium,
        padding: '1rem 2rem'
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));
