import { combineReducers } from 'redux';

import projectReducer from './project';
import albumReducer from './album';
import userReducer from './user';
import licenseReducer from './license';
import priorCommunicationReducer from './prior-communication';

const reducer = combineReducers({
    project: projectReducer,
    album: albumReducer,
    user: userReducer,
    license: licenseReducer,
    priorCommunication: priorCommunicationReducer
});

export default reducer;
