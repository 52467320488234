/* eslint-disable react/prop-types */
import React from 'react';
import { Divider, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../../context/LayoutContext';

// styles
import useStyles from './SidebarLink.styles';

const SidebarLink = ({ link, callback, icon, label, location, isSidebarOpened, nested, type }) => {
    const classes = useStyles();
    const layoutDispatch = useLayoutDispatch();
    const { isFloatingSidebar } = useLayoutState();

    const isLinkActive = link && (location.pathname === link || location.pathname.indexOf(link) !== -1);

    const handleOnClick = () => {
        if (callback) {
            callback();
        }

        if (isFloatingSidebar) {
            toggleSidebar(layoutDispatch);
        }
    };

    if (type === 'title') {
        return (
            <Typography
                className={classnames(classes.linkText, classes.sectionTitle, {
                    [classes.linkTextHidden]: !isSidebarOpened
                })}
            >
                {label}
            </Typography>
        );
    }

    if (type === 'divider') return <Divider className={classes.divider} />;

    return (
        <ListItem
            button
            component={link && Link}
            to={link}
            onClick={handleOnClick}
            className={classes.link}
            classes={{
                root: classnames(classes.linkRoot, {
                    [classes.linkActive]: isLinkActive && !nested,
                    [classes.linkNested]: nested
                })
            }}
            disableRipple
        >
            {!nested && (
                <ListItemIcon
                    className={classnames(classes.linkIcon, {
                        [classes.linkIconActive]: isLinkActive
                    })}
                >
                    {icon}
                </ListItemIcon>
            )}
            <ListItemText
                className={classnames(classes.linkTextParent)}
                classes={{
                    primary: classnames(classes.linkText, {
                        [classes.linkTextActive]: isLinkActive,
                        [classes.linkTextHidden]: !isSidebarOpened
                    })
                }}
                primary={label}
            />
        </ListItem>
    );
};

export default SidebarLink;
