import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputBase } from '@material-ui/core';
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityOnIcon } from '@material-ui/icons';

import useStyles from './BaseInputCityoo.styles';

const propTypes = {
    placeholder: PropTypes.string,
    isPasswordVisible: PropTypes.bool,
    onChangePasswordVisibility: PropTypes.func
};

const defaultProps = {
    placeholder: '',
    isPasswordVisible: false,
    onChangePasswordVisibility: null
};

const BaseInputCityoo = ({ placeholder, isPasswordVisible, onChangePasswordVisibility, ...rest }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <InputBase
                {...rest}
                inputProps={{
                    autoComplete: 'new-password'
                }}
                type={!isPasswordVisible && onChangePasswordVisibility ? 'password' : 'text'}
                className={classes.input}
                classes={{
                    input: classes.input
                }}
                placeholder={placeholder}
            />
            {onChangePasswordVisibility && (
                <div className={classes.iconVisibility}>
                    <IconButton className={classes.iconVisibility} size="small" onClick={onChangePasswordVisibility}>
                        {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                    </IconButton>
                </div>
            )}
        </div>
    );
};

BaseInputCityoo.propTypes = propTypes;
BaseInputCityoo.defaultProps = defaultProps;

export default BaseInputCityoo;
