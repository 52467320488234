import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';

import {
    selectors as modalManagerSelectors,
    actions as modalManagerActions,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';

import BaseDialog from '../../base-dialog';
import Unfulfilled from '../../unfulfilled';

const UnfulfilledModal = () => {
    const dispatch = useDispatch();

    const unfulfilledModal = useSelector(modalManagerSelectors.getUnfulfilledModal);

    const handleClose = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.UNFULFILLED));
    }, [dispatch]);

    if (!unfulfilledModal.isOpen) {
        return null;
    }

    return (
        <BaseDialog disableBackdropClick open onClose={handleClose}>
            <Box width="100%" padding="5rem">
                <Unfulfilled small message={unfulfilledModal.feature} />
            </Box>
        </BaseDialog>
    );
};

export default UnfulfilledModal;
