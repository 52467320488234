import { combineReducers } from 'redux';

import countriesReducer from './countries';
import countiesReducer from './counties';
import districtsReducer from './districts';
import parishesReducer from './parishes';
import selectedOptionsReducer from './selected-options';

export const formReducer = combineReducers({
    countries: countriesReducer,
    counties: countiesReducer,
    districts: districtsReducer,
    parishes: parishesReducer,
    selectedOptions: selectedOptionsReducer
});

export default formReducer;
