import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@material-ui/core';

import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../../redux/modal-manager';
import { actions as projectActions, selectors as projectSelectors } from '../../../redux/projects/project/details';

import ConfirmModal from '../../../components/modals/confirm-modal/ConfirmModal';

import useStyles from './ProjectDelete.styles';

const ProjectDelete = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectProject = useSelector(projectSelectors.getProject);
    const isLoadingDelete = useSelector(projectSelectors.getIsLoadingDelete);
    
    const onDeleteProject = useCallback(
        (ev) => {
            ev.stopPropagation();

            dispatch(
                modalManagerActions.openModal(modalManagerEnumerators.CONFIRM_MODAL, {
                    buttons: [
                        {
                            action: () => dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CONFIRM_MODAL)),
                            color: 'secondary',
                            variant: 'contained',
                            text: 'cancel'
                        },
                        {
                            action: () => dispatch(projectActions.deleteProject(selectProject.id)),
                            variant: 'outlined',
                            text: 'confirm'
                        }
                    ],
                    title: t('projects.confirmModal.deleteConfirmationTitle'),
                    message: `${t('projects.confirmModal.deleteConfirmation')} ${selectProject.name}?`
                })
            );
        },
        [dispatch, t, selectProject]
    );

    return (
        <>
            <Box className={classes.container} display="flex" alignItems="flex-end" justifyContent="flex-end">
                <Button color="primary" size="small" onClick={onDeleteProject} disabled={isLoadingDelete || !selectProject.id}>
                    {t('users.deleteProject.action')}
                </Button>
            </Box>

            <ConfirmModal />
        </>
    );
};

export default ProjectDelete;
