import { combineReducers } from 'redux';

import activityReducer from './activity';
import detailsReducer from './details';
import timelineReducer from './timeline';
import albumsReducer from './albums';
import licensingReducer from './licensing';
import priorCommunicationReducer from './prior-communication';

export const reducer = combineReducers({
    activity: activityReducer,
    details: detailsReducer,
    timeline: timelineReducer,
    albums: albumsReducer,
    licensing: licensingReducer,
    priorCommunication: priorCommunicationReducer
});

export default reducer;
