import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../../modal-manager/actionTypes';

import { actions as usersActions } from '../../users';
import { actions as notificationsActions } from '../../notifications';

export const submitUser = userToSubmit => async (dispatch, getState, { apiSdk }) => {
    dispatch({ type: actionTypes.SUBMIT_USER_START });

    const state = getState();

    try {
        await apiSdk.users.create(userToSubmit);

        dispatch({ type: actionTypes.SUBMIT_USER_SUCCESS });
        dispatch({ type: modalActionTypes.CLOSE_CREATE_USER });

        dispatch(usersActions.getUsers({ limit: state.users?.users?.limit, page: state.users?.users?.page }));
        dispatch(notificationsActions.openSuccessNotification('success.createUser'));
    } catch (error) {
        if (error?.response?.data?.description?.field === 'email') {
            dispatch(notificationsActions.openErrorNotification('error.createUserAlreadyExists'));
        } if (error?.response?.data?.description?.field === 'deletedAt') {
            dispatch(notificationsActions.openErrorNotification('error.emailAlreadyUsedByDeletedUser'));
        } else {
            dispatch(notificationsActions.openErrorNotification('error.createUser'));
        }

        dispatch({ type: actionTypes.SUBMIT_USER_FAIL });
    }
};

export const updateUserField = (field, value) => ({
    type: actionTypes.UPDATE_USER_FIELD,
    payload: {
        field,
        value
    }
});
