/* eslint-disable import/no-cycle */
import client from './client';
import modules from './modules';

export default function createSdk(done) {
    const configuredClient = client.create();
    const configuredModules = modules(configuredClient);

    done(configuredModules);
}
