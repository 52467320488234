import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        padding: '1rem 0'
    },
    media: {
        display: 'block',
        maxWidth: '100%',
        position: 'relative',

        '&::before': {
            content: '""',
            display: 'block'
        }
    },
    square: {
        '&::before': {
            paddingTop: '100%'
        }
    },
    wide: {
        '&::before': {
            paddingTop: '75%'
        }
    },
    caption: {
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'center',
        fontWeight: 600,
        color: theme.palette.cityooText
    },
    noBorderRadius: {
        borderRadius: 0
    }
}));
