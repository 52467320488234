import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    rootDefaultInput: {
        height: '1.6rem'
    },
    input: {
        '&:invalid': {
            borderBottom: 'red solid 2px'
        }
    }
}));

export default useStyles;
