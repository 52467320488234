/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import baseInputHelper from '../helper';
import useStyles from './BaseInputMaterial.styles';

const propTypes = {
    styleClasses: PropTypes.object,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    multiline: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

const defaultProps = {
    styleClasses: {},
    multiline: false,
    readOnly: false,
    disabled: false,
    type: 'text',
    onChange: null
};

const BaseInputMaterial = ({ disabled, styleClasses, label, multiline, readOnly, onChange, type, ...rest }) => {
    const classes = useStyles();

    const handleChange = e => {
        if (!onChange) {
            return;
        }

        if (type === 'number') {
            if (baseInputHelper.blockInvalidChar(e)) {
                e.preventDefault();
            } else if (e.target.value) {
                onChange(e);
            }
        } else {
            onChange(e);
        }
    };

    return (
        <TextField
            {...rest}
            onChange={handleChange}
            type={type}
            classes={{
                ...styleClasses
            }}
            disabled={disabled}
            multiline={multiline}
            rows={multiline ? 4 : 1}
            label={label}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: multiline ? classes.labelRoot : ''
                }
            }}
            InputProps={{
                disableUnderline: multiline,
                readOnly,
                classes: {
                    root: multiline ? classes.rootInput : classes.rootDefaultInput,
                    input: multiline ? classes.input : ''
                }
            }}
        />
    );
};

BaseInputMaterial.propTypes = propTypes;
BaseInputMaterial.defaultProps = defaultProps;

export default BaseInputMaterial;
