import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
        margin: theme.spacing(-3),
        position: 'relative',
        height: '100%'
    },
    mapContainer: {
        height: '100%'
    }
}));
