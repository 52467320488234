import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Box } from '@material-ui/core';
import classnames from 'classnames';

import {
    actions as modalManagerActions,
    enumerators as modalManagerEnumerators,
    selectors as modalManagerSelectors
} from '../../../redux/modal-manager';
import { actions as projectActions, selectors as projectSelectors } from '../../../redux/projects/project/details';

import BaseDialog from '../../base-dialog';
import Loading from '../../base-loading';

import classificationEnumerators from './enumerators';
import useStyles from './ProjectClassification.styles';

const ProjectClassification = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    // state
    const [selectedClassification, setSelectedClassification] = useState(null);

    // state props
    const classificationModal = useSelector(modalManagerSelectors.getClassificationProject);
    const projectId = useSelector(modalManagerSelectors.getProjectIdFromClassificationProject);
    const isLoadingClassification = useSelector(projectSelectors.getIsLoadingClassification);

    const handleChangeClassification = useCallback(
        newChange => () => {
            setSelectedClassification(newChange);
        },
        [setSelectedClassification]
    );

    useEffect(() => {
        if (classificationModal.isOpen) {
            setSelectedClassification(null);
        }
    }, [classificationModal.isOpen, setSelectedClassification]);

    const handleCloseModal = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CLASSIFICATION_PROJECT));
    }, [dispatch]);

    const handleSubmitClassification = useCallback(() => {
        if (selectedClassification === classificationEnumerators.LEGAL) {
            dispatch(projectActions.setLegalClassification(projectId));
        } else {
            dispatch(projectActions.setIllegalClassification(projectId));
        }
    }, [dispatch, selectedClassification, projectId]);

    return (
        <BaseDialog
            small
            open={classificationModal.isOpen}
            styleClasses={{ paper: classes.paper }}
            onClose={handleCloseModal}
            title={t('projects.classificationModal.rate')}
        >
            {isLoadingClassification ? (
                <Box className={classes.loadingContainer}>
                    <Loading />
                </Box>
            ) : (
                <>
                    <Typography variant="h4" className={classes.subtitle}>
                        {t('projects.classificationModal.howToClassifyProject')}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <Button
                            onClick={handleChangeClassification(classificationEnumerators.LEGAL)}
                            className={classnames(classes.legalButton, {
                                [classes.withOpacity]: classificationEnumerators.LEGAL === selectedClassification
                            })}
                        >
                            {t('projects.statuses.legal').toUpperCase()}
                        </Button>
                        <Button
                            onClick={handleChangeClassification(classificationEnumerators.ILLEGAL)}
                            className={classnames(classes.illegalButton, {
                                [classes.withOpacity]: classificationEnumerators.ILLEGAL === selectedClassification
                            })}
                        >
                            {t('projects.statuses.illegal').toUpperCase()}
                        </Button>
                    </Box>
                </>
            )}
            <div className={classes.actions}>
                <Button onClick={handleCloseModal} color="secondary" variant="contained">
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleSubmitClassification}
                    disabled={!selectedClassification}
                    variant="outlined"
                    color="primary"
                    className={classes.submitButton}
                >
                    {t('confirm')}
                </Button>
            </div>
        </BaseDialog>
    );
};

export default ProjectClassification;
