import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: '1.25rem',
        paddingRight: '1rem'
    },
    rootSmallSpacing: {
        marginBottom: '0.25rem'
    },
    label: {
        color: theme.palette.cityooText
    }
}));

export default useStyles;
