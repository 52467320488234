import { combineReducers } from 'redux';

import territoriesBoundariesReducer from './territories-boundaries';
import projectsCentroidReducer from './projects-centroid';

export const projectsReducer = combineReducers({
    territoriesBoundaries: territoriesBoundariesReducer,
    projectsCentroid: projectsCentroidReducer
});

export default projectsReducer;
