import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';
import * as modalManagerActionTypes from '../../modal-manager/actionTypes';

const initialStateUserDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    jobRole: undefined
};

const initialState = {
    isLoading: false,
    userDetails: initialStateUserDetails
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_USER_START:
            return true;
        case actionTypes.SUBMIT_USER_SUCCESS:
        case actionTypes.SUBMIT_USER_FAIL:
            return false;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const userDetails = (state = initialState.userDetails, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case modalManagerActionTypes.OPEN_CREATE_PROJECT: {
            return initialState.userDetails;
        }
        case actionTypes.RESET:
            return initialState.userDetails;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getUserDetails = state => get(state, 'userDetails', initialState.userDetails);

const reducer = combineReducers({
    isLoading,
    userDetails
});

export default reducer;
