/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../../redux/modal-manager';

import { selectors as licenseSelectors } from '../../../redux/projects/project/licensing';
import { selectors as priorCommunicationSelectors } from '../../../redux/projects/project/prior-communication';

import LicenseInfoModal from '../../../components/modals/license-info';
import License from './license';
import PriorCommunication from './prior-communication';
import PriorCommunicationInfoModal from '../../../components/modals/prior-communication-info';

const ProjectLicense = () => {
    const dispatch = useDispatch();

    const licenseDetail = useSelector(licenseSelectors.getLicensing);
    const priorCommunicationDetail = useSelector(priorCommunicationSelectors.getPriorCommunication);

    const onEditLicense = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.LICENSE_INFO, licenseDetail));
    }, [dispatch, licenseDetail]);

    const onEditPriorCommunication = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.PRIOR_COMMUNICATION, priorCommunicationDetail));
    }, [dispatch, priorCommunicationDetail]);

    return (
        <>
            <License onEditLicense={onEditLicense} />
            <PriorCommunication onEditPriorCommunication={onEditPriorCommunication} />

            <LicenseInfoModal />
            <PriorCommunicationInfoModal />
        </>
    );
};

export default ProjectLicense;
