import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    licensing: {}
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_LICENSING_SUCCESS:
        case actionTypes.GET_LICENSING_FAIL:
            return false;
        case actionTypes.GET_LICENSING_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const licensing = (state = initialState.licensing, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_LICENSING_SUCCESS:
            return action.payload;
        case actionTypes.GET_LICENSING_FAIL:
        case actionTypes.RESET:
            return initialState.licensing;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getLicensing = state => get(state, 'licensing', initialState.licensing);

const reducer = combineReducers({
    isLoading,
    licensing
});

export default reducer;
