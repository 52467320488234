import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { LIST_ITEM_BODY } from '../enumerators';

const propTypes = {
    children: PropTypes.node
};

const defaultProps = {
    children: <></>
};

const CollapsibleListItemBody = ({children}) => {
    return (
        <Box>
            { children }
        </Box>
    );
};

CollapsibleListItemBody.displayName = LIST_ITEM_BODY;
CollapsibleListItemBody.propTypes = propTypes;
CollapsibleListItemBody.defaultProps = defaultProps;

export default CollapsibleListItemBody;
