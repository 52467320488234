import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tab } from '@material-ui/core';

import useStyles from './LinkTab.styles';

const propTypes = {
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
};

const LinkTab = ({ label, href, ...rest }) => {
    const classes = useStyles();
    const history = useHistory();

    const preventDefaultClick = useCallback((ev) => {
        ev.preventDefault();
        history.push(href);
    }, [history, href]);

    return (
        <Tab
            classes={classes}
            component="a"
            label={label}
            href={href}
            onClick={preventDefaultClick}
            {...rest}
        />
    );
};

LinkTab.propTypes = propTypes;

export default LinkTab;
