import { isEmpty } from 'lodash';
import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../../modal-manager/actionTypes';
import { actions as albumsActions } from '../../projects/project/albums';

import { actions as notificationsActions } from '../../notifications';

export const submitAlbum = (projectId, albumToSubmit) => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.SUBMIT_ALBUM_START });

    try {
        const { name, comments, files } = albumToSubmit;

        const S3Files = await Promise.all(
            files.map(async file => {
                const response = await apiSdk.files.upload();

                const {
                    data: { fileUrl, filename }
                } = response;

                await apiSdk.files.sendFileToS3(response, file);

                return { fileUrl, filename };
            })
        );

        await apiSdk.projectAlbums.insert(projectId, {
            name,
            comments,
            files: S3Files
        });

        dispatch({ type: modalActionTypes.CLOSE_CREATE_ALBUM });
        dispatch(albumsActions.getAlbums(projectId));
        dispatch(notificationsActions.openSuccessNotification('success.createAlbum'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.createAlbum'));
        dispatch({ type: actionTypes.SUBMIT_ALBUM_FAIL });
    }
};

export const updateAlbum = (projectId, albumToSubmit) => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.SUBMIT_ALBUM_START });

    try {
        const { albumId, name, comments, files, filesToRemove } = albumToSubmit;

        const filesToUpload = files.filter(({ id }) => !id);

        const S3Files = await Promise.all(
            filesToUpload.map(async file => {
                const response = await apiSdk.files.upload();

                const {
                    data: { fileUrl, filename }
                } = response;

                await apiSdk.files.sendFileToS3(response, file);

                return { fileUrl, filename };
            })
        );

        await apiSdk.projectAlbums.update(projectId, albumId, {
            ...(name && { name }),
            ...(comments && { comments }),
            ...(!isEmpty(S3Files) && { files: S3Files }), // we don't want to send files that were already uploaded in the past
            ...(!isEmpty(filesToRemove) && { filesToRemove })
        });

        dispatch({ type: modalActionTypes.CLOSE_CREATE_ALBUM });
        dispatch(albumsActions.getAlbums(projectId));
        dispatch(notificationsActions.openSuccessNotification('success.updateAlbum'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.updateAlbum'));
        dispatch({ type: actionTypes.SUBMIT_ALBUM_FAIL });
    }
};
