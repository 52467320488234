const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;

function isValidEmail(email) {
    const pattern = new RegExp(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return pattern.test(email);
}

function isValidPassword(password) {
    if (password.length < 8) {
        return false;
    }
    // at least 1 capital letter or 1 symbol
    const pattern = new RegExp(/[^a-z0-9]+/g);
    return pattern.test(password);
}

export default {
    isValidEmail,
    isValidPassword,
    floatRegex
};
