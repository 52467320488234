import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

import * as fromReducer from './reducer';

const getProjectFromReducer = get('projects.project.details');

export const getIsLoading = state => fromReducer.getIsLoading(getProjectFromReducer(state));

export const getIsLoadingClassification = state => fromReducer.getIsLoadingClassification(getProjectFromReducer(state));

export const getIsLoadingDelete = state => fromReducer.getIsLoadingDelete(getProjectFromReducer(state));

export const getProject = state => fromReducer.getProject(getProjectFromReducer(state));

export const getProjectId = createSelector([getProject], project => project?.id);

export const getProjectChangeCluster = createSelector([getProject], project => project?.changeCluster ?? {});

export const getProjectCoordinates = createSelector([getProject], project => {
    const [coordinates] = project?.changeCluster?.geometry?.coordinates;

    if (!coordinates) {
        return [];
    }

    return coordinates.reduce((acc, point) => {
        acc.push({
            latitude: point[1],
            longitude: point[0]
        });

        return acc;
    }, []);
});
