import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import validators from '../../../utils/validators';

import useStyles from './BaseInputLatitude.styles';

const propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

const defaultProps = {
    label: 'latitude'
};

const BaseInputLatitude = ({ label, onChange, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [error, setError] = useState('');

    const handleChange = e => {
        if (!validators.floatRegex.test(e.target.value) || parseFloat(e.target.value) > 90 || parseFloat(e.target.value) < -90) {
            setError('invalidLatitude');
        } else {
            setError('');
        }

        onChange(e);
    };

    return (
        <TextField
            {...rest}
            required
            key="latitude"
            label={t(label)}
            InputLabelProps={{
                shrink: true
            }}
            placeholder="40.2033145"
            helperText={t(error)}
            error={error !== ''}
            onChange={handleChange}
            InputProps={{
                classes: {
                    root: classes.rootDefaultInput
                }
            }}
        />
    );
};

BaseInputLatitude.propTypes = propTypes;
BaseInputLatitude.defaultProps = defaultProps;

export default BaseInputLatitude;
