import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        background: theme.palette.background.white,
        boxShadow: theme.customShadows.medium,
        padding: '1.5rem 2rem 0.5rem 3.125rem',
        marginBottom: '0.5rem'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    actionBtn: {
        padding: 0
    },
    albumTitle: {
        color: theme.palette.text.link,
        fontWeight: 'bold'
    },
    albumDate: {
        color: theme.palette.black
    }
}));
