const reducerName = '@cityoo-tenant/modalManager';

export const RESET = `${reducerName}/RESET`;

export const OPEN_CREATE_ALBUM = `${reducerName}/OPEN_CREATE_ALBUM`;
export const CLOSE_CREATE_ALBUM = `${reducerName}/CLOSE_CREATE_ALBUM`;

export const OPEN_IMAGE_PREVIEW = `${reducerName}/OPEN_IMAGE_PREVIEW`;
export const CLOSE_IMAGE_PREVIEW = `${reducerName}/CLOSE_IMAGE_PREVIEW`;

export const OPEN_CREATE_PROJECT = `${reducerName}/OPEN_CREATE_PROJECT`;
export const CLOSE_CREATE_PROJECT = `${reducerName}/CLOSE_CREATE_PROJECT`;

export const OPEN_CLASSIFICATION_PROJECT = `${reducerName}/OPEN_CLASSIFICATION_PROJECT`;
export const CLOSE_CLASSIFICATION_PROJECT = `${reducerName}/CLOSE_CLASSIFICATION_PROJECT`;

export const OPEN_UNFULFILLED_MODAL = `${reducerName}/OPEN_UNFULFILLED_MODAL`;
export const CLOSE_UNFULFILLED_MODAL = `${reducerName}/CLOSE_UNFULFILLED_MODAL`;

export const OPEN_CREATE_USER = `${reducerName}/OPEN_CREATE_USER`;
export const CLOSE_CREATE_USER = `${reducerName}/CLOSE_CREATE_USER`;

export const OPEN_LICENSE_INFO = `${reducerName}/OPEN_LICENSE_INFO`;
export const CLOSE_LICENSE_INFO = `${reducerName}/CLOSE_LICENSE_INFO`;

export const OPEN_CONFIRM_MODAL = `${reducerName}/OPEN_CONFIRM_MODAL`;
export const CLOSE_CONFIRM_MODAL = `${reducerName}/CLOSE_CONFIRM_MODAL`;

export const OPEN_PRIOR_COMMUNICATION_INFO = `${reducerName}/OPEN_PRIOR_COMMUNICATION_INFO`;
export const CLOSE_PRIOR_COMMUNICATION_INFO = `${reducerName}/CLOSE_PRIOR_COMMUNICATION_INFO`;
