import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Box } from '@material-ui/core';
import useStyles from './ProjectTimeline.styles';

import {
    selectors as modalManagerSelectors,
    actions as modalManagerActions,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';

import { selectors as projectSelectors } from '../../../redux/projects/project/details';
import { selectors as timelineSelectors, actions } from '../../../redux/projects/project/timeline';

import Loading from '../../../components/base-loading';
import ImageGrid from '../../../components/image-grid';
import ImageLightbox from '../../../components/image-lightbox/ImageLightbox';

const propTypes = {
    children: PropTypes.node,
    isSmall: PropTypes.bool,
    limit: PropTypes.number,
    isDashboard: PropTypes.bool
};

const defaultProps = {
    children: <></>,
    isSmall: false,
    limit: null,
    isDashboard: false
};

const ProjectTimeline = ({ children, isSmall, limit, isDashboard }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const projectId = useSelector(projectSelectors.getProjectId);
    const timelinePhotos = useSelector(timelineSelectors.getTimelinePhotos(limit));
    const isLoading = useSelector(timelineSelectors.getIsLoading);
    const imagePreviewModal = useSelector(modalManagerSelectors.getImagePreview);

    useEffect(() => {
        dispatch(actions.getTimelinePhotos(projectId));
    }, [dispatch, projectId]);

    const openImagePreview = useCallback(
        image => {
            dispatch(modalManagerActions.openModal(modalManagerEnumerators.IMAGE_PREVIEW, image));
        },
        [dispatch]
    );

    const onCloseImagePreview = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.IMAGE_PREVIEW));
    }, [dispatch]);

    if (isLoading) {
        return (
            <Box flex={1}>
                <Loading />
            </Box>
        );
    }

    const gridItemSizes = isSmall ? { xs: 6, sm: 4, md: 3, lg: 3 } : undefined;

    return timelinePhotos?.length > 0 ? (
        <>
            <Box className={classnames(classes.container, { dashboard: isDashboard })} flex={1}>
                {children}
                <ImageGrid
                    hasCaptions
                    hasBorderRadius
                    imageRatio="square"
                    images={timelinePhotos}
                    onGridItemClick={openImagePreview}
                    gridItemSizes={gridItemSizes}
                />
            </Box>
            {imagePreviewModal.data && (
                <ImageLightbox
                    open={imagePreviewModal.isOpen}
                    onClose={onCloseImagePreview}
                    src={imagePreviewModal.data.fileUrl}
                    alt={imagePreviewModal.data.filename}
                />
            )}
        </>
    ) : null;
};

ProjectTimeline.propTypes = propTypes;
ProjectTimeline.defaultProps = defaultProps;

export default ProjectTimeline;
