import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import useStyles from './ProjectSidepanelHeader.styles';

const propTypes = {
    title: PropTypes.string.isRequired
};

const ProjectSidepanelHeader = ({ title }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container} display="flex" alignItems="center">
            <Typography className={classes.title}>{title}</Typography>
        </Box>
    );
};

ProjectSidepanelHeader.propTypes = propTypes;

export default ProjectSidepanelHeader;
