import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 0,
        paddingTop: theme.spacing(4),
        minHeight: '0.75rem'
    },
    submitButton: {
        marginLeft: theme.spacing(1)
    },
    paper: {
        width: '45rem',
        minHeight: '18.75rem'
    },
    subtitle: {
        color: theme.palette.cityooText
    },
    legalButton: {
        height: '1.8rem',
        width: '10rem',
        backgroundColor: theme.palette.statuses.legal,
        marginRight: theme.spacing(2),
        color: theme.palette.background.white,
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.statuses.legal
        }
    },
    illegalButton: {
        height: '1.8rem',
        width: '10rem',
        backgroundColor: theme.palette.statuses.illegal,
        marginLeft: theme.spacing(2),
        color: theme.palette.background.white,
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.statuses.illegal
        }
    },
    withOpacity: {
        opacity: '0.2'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '5rem'
    }
}));
