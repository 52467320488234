import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';

import { selectors as activitySelectors } from '../../../redux/projects/project/activity';

import List, { ListItem, ListItemHeader } from '../../../components/list';
import Loading from '../../../components/base-loading';
import PaginationControls from '../../../components/pagination-controls';
import ProjectActivityDetailsLabel from '../../../components/project-activity-details-label';
import { ProjectStatusBadge } from '../../../components/project-status';

import { dateTimeSeparatorByDots } from '../../../utils/date-time';

import useStyles from './ProjectActivity.styles';

const propTypes = {
    isDashboard: PropTypes.bool,
    isSmall: PropTypes.bool
};

const defaultProps = {
    isDashboard: false,
    isSmall: false
};

const ProjectActivity = ({ isDashboard, isSmall }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const headers = [
        { key: 'date', title: t('projects.activity.date') },
        { key: 'user', title: t('projects.activity.user'), isHidden: (isTablet || isSmall) },
        { key: 'details', title: t('projects.activity.details') },
        { key: 'status', title: t('projects.activity.status'), width: '8rem' }
    ];

    const projectActivity = useSelector(activitySelectors.getActivity);
    const isLoading = useSelector(activitySelectors.getIsLoading);

    const onPageChange = useCallback(
        (_ev, page) => {
            if (page !== projectActivity.page) {
                // TODO: dispatch action
            }
        },
        [projectActivity.page]
    );

    // useEffect(() => {
    //     // TODO: dispatch get logs action
    // }, [dispatch, projectActivity.page, projectActivity.limit]);

    return (
        <>
            <Box className={classnames(classes.container, { dashboard: isDashboard })}>
                <List headers={headers} hideListHeader={isDashboard}>
                    {isLoading ? (
                        <div className={classes.loadingContainer}>
                            <Loading />
                        </div>
                    ) : (
                        projectActivity.rows &&
                        projectActivity.rows.map(item => (
                            <ListItem key={`list-logs-${item.id}`}>
                                <ListItemHeader headers={headers}>
                                    <Typography key="date" variant="body1" className={classes.dateCol}>
                                        {dateTimeSeparatorByDots(item.date)}
                                    </Typography>
                                    <Typography key="user" variant="body1">
                                        {item.user}
                                    </Typography>
                                    <ProjectActivityDetailsLabel
                                        key="details"
                                        variant="body1"
                                        label={item.details?.label}
                                        payload={item.details?.payload}
                                    />
                                    <Box key="status" display="flex">
                                        {item.status != null && <ProjectStatusBadge status={item.status} />}
                                    </Box>
                                </ListItemHeader>
                            </ListItem>
                        ))
                    )}
                </List>

                {!isDashboard && <PaginationControls pages={projectActivity.pages} page={projectActivity.page} onChange={onPageChange} />}
            </Box>
        </>
    );
};

ProjectActivity.propTypes = propTypes;
ProjectActivity.defaultProps = defaultProps;

export default ProjectActivity;
