import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
        height: '6rem',
        borderRadius: '0.25rem',
        boxShadow: theme.customShadows.medium,
        padding: '1rem 3rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem'
        }
    },
    new: {
        background: theme.palette.statuses.new
    },
    pending: {
        background: theme.palette.statuses.pending
    },
    legal: {
        background: theme.palette.statuses.legal
    },
    illegal: {
        background: theme.palette.statuses.illegal
    },
    recurrent: {
        background: theme.palette.statuses.recurrent
    },
    archived: {
        background: theme.palette.statuses.archived
    },
    titleContainer: {
        width: 'fit-content',
        textAlign: 'center'
    },
    title: {
        fontSize: '1.25rem',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.white,
        lineHeight: 'normal'
    },
    subtitle: {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.white
    },
    button: {
        width: '100%',
        maxWidth: '17.5rem',
        height: '3.75rem',
        fontSize: '1rem',
        color: theme.palette.text.tabsSubtitles,
        borderRadius: '0.25rem',
        background: 'rgba(255, 255, 255, 0.8)',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.8)'
        }
    }
}));
