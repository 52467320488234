const reducerName = '@cityoo-tenant/projects/project/details';

export const RESET = `${reducerName}/RESET`;

export const GET_PROJECT_START = `${reducerName}/GET_PROJECT_START`;
export const GET_PROJECT_SUCCESS = `${reducerName}/GET_PROJECT_SUCCESS`;
export const GET_PROJECT_FAIL = `${reducerName}/GET_PROJECT_FAIL`;

export const SET_LEGAL_CLASSIFICATION_START = `${reducerName}/SET_LEGAL_CLASSIFICATION_START`;
export const SET_LEGAL_CLASSIFICATION_SUCCESS = `${reducerName}/SET_LEGAL_CLASSIFICATION_SUCCESS`;
export const SET_LEGAL_CLASSIFICATION_FAIL = `${reducerName}/SET_LEGAL_CLASSIFICATION_FAIL`;

export const SET_ILLEGAL_CLASSIFICATION_START = `${reducerName}/SET_ILLEGAL_CLASSIFICATION_START`;
export const SET_ILLEGAL_CLASSIFICATION_SUCCESS = `${reducerName}/SET_ILLEGAL_CLASSIFICATION_SUCCESS`;
export const SET_ILLEGAL_CLASSIFICATION_FAIL = `${reducerName}/SET_ILLEGAL_CLASSIFICATION_FAIL`;

export const DELETE_PROJECT_START = `${reducerName}/DELETE_PROJECT_START`;
export const DELETE_PROJECT_SUCCESS = `${reducerName}/DELETE_PROJECT_SUCCESS`;
export const DELETE_PROJECT_FAIL = `${reducerName}/DELETE_PROJECT_FAIL`;
