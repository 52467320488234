import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.white,
        padding: '2rem',
        borderRadius: '0.625rem',
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.1)',
        '&.small': {
            minHeight: '3.25rem',
            padding: '0.25rem 2.5rem',
            borderRadius: 0
        }
    },
    title: {
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
        '&.small': {
            fontSize: '1rem',
            fontWeight: theme.typography.fontWeightBold
        }
    },
    actionContainer: {
        marginLeft: '1rem'
    }
}));

export default useStyles;
