import React from 'react';
import PropTypes from 'prop-types';

import CollapsibleListHeader from './collapsible-list-header';

const propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            width: PropTypes.string
        })
    ).isRequired,
    children: PropTypes.node.isRequired
};

const CollapsibleList = ({headers, children}) => {
    return (
        <div>
            <CollapsibleListHeader headers={headers} />
            {children}
        </div>
    );
};

CollapsibleList.propTypes = propTypes;

export default CollapsibleList;
