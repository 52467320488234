import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.white,
        minHeight: '2rem',
        margin: '0.5rem 0',
        padding: '0 2.5rem',
        borderRadius: '0.3125rem',
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.1)'
    },
    headerTitle: {
        color: theme.palette.cityooText,
        fontWeight: theme.typography.fontWeightMedium,
        flex: 1,
        '&.fixed': {
            flex: '1 1 100%'
        }
    }
}));

export default useStyles;
