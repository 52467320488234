import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        background: theme.palette.background.white,
        boxShadow: theme.customShadows.medium,
        padding: '1rem 2rem',
        marginBottom: '0.25rem',
        textAlign: 'center'
    },
    dropdown: {
        '&.Mui-focused > div': {
            backgroundColor: `${theme.palette.background.white}!important`
        },

        '& > .MuiSelect-selectMenu': {
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            fontWeight: 600
        },

        '& .MuiSelect-icon': {
            color: theme.palette.text.primary,
            right: '-10px',
            height: 'auto',
            lineHeight: '1.1876em',
            top: 0
        },

        '&:hover::before': {
            borderBottom: '0!important'
        },

        '&::after': {
            borderBottom: 0
        },
        '&::before': {
            borderBottom: 0
        }
    }
}));
