import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import placeholderUnfulfilled from '../../assets/placeholder-unfulfilled.svg';

import useStyles from './Unfulfilled.styles';

const propTypes = {
    small: PropTypes.bool,
    message: PropTypes.string
};

const defaultProps = {
    small: false,
    message: ''
};

const Unfulfilled = ({ small, message }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.container}>
            <img
                src={placeholderUnfulfilled}
                className={classes.spacing}
                style={{ width: small ? '30rem' : '60rem', maxWidth: '100%' }}
                alt={t('unfulfilled.soon')}
            />
            <Typography variant="h2" className={classnames(classes.spacing, classes.title)}>
                {t('unfulfilled.soon').toUpperCase()}
            </Typography>
            <Typography className={classes.subtitle} variant="h3">
                {message !== '' ? `${message} ${t('unfulfilled.featureInDevelopmentCustom')}` : t('unfulfilled.featureInDevelopment')}
            </Typography>
        </Box>
    );
};

Unfulfilled.propTypes = propTypes;
Unfulfilled.defaultProps = defaultProps;

export default Unfulfilled;
