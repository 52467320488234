import config from '../../../_config';

const { tenantSlugBaseUrl } = config;

const baseUrl = '/spatial';
const baseUrlWithSlug = `${tenantSlugBaseUrl}${baseUrl}`;

const list = client => {
    return client.get(`${baseUrlWithSlug}/territories-boundaries`);
};

const getProjectsCentroid = client => {
    return client.get(`${baseUrlWithSlug}/projects-centroid`);
};

export default client => ({
    list: () => list(client),
    getProjectsCentroid: () => getProjectsCentroid(client)
});
