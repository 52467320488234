import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { actions as countriesActions, selectors as countriesSelectors } from '../../../../redux/territories/countries';
import { actions as districtsActions, selectors as districtsSelectors } from '../../../../redux/territories/districts';
import { actions as countiesActions, selectors as countiesSelectors } from '../../../../redux/territories/counties';
import { actions as parishesActions, selectors as parishesSelectors } from '../../../../redux/territories/parishes';
import { actions as selectedOptionsActions, selectors as selectedOptionsSelectors } from '../../../../redux/territories/selected-options';
import { enumerators as modalManagerEnumerators, selectors as modalManagerSelectors } from '../../../../redux/modal-manager';
import { actions as formProjectActions } from '../../../../redux/form/project';

import BaseAutoComplete from '../../../base-auto-complete/BaseAutoComplete';

const Territories = () => {
    const dispatch = useDispatch();

    // state props
    const countries = useSelector(countriesSelectors.getCountries);
    const districts = useSelector(districtsSelectors.getDistricts);
    const counties = useSelector(countiesSelectors.getCounties);
    const parishes = useSelector(parishesSelectors.getParishes);
    const selectedCountry = useSelector(selectedOptionsSelectors.getSelectedCountry);
    const selectedDistrict = useSelector(selectedOptionsSelectors.getSelectedDistrict);
    const selectedCounty = useSelector(selectedOptionsSelectors.getSelectedCounty);
    const selectedParish = useSelector(selectedOptionsSelectors.getSelectedParish);
    const createProjectModal = useSelector(modalManagerSelectors.getCreateProject);

    useEffect(() => {
        dispatch(countriesActions.getCountries());
    }, [dispatch]);

    const handleChangeAutoComplete = useCallback(
        type => (_e, value) => {
            if (!value || !value.id) {
                return null;
            }

            switch (type) {
                case 'country':
                    dispatch(districtsActions.reset());
                    dispatch(countiesActions.reset());
                    dispatch(parishesActions.reset());
                    dispatch(selectedOptionsActions.resetSelectedDistrict());
                    dispatch(selectedOptionsActions.resetSelectedCounty());
                    dispatch(selectedOptionsActions.resetSelectedParish());
                    dispatch(selectedOptionsActions.setSelectedCountry(value));
                    dispatch(districtsActions.getDistricts(value.id));
                    break;

                case 'district':
                    dispatch(countiesActions.reset());
                    dispatch(parishesActions.reset());
                    dispatch(selectedOptionsActions.resetSelectedCounty());
                    dispatch(selectedOptionsActions.resetSelectedParish());
                    dispatch(selectedOptionsActions.setSelectedDistrict(value));
                    dispatch(countiesActions.getCounties(value.id));
                    break;

                case 'county':
                    dispatch(parishesActions.reset());
                    dispatch(selectedOptionsActions.resetSelectedParish());
                    dispatch(selectedOptionsActions.setSelectedCounty(value));
                    dispatch(parishesActions.getParishes(value.id));
                    break;

                case 'parish':
                    dispatch(selectedOptionsActions.setSelectedParishes(value));
                    dispatch(formProjectActions.submitParishId(value.id));
                    break;

                default:
                    break;
            }

            return null;
        },
        [dispatch]
    );

    return (
        <>
            <BaseAutoComplete
                required
                options={countries}
                selectedOption={selectedCountry}
                optionLabel="name"
                id="country"
                onChange={handleChangeAutoComplete}
                disabled={createProjectModal.data?.type === modalManagerEnumerators.TYPE_EDIT}
            />
            <BaseAutoComplete
                required
                disabled={!selectedCountry.name || isEmpty(districts) || createProjectModal.data?.type === modalManagerEnumerators.TYPE_EDIT}
                options={districts}
                selectedOption={selectedDistrict}
                optionLabel="name"
                id="district"
                onChange={handleChangeAutoComplete}
            />
            <BaseAutoComplete
                required
                disabled={!selectedDistrict.name || isEmpty(counties) || createProjectModal.data?.type === modalManagerEnumerators.TYPE_EDIT}
                options={counties}
                selectedOption={selectedCounty}
                optionLabel="name"
                id="county"
                onChange={handleChangeAutoComplete}
            />
            <BaseAutoComplete
                required
                disabled={!selectedCounty.name || isEmpty(parishes) || createProjectModal.data?.type === modalManagerEnumerators.TYPE_EDIT}
                options={parishes}
                selectedOption={selectedParish}
                optionLabel="name"
                id="parish"
                onChange={handleChangeAutoComplete}
            />
        </>
    );
};

export default Territories;
