import * as Yup from 'yup';

const formOptions = {
    county: { required: false, type: 'text' },
    intendedPurpose: { required: false, type: 'text' },
    date: { required: false, type: 'date' },
    processNumber: { required: false, type: 'text' }
};

const getValidationSchema = () => {
    return Yup.object().shape({
        county: Yup.string().optional(),
        intendedPurpose: Yup.string().optional(),
        date: Yup.date().optional().nullable(),
        processNumber: Yup.string().optional()
    });
};

const dateItems = ['date'];

const isDateField = fieldKey => dateItems.includes(fieldKey);

export default {
    isDateField,
    formOptions,
    getValidationSchema
};
