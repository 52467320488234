import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { STATUSES_MAP } from '../../../utils/project-status';

import useStyles from './ProjectStatusBadge.styles';

const propTypes = {
    status: PropTypes.number.isRequired
};

const ProjectStatusBadge = ({status}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getLabel = () => {
        return `projects.statuses.${STATUSES_MAP[status]}`;
    };

    return (
        <Box className={classnames(classes.root, classes[STATUSES_MAP[status]])}>
            <span>{t(getLabel())}</span>
        </Box>
    );
};

ProjectStatusBadge.propTypes = propTypes;

export default ProjectStatusBadge;
