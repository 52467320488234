import config from '../../../_config';

const { tenantSlugBaseUrl } = config;

const baseUrl = '/projects';
const baseUrlWithSlug = `${tenantSlugBaseUrl}${baseUrl}`;

const licensingUrl = projectId => `${baseUrlWithSlug}/${projectId}/licensing`;

const get = (client, projectId) => {
    const url = `${licensingUrl(projectId)}`;

    return client.get(url, {});
};

const update = (client, projectId, data) => {
    const url = `${licensingUrl(projectId)}`;

    return client.put(url, data);
};

export default client => ({
    get: projectId => get(client, projectId),
    update: (projectId, data) => update(client, projectId, data)
});
