import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { actions as accountActions, selectors as accountSelectors } from '../../redux/account';

import logo from '../../assets/logo.svg';
import ROUTES from '../../utils/routes';

// redux
import { BaseInputCityoo } from '../../components/base-input';
import { BaseButtonCityoo } from '../../components/base-button';
import BaseLoading from '../../components/base-loading';
import Fallback from '../../components/fallback';
import LandingBackground from '../../components/landing-background';

import useStyles from './Login.styles';
import loginHelper from './helper';

const propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

const Login = ({ history }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // state
    const [formError, setFormError] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    // state props
    const isLoadingLogin = useSelector(accountSelectors.getIsLoading);
    const isLoadingGeMe = useSelector(accountSelectors.getIsLoadingGeMe);
    const loginError = useSelector(accountSelectors.getError);

    useEffect(() => {
        setFormError('');
    }, [email, password]);

    const handleLogin = useCallback(() => {
        dispatch(accountActions.login({ email, password }));
    }, [dispatch, email, password]);

    const handlePasswordVisibility = useCallback(() => {
        setIsPasswordVisible(!isPasswordVisible);
    }, [setIsPasswordVisible, isPasswordVisible]);

    const handleChangeEmail = useCallback(
        ev => {
            setEmail(ev.target.value);
        },
        [setEmail]
    );

    const handleChangePassword = useCallback(
        ev => {
            setPassword(ev.target.value);
        },
        [setPassword]
    );

    const handleValidateFields = useCallback(() => {
        loginHelper
            .getValidationSchema()
            .validate({ email, password })
            .then(() => {
                handleLogin();
                setFormError('');
            })
            .catch(err => {
                setFormError(err.errors);
            });
    }, [handleLogin, setFormError, email, password]);

    const handleGoToResetPassword = useCallback(() => {
        history.push(ROUTES.RECOVER_PASSWORD);
    }, [history]);

    if (isLoadingGeMe) {
        return <Fallback />;
    }

    return (
        <LandingBackground>
            <Box container justify="center" className={classes.container}>
                <Box item className={classes.loginBox} spacing={5}>
                    <Box item className={classes.logoContainer}>
                        <img src={logo} alt={logo} className={classes.logo} />
                    </Box>
                    {isLoadingLogin ? (
                        <Box className={classes.loadingLoginContainer}>
                            <BaseLoading color="secondary" />
                        </Box>
                    ) : (
                        <>
                            <BaseInputCityoo placeholder={t('email')} value={email} onChange={handleChangeEmail} />
                            <BaseInputCityoo
                                placeholder={t('password')}
                                value={password}
                                onChange={handleChangePassword}
                                isPasswordVisible={isPasswordVisible}
                                onChangePasswordVisibility={handlePasswordVisibility}
                            />
                        </>
                    )}
                    {(formError || loginError) && (
                        <Typography variant="subtitle1" className={classes.error}>
                            {formError !== '' ? t(formError) : t(loginError)}
                        </Typography>
                    )}
                    <div className={classes.loginButtonContainer}>
                        <BaseButtonCityoo disabled={isLoadingLogin} title={t('login')} onClick={handleValidateFields} />
                    </div>
                    <Typography onClick={handleGoToResetPassword} variant="subtitle2" className={classes.recoverPassword}>
                        {t('recover_my_password')}
                    </Typography>
                </Box>
            </Box>
        </LandingBackground>
    );
};

Login.propTypes = propTypes;

export default withRouter(Login);
