import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        background: theme.palette.background.white,
        padding: theme.spacing(2),
        fontSize: '1.5rem',
        borderRadius: '0.3125rem',
        boxShadow: theme.customShadows.light
    },
    title: {
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        fontSize: '1.25rem'
    }
}));

export default useStyles;
