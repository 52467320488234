/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, IconButton, List } from '@material-ui/core';
import {
    LocationCity as LocationCityIcon,
    ArrowBack as ArrowBackIcon,
    Map as MapIcon,
    Dashboard as DashboardIcon,
    SupervisorAccount as UsersIcon,
    ExitToApp as ExitToAppIcon
} from '@material-ui/icons';

import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import { useTranslation } from 'react-i18next';
import useStyles from './Sidebar.styles';

// utils
import ROUTES from '../../utils/routes';

// components
import SidebarLink from './sidebar-link';

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext';
import { actions as accountActions } from '../../redux/account';

const Sidebar = ({ location }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    // global
    const layoutDispatch = useLayoutDispatch();
    const { isFloatingSidebar, isSidebarOpened } = useLayoutState();
    const { t } = useTranslation();

    const handleLogout = useCallback(() => {
        dispatch(accountActions.logout());
    }, [dispatch]);

    const structure = [
        {
            label: t('sidebar.map'),
            link: ROUTES.MAP,
            icon: <MapIcon />
        },
        {
            label: t('sidebar.dashboard'),
            link: ROUTES.DASHBOARD,
            icon: <DashboardIcon />
        },
        {
            label: t('sidebar.projects'),
            link: ROUTES.PROJECTS,
            icon: <LocationCityIcon />
        },
        {
            label: t('sidebar.users'),
            link: ROUTES.USERS,
            icon: <UsersIcon />
        },
        {
            label: t('sidebar.logout'),
            callback: handleLogout,
            icon: <ExitToAppIcon />,
            isHidden: !isFloatingSidebar
        }
    ];

    return (
        <Drawer
            variant={isFloatingSidebar ? 'temporary' : 'permanent'}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened
                })
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse)
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(
                    link => !link.isHidden && <SidebarLink key={link.label} location={location} isSidebarOpened={isSidebarOpened} {...link} />
                )}
            </List>
        </Drawer>
    );
};

export default withRouter(Sidebar);
