import * as Yup from 'yup';

const formOptions = {
    reference: {
        required: true
    },
    projectResponsible: {
        required: true
    },
    address: {
        required: true
    },
    postalCode: {
        required: true
    }
};

const getValidationSchema = () => {
    return Yup.object().shape({
        reference: Yup.string().required('mandatory_field'),
        projectResponsible: Yup.string().required('mandatory_field'),
        address: Yup.string().required('mandatory_field'),
        postalCode: Yup.string().required('mandatory_field')
    });
};

export default {
    formOptions,
    getValidationSchema
};
