import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    photos: []
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_TIMELINE_SUCCESS:
        case actionTypes.GET_TIMELINE_FAIL:
            return false;
        case actionTypes.GET_TIMELINE_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const photos = (state = initialState.photos, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_TIMELINE_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.photos;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getTimelinePhotos = state => get(state, 'photos', initialState.photos);

const reducer = combineReducers({
    isLoading,
    photos
});

export default reducer;
