import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';

import {
    actions as modalManagerActions,
    selectors as modalManagerSelectors,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';
import { actions as formUserActions, selectors as formUserSelectos } from '../../../redux/form/user';

import BaseDialog from '../../base-dialog';
import Loading from '../../base-loading';
import BaseInput from '../../base-input';

import useStyles from './CreateUser.styles';
import createUserHelper from './helper';

const CreateUser = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    // state props
    const createUserModal = useSelector(modalManagerSelectors.getCreateUser);
    const createUserModalType = useSelector(modalManagerSelectors.getCreateUserType);
    const userDetails = useSelector(formUserSelectos.getUserDetails);
    const isUserLoading = useSelector(formUserSelectos.getIsLoading);

    const handleCloseDialog = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CREATE_USER));
    }, [dispatch]);

    const handleSubmitProjectForm = useCallback(
        values => {
            if (createUserModalType === modalManagerEnumerators.TYPE_EDIT) {
                console.log('edit');
            } else {
                dispatch(
                    formUserActions.submitUser({
                        ...values
                    })
                );
            }
        },
        [dispatch, createUserModalType]
    );

    return (
        <BaseDialog
            open={createUserModal.isOpen}
            onClose={handleCloseDialog}
            title={createUserModalType === modalManagerEnumerators.TYPE_EDIT ? t('users.createModal.edit') : t('users.createModal.create')}
        >
            <Formik initialValues={userDetails} onSubmit={handleSubmitProjectForm} validationSchema={createUserHelper.getValidationSchema()}>
                {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
                    <Form>
                        {isUserLoading ? (
                            <Box className={classes.loadingContainer}>
                                <Loading />
                            </Box>
                        ) : (
                            <>
                                <Box className={classes.container}>
                                    {Object.keys(values).map(fieldKey => (
                                        <BaseInput
                                            key={fieldKey}
                                            label={t(`users.createModal.${fieldKey}`)}
                                            id={fieldKey}
                                            name={fieldKey}
                                            required={
                                                createUserHelper.formOptions.hasOwnProperty(fieldKey) &&
                                                createUserHelper.formOptions[fieldKey].required
                                            }
                                            value={values[fieldKey]}
                                            helperText={
                                                errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey) ? t(errors[fieldKey]) : ''
                                            }
                                            error={!!(errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey))}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    ))}
                                </Box>

                                <div className={classes.actions}>
                                    <Button onClick={handleCloseDialog} color="secondary" variant="contained">
                                        {t('cancel')}
                                    </Button>
                                    <Button onClick={handleSubmit} disabled={false} variant="outlined" className={classes.submitButton}>
                                        {t('save')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </BaseDialog>
    );
};

export default CreateUser;
