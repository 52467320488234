import config from '../../../_config';

const { tenantSlugBaseUrl } = config;

const baseUrl = '/users';
const baseUrlWithSlug = `${tenantSlugBaseUrl}${baseUrl}`;

const create = (client, data) => {
    const url = `${baseUrlWithSlug}`;

    return client.post(url, data);
};

const find = (client, params) => {
    const url = `${baseUrlWithSlug}/paged`;
    return client.get(url, { params });
};

const block = (client, userId) => {
    const url = `${baseUrl}/${userId}/block`;

    return client.put(url);
};

const unblock = (client, userId) => {
    const url = `${baseUrl}/${userId}/unblock`;

    return client.put(url);
};

const remove = (client, userId) => {
    const url = `${baseUrl}/${userId}`;

    return client.delete(url);
};

export default client => ({
    create: data => create(client, data),
    find: params => find(client, params),
    block: userId => block(client, userId),
    unblock: userId => unblock(client, userId),
    remove: userId => remove(client, userId)
});
