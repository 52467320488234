import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        background: theme.palette.background.primary
    },
    logo: {
        width: '100%'
    },
    logoContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
        width: '22rem'
    },
    loadingContainer: {
        display: 'flex',
        height: '12rem'
    }
}));
