import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';

import useStyles from './CollapsibleListHeader.styles';

const propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            width: PropTypes.string
        })
    ).isRequired
};

const CollapsibleListHeader = ({ headers }) => {
    const classes = useStyles();

    const getStyles = (header) => header?.width ? { maxWidth: header.width } : {};

    return (
        <Box
            display="flex"
            alignItems="center"
            className={classes.root}
        >
            {
                headers.map((header) => (
                    <Typography
                        key={header.title}
                        className={classnames(classes.headerTitle, {
                            fixed: !!header.width
                        })}
                        style={getStyles(header)}
                        variant="body1"
                    >
                        {header.title}
                    </Typography>
                ))
            }
        </Box>
    );
};

CollapsibleListHeader.propTypes = propTypes;

export default CollapsibleListHeader;
