import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../../notifications';

export const getAlbums = projectId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_ALBUMS_START });

    try {
        const response = await apiSdk.projectAlbums.list(projectId);
        const { data } = response;

        dispatch({ type: actionTypes.GET_ALBUMS_SUCCESS, payload: data });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchAlbums'));
        dispatch({ type: actionTypes.GET_ALBUMS_FAIL });
    }
};

export const reset = () => ({
    type: actionTypes.RESET
});
