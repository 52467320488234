import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import useStyles from './ListItem.styles';

const propTypes = {
    children: PropTypes.node.isRequired
};

const ListItem = ({ children }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box>{children}</Box>
        </Box>
    );
};

ListItem.propTypes = propTypes;

export default ListItem;
