import auth from './auth';
import territories from './territories';
import projects from './projects';
import projectAlbums from './project-albums';
import projectCommunication from './project-communication';
import projectLicensing from './project-licensing';
import projectTimeline from './project-timeline';
import tenantSpatial from './tenant-spatial';
import files from './files';
import users from './users';

export default client => ({
    auth: auth(client),
    territories: territories(client),
    projects: projects(client),
    projectAlbums: projectAlbums(client),
    projectCommunication: projectCommunication(client),
    projectLicensing: projectLicensing(client),
    projectTimeline: projectTimeline(client),
    tenantSpatial: tenantSpatial(client),
    files: files(client),
    users: users(client)
});
