import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        opacity: 1,
        color: theme.palette.cityooText
    },
    selected: {
        background: theme.palette.primary.main,
        color: theme.palette.text.white
    }
}));
