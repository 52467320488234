import React, { useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormControl, Button, Box, InputLabel } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import useStyles from './FileUpload.styles';

const propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isMultiple: PropTypes.bool,
    onFileChange: PropTypes.func.isRequired,
    error: PropTypes.bool
};

const defaultProps = {
    isMultiple: false,
    error: false
};

const FileUpload = ({ label, name, isMultiple, onFileChange, error }) => {
    const { t } = useTranslation();
    const hiddenFileInput = useRef(null);
    const classes = useStyles();

    const onAddPhotos = useCallback(() => {
        hiddenFileInput.current.click();
    }, [hiddenFileInput]);

    const handleFileChange = event => {
        const { files } = event.target;
        onFileChange(files);
    };

    return (
        <FormControl className={classes.root}>
            <InputLabel color="secondary" error={error} htmlFor={name} classes={{ root: classes.customLabel }}>
                {label}
            </InputLabel>
            <Box className={classes.actionContainer}>
                <Button variant="contained" aria-label={name} color="primary" size="small" onClick={onAddPhotos}>
                    <AddIcon />
                    {t('addPhotos')}
                </Button>
            </Box>
            <input
                multiple={isMultiple}
                type="file"
                accept="image/png, image/jpeg"
                name={name}
                ref={hiddenFileInput}
                onChange={handleFileChange}
                className={classes.hiddenFileInput}
            />
        </FormControl>
    );
};

FileUpload.defaultProps = defaultProps;
FileUpload.propTypes = propTypes;

export default FileUpload;
