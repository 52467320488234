import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../modal-manager/actionTypes';

import { actions as notificationsActions } from '../notifications';

export const getUsers = params => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_USERS_START });

    try {
        const response = await apiSdk.users.find(params);
        const { data } = response;

        dispatch({ type: actionTypes.GET_USERS_SUCCESS, payload: data });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchUsers'));
        dispatch({ type: actionTypes.GET_USERS_FAIL });
    }
};

export const reset = () => ({
    type: actionTypes.RESET
});

export const setPage = page => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: page
});


export const blockUser = id => async (dispatch, getState, { apiSdk }) => {
    dispatch({ type: modalActionTypes.CLOSE_CONFIRM_MODAL });

    const state = getState();

    try {
        await apiSdk.users.block(id);

        dispatch(getUsers({ limit: state.users?.users?.limit, page: state.users?.users?.page }));
        dispatch(notificationsActions.openSuccessNotification('success.blockUser'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.blockUser'));
    }
};

export const unblockUser = id => async (dispatch, getState, { apiSdk }) => {
    dispatch({ type: modalActionTypes.CLOSE_CONFIRM_MODAL });

    const state = getState();

    try {
        await apiSdk.users.unblock(id);

        dispatch(notificationsActions.openSuccessNotification('success.unblockUser'));
        dispatch(getUsers({ limit: state.users?.users?.limit, page: state.users?.users?.page }));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.unblockUser'));
    }
};

export const deleteUser = id => async (dispatch, getState, { apiSdk }) => {
    dispatch({ type: modalActionTypes.CLOSE_CONFIRM_MODAL });

    const state = getState();

    try {
        await apiSdk.users.remove(id);

        dispatch(notificationsActions.openSuccessNotification('success.deleteUser'));
        dispatch(getUsers({ limit: state.users.users?.limit, page: 1 }));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.deleteUser'));
    }
};
