import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '30rem'
        }
    },
    rootInput: {
        '&::before': {
            height: '10px',
            content: '""'
        },
        border: `1px solid ${theme?.palette?.cityooText}`,
        borderRadius: '10px',
        paddingLeft: '0.2rem'
    },
    rootDefaultInput: {
        height: '1.6rem'
    },
    labelRoot: {
        marginBottom: '2rem'
    },
    hideUnderline: {
        '&::before': {
            borderBottomColor: 'transparent'
        }
    }
}));

export default useStyles;
