/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { uuidv4 } from '../../../../utils/uuid';

import useStyles from './FilesList.styles';
import FileUpload from '../../../file-upload';

const propTypes = {
    files: PropTypes.array,
    handleFilesChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool
};

const defaultProps = {
    files: [],
    isRequired: false
};

const FilesList = ({ files, handleFilesChange, error, isRequired }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectedFiles, setSelectedFiles] = useState([]);

    const getFileUrl = file => file.fileUrl || URL.createObjectURL(file);
    const getFileName = file => (file.filename ? `${file.filename?.replace('uploads/', '').substring(0, 25)}...` : file.name);

    useEffect(() => {
        const items = files.map(file => ({
            id: file.id || uuidv4(),
            blob: getFileUrl(file),
            name: getFileName(file),
            original: file
        }));

        setSelectedFiles(items);
    }, [files]);

    const onFileSelected = useCallback(
        fileList => {
            const filesArray = Array.from(fileList);
            const items = [...files, ...filesArray];
            handleFilesChange(items);
        },
        [files, handleFilesChange]
    );

    const onDeleteFile = useCallback(
        file => {
            const remainingFiles = selectedFiles.filter(({ id }) => file.id !== id);
            handleFilesChange(remainingFiles.map(({ original }) => original));
        },
        [selectedFiles, handleFilesChange]
    );

    const requiredLabel = isRequired ? '*' : '';

    return (
        <Box className={classes.container}>
            <FileUpload
                label={`${t('projects.albums.modal.photos')}${requiredLabel}`}
                name="album-photos"
                onFileChange={onFileSelected}
                error={error}
                isMultiple
            />
            {selectedFiles.length > 0 && (
                <List className={classes.list}>
                    {selectedFiles.map((file, index) => (
                        <ListItem key={file.id} dense>
                            <ListItemAvatar>
                                <Avatar classes={{ root: classes.squareAvatar }} alt={file.name} src={file.blob} />
                            </ListItemAvatar>
                            <ListItemText primary={`${index + 1}. ${file.name}`} />
                            <ListItemSecondaryAction>
                                <IconButton aria-label="edit" onClick={() => onDeleteFile(file)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

FilesList.defaultProps = defaultProps;
FilesList.propTypes = propTypes;

export default FilesList;
