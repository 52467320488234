import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BaseInput from '../../../base-input';

import createProjectHelper from '../helper';

const propTypes = {
    values: PropTypes.shape({
        address: PropTypes.string.isRequired,
        createDate: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired,
        projectResponsible: PropTypes.string.isRequired,
        reference: PropTypes.string.isRequired
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        address: PropTypes.bool,
        createDate: PropTypes.bool,
        postalCode: PropTypes.bool,
        projectResponsible: PropTypes.bool,
        reference: PropTypes.bool
    }).isRequired,
    errors: PropTypes.shape({
        address: PropTypes.string,
        createDate: PropTypes.string,
        postalCode: PropTypes.string,
        projectResponsible: PropTypes.string,
        reference: PropTypes.string
    }).isRequired
};

const ProjectDetails = ({ errors, touched, values, handleChange, handleBlur }) => {
    const { t } = useTranslation();

    return (
        <>
            {Object.keys(values).map(fieldKey => (
                <BaseInput
                    key={fieldKey}
                    label={t(`projects.createModal.${fieldKey}`)}
                    id={fieldKey}
                    name={fieldKey}
                    required={createProjectHelper.formOptions.hasOwnProperty(fieldKey) && createProjectHelper.formOptions[fieldKey].required}
                    disabled={fieldKey === 'createDate'}
                    value={values[fieldKey]}
                    helperText={errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey) ? t(errors[fieldKey]) : ''}
                    error={!!(errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            ))}
        </>
    );
};

ProjectDetails.propTypes = propTypes;

export default ProjectDetails;
