import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../../notifications';

export const getLicensing = projectId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_LICENSING_START });

    try {
        const response = await apiSdk.projectLicensing.get(projectId);
        const { data } = response;

        dispatch({ type: actionTypes.GET_LICENSING_SUCCESS, payload: data });
    } catch (error) {
        if (error?.response?.status !== 404) {
            dispatch(notificationsActions.openErrorNotification('error.fetchLicensing'));
        }

        dispatch({ type: actionTypes.GET_LICENSING_FAIL });
    }
};

export const reset = () => ({
    type: actionTypes.RESET
});
