import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBot: theme.spacing(1)
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    customLabel: {
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        position: 'relative'
    },
    hiddenFileInput: {
        display: 'none'
    }
}));
