import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '28rem',
        minHeight: '28rem',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            minWidth: '0',
            minHeight: '0'
        }
    },
    logo: {
        width: '100%'
    },
    logoContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
        width: '22rem'
    },
    buttonContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    resetPassword: {
        cursor: 'pointer',
        color: theme.palette.text.white
    },
    error: {
        color: theme.palette.text.error,
        fontWeight: 600,
        maxWidth: '28rem'
    },
    success: {
        color: theme.palette.text.success,
        fontWeight: 600,
        maxWidth: '28rem',
        textAlign: 'center'
    },
    loadingContainer: {
        display: 'flex',
        height: '12rem'
    }
}));
