const reducerName = '@cityoo-tenant/account';

export const RESET = `${reducerName}/RESET`;

export const LOGIN_START = `${reducerName}/LOGIN_START`;
export const LOGIN_SUCCESS = `${reducerName}/LOGIN_SUCCESS`;
export const LOGIN_FAIL = `${reducerName}/LOGIN_FAIL`;

export const GET_ME_START = `${reducerName}/GET_ME_START`;
export const GET_ME_SUCCESS = `${reducerName}/GET_ME_SUCCESS`;
export const GET_ME_FAIL = `${reducerName}/GET_ME_FAIL`;

export const RECOVER_PASSWORD_START = `${reducerName}/RECOVER_PASSWORD_START`;
export const RECOVER_PASSWORD_SUCCESS = `${reducerName}/RECOVER_PASSWORD_SUCCESS`;
export const RECOVER_PASSWORD_FAIL = `${reducerName}/RECOVER_PASSWORD_FAIL`;

export const RESET_PASSWORD_START = `${reducerName}/RESET_PASSWORD_START`;
export const RESET_PASSWORD_SUCCESS = `${reducerName}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${reducerName}/RESET_PASSWORD_FAIL`;
