import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

import * as fromReducer from './reducer';

const getFormProjectFromReducer = get('form.project');

export const getIsLoading = state => fromReducer.getIsLoading(getFormProjectFromReducer(state));

export const getProjectsDetails = state => fromReducer.getProjectsDetails(getFormProjectFromReducer(state));

export const getParishId = state => fromReducer.getParishId(getFormProjectFromReducer(state));

const getChangeClusterReducer = state => fromReducer.getChangeCluster(getFormProjectFromReducer(state));

export const getChangeCluster = createSelector([getChangeClusterReducer], changeCluster => {
    return {
        ...changeCluster,
        geometry: {
            ...changeCluster.geometry,
            coordinates:
                changeCluster.geometry.coordinates[0] && changeCluster.geometry.coordinates[0][0]
                    ? [[...changeCluster.geometry.coordinates[0], changeCluster.geometry.coordinates[0][0]]]
                    : []
        }
    };
});

export const getCoordinates = createSelector([getChangeClusterReducer], geoJSON => {
    const [coordinates] = geoJSON?.geometry?.coordinates;

    if (!coordinates) {
        return [];
    }

    return coordinates.reduce((acc, point) => {
        acc.push({
            latitude: point[1],
            longitude: point[0]
        });

        return acc;
    }, []);
});

export const getNumberOfCoordinates = createSelector([getCoordinates], coordinates => coordinates.length);
