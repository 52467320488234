import { get } from 'lodash/fp';
import { isEmpty } from 'lodash';

import * as fromReducer from './reducer';

const getAccountFromReducer = get('account');

export const getIsLoading = state => fromReducer.getIsLoading(getAccountFromReducer(state));

export const getIsLoadingGeMe = state => fromReducer.getIsLoadingGeMe(getAccountFromReducer(state));

export const getError = state => fromReducer.getError(getAccountFromReducer(state));

export const getUser = state => fromReducer.getUser(getAccountFromReducer(state));

export const getRecoverPassword = state => fromReducer.getRecoverPassword(getAccountFromReducer(state));

export const getResetPassword = state => fromReducer.getResetPassword(getAccountFromReducer(state));

export const isAuthenticated = state => !isEmpty(getUser(state));
