import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme, Box, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { MapContainer, TileLayer } from 'react-leaflet';

import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../../redux/modal-manager';
import { selectors as projectSelectors } from '../../../redux/projects/project/details';

import { ProjectStatusBanner } from '../../../components/project-status';
import SurveillanceAreaMap from '../../../components/modals/create-project/surveillance-area-map';

import ProjectActivity from '../project-activity';
import ProjectDelete from '../project-delete';
import ProjectTimeline from '../project-timeline';

import config from '../../../_config';

import 'leaflet/dist/leaflet.css';
import useStyles from './ProjectDashboard.styles';

const TIMELINE_LIMIT_PHOTOS = 4;

const propTypes = {
    isSidePanel: PropTypes.bool
};

const defaultProps = {
    isSidePanel: false
};

const ProjectDashboard = ({ isSidePanel }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedProject = useSelector(projectSelectors.getProject);
    const projectChangeCluster = useSelector(projectSelectors.getProjectChangeCluster);

    const { mapConfigs } = config;
    const isSingleColumnDashboard = isMobile || isSidePanel;

    const onProjectStatusChange = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.CLASSIFICATION_PROJECT, { projectId: selectedProject.id }));
    }, [dispatch, selectedProject.id]);

    const renderSurveillanceAreaMap = () => (
        <>
            <Typography className={classes.tilesTitle}>{t('projects.identification.surveillanceArea')}</Typography>
            <MapContainer
                className={classes.mapContainer}
                scrollWheelZoom={false}
                center={mapConfigs.center}
                zoomControl={mapConfigs.zoomControl}
            >
                <>
                    <TileLayer attribution={mapConfigs.disclaimer.title} url={mapConfigs.disclaimer.link} />
                    <SurveillanceAreaMap geoJSON={projectChangeCluster} />
                </>
            </MapContainer>
            <Typography variant="body1">
                {`${selectedProject.address ?? ''}${selectedProject.address ? ',' : ''} ${selectedProject.postalCode ?? ''}`}
            </Typography>
        </>
    );

    return (
        <>
            <Box className={classes.container} display="flex" flexDirection="row">
                <Box flex={1} className={classnames({ [classes.gap]: !isSingleColumnDashboard })}>
                    {!isSidePanel && (
                        <Box className={classes.tilesContainer}>
                            <ProjectStatusBanner status={selectedProject.status} date={selectedProject.createdAt} onClick={onProjectStatusChange} />
                        </Box>
                    )}
                    {isSingleColumnDashboard && (
                        <Box flex={1} className={classnames(classes.tilesContainer, { withShadow: true })}>
                            {renderSurveillanceAreaMap()}
                        </Box>
                    )}
                    <Box className={classes.tilesContainer}>
                        <ProjectTimeline isSmall isDashboard limit={TIMELINE_LIMIT_PHOTOS}>
                            <Typography className={classes.tilesTitle}>{t('projects.tabs.timeline')}</Typography>
                        </ProjectTimeline>
                    </Box>
                    <Box className={classnames(classes.tilesContainer, { withShadow: true })}>
                        <Typography className={classes.tilesTitle}>{t('projects.tabs.activity')}</Typography>
                        <ProjectActivity isSmall isDashboard />
                    </Box>
                </Box>
                {!isSingleColumnDashboard && (
                    <Box width="40%">
                        <Box flex={1} className={classnames(classes.tilesContainer, { withShadow: true })}>
                            {renderSurveillanceAreaMap()}
                        </Box>
                    </Box>
                )}
            </Box>

            {selectedProject &&  <ProjectDelete />}
        </> 
    );
};

ProjectDashboard.propTypes = propTypes;
ProjectDashboard.defaultProps = defaultProps;

export default ProjectDashboard;
