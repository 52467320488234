import { tenantSlug } from '../../_config';

const ROUTES = {
    HOME: `/t/${tenantSlug}/`,
    MAP: `/t/${tenantSlug}/map`,
    DASHBOARD: `/t/${tenantSlug}/dashboard`,
    TASKS: `/t/${tenantSlug}/tasks`,
    USERS: `/t/${tenantSlug}/users`,
    PERMISSIONS: `/t/${tenantSlug}/permissions`,
    ACTIVITY: `/t/${tenantSlug}/activity`,
    BILLING: `/t/${tenantSlug}/billing`,
    PROJECTS: `/t/${tenantSlug}/projects`,
    PROJECTS_ID: `/t/${tenantSlug}/projects/:id`,
    PROJECTS_ID_TIMELINE: `/t/${tenantSlug}/projects/:id/timeline`,
    PROJECTS_ID_IDENTIFICATION: `/t/${tenantSlug}/projects/:id/identification`,
    PROJECTS_ID_LICENSE: `/t/${tenantSlug}/projects/:id/license`,
    PROJECTS_ID_GALLERY: `/t/${tenantSlug}/projects/:id/gallery`,
    PROJECTS_ID_ACTIVITY: `/t/${tenantSlug}/projects/:id/activity`,
    LOGIN: `/t/${tenantSlug}/login`,
    RECOVER_PASSWORD: `/t/${tenantSlug}/recover-password`,
    RECOVER_PASSWORD_LINK: `/t/${tenantSlug}/recover-password-confirmation`,
    PROJECT_SIDEPANEL: `/t/${tenantSlug}/map/projects/:id`,
    PROJECT_SIDEPANEL_TIMELINE: `/t/${tenantSlug}/map/projects/:id/timeline`,
    PROJECT_SIDEPANEL_IDENTIFICATION: `/t/${tenantSlug}/map/projects/:id/identification`,
    PROJECT_SIDEPANEL_GALLERY: `/t/${tenantSlug}/map/projects/:id/gallery`,
    PROJECT_SIDEPANEL_ACTIVITY: `/t/${tenantSlug}/map/projects/:id/activity`
};

export const getProjectUrl = {
    dashboard: id => `${ROUTES.PROJECTS_ID.replace(/:id/, id)}`,
    timeline: id => `${ROUTES.PROJECTS_ID_TIMELINE.replace(/:id/, id)}`,
    identification: id => `${ROUTES.PROJECTS_ID_IDENTIFICATION.replace(/:id/, id)}`,
    license: id => `${ROUTES.PROJECTS_ID_LICENSE.replace(/:id/, id)}`,
    gallery: id => `${ROUTES.PROJECTS_ID_GALLERY.replace(/:id/, id)}`,
    activity: id => `${ROUTES.PROJECTS_ID_ACTIVITY.replace(/:id/, id)}`
};

export const getSidepanelUrl = {
    dashboard: id => `${ROUTES.PROJECT_SIDEPANEL.replace(/:id/, id)}`,
    timeline: id => `${ROUTES.PROJECT_SIDEPANEL_TIMELINE.replace(/:id/, id)}`,
    identification: id => `${ROUTES.PROJECT_SIDEPANEL_IDENTIFICATION.replace(/:id/, id)}`,
    gallery: id => `${ROUTES.PROJECT_SIDEPANEL_GALLERY.replace(/:id/, id)}`,
    activity: id => `${ROUTES.PROJECT_SIDEPANEL_ACTIVITY.replace(/:id/, id)}`
};

export default ROUTES;
