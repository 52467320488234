import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION, RESET_ALL } from '../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    isLoadingGeMe: false,
    user: {},
    error: '',
    recoverPassword: {
        isLoading: false,
        success: false,
        message: ''
    },
    resetPassword: {
        isLoading: false,
        success: false,
        message: ''
    }
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
        case actionTypes.LOGIN_FAIL:
            return false;
        case actionTypes.LOGIN_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const isLoadingGeMe = (state = initialState.isLoadingGeMe, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_ME_SUCCESS:
        case actionTypes.GET_ME_FAIL:
            return false;
        case actionTypes.GET_ME_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoadingGeMe;
        default:
            return state;
    }
};

export const error = (state = initialState.error, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.LOGIN_FAIL:
            return action.payload;
        case actionTypes.LOGIN_SUCCESS:
        case actionTypes.LOGIN_START:
        case actionTypes.RESET:
            return initialState.error;
        default:
            return state;
    }
};

export const user = (state = initialState.user, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
        case actionTypes.GET_ME_SUCCESS:
            return action.payload;
        case actionTypes.LOGIN_FAIL:
        case actionTypes.LOGIN_START:
        case actionTypes.GET_ME_FAIL:
        case actionTypes.RESET:
        case RESET_ALL:
            return initialState.user;
        default:
            return state;
    }
};

export const recoverPassword = (state = initialState.recoverPassword, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.RECOVER_PASSWORD_START:
            return {
                ...initialState.recoverPassword,
                isLoading: true
            };

        case actionTypes.RECOVER_PASSWORD_FAIL:
            return {
                ...initialState.recoverPassword,
                message: 'error_recover_password'
            };

        case actionTypes.RECOVER_PASSWORD_SUCCESS:
            return {
                ...initialState.recoverPassword,
                success: true,
                message: 'success_recover_password'
            };
        case actionTypes.RESET:
            return initialState.recoverPassword;
        default:
            return state;
    }
};

export const resetPassword = (state = initialState.resetPassword, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.RESET_PASSWORD_START:
            return {
                ...initialState.resetPassword,
                isLoading: true
            };

        case actionTypes.RESET_PASSWORD_FAIL:
            return {
                ...initialState.resetPassword,
                message: 'error_reset_password'
            };

        case actionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...initialState.resetPassword,
                success: true,
                message: 'success_reset_password'
            };
        case actionTypes.RESET:
            return initialState.resetPassword;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getIsLoadingGeMe = state => get(state, 'isLoadingGeMe', initialState.isLoadingGeMe);

export const getError = state => get(state, 'error', initialState.error);

export const getUser = state => get(state, 'user', initialState.user);

export const getRecoverPassword = state => get(state, 'recoverPassword', initialState.recoverPassword);

export const getResetPassword = state => get(state, 'resetPassword', initialState.resetPassword);

const reducer = combineReducers({
    isLoading,
    isLoadingGeMe,
    error,
    user,
    recoverPassword,
    resetPassword
});

export default reducer;
