import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@material-ui/core';

const propTypes = {
    email: PropTypes.string.isRequired
};

const EmailLink = ({ email }) => {
    return (
        <Link
            href={`mailto:${email}`}
            variant="body2"
            color="inherit"
            underline="always"
        >
            {email}
        </Link>
    );
};

EmailLink.propTypes = propTypes;

export default EmailLink;
