const host = process.env.REACT_APP_API_BASE_URL;
const mapConfigs = {
    disclaimer: {
        title: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        link: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },
    center: [40.2033145, -8.4102573],
    zoom: 13,
    scrollWheelZoom: true,
    zoomControl: false
};

if (!host) {
    throw new Error('API_BASE_URL env var not set');
}

export const tenantSlug = (() => {
    // eslint-disable-next-line
    const regex = /\/t\/([^\/]+)\//;
    const str = window.location.pathname;
    const match = regex.exec(str);
    if (Array.isArray(match) && match.length === 2) {
        return match[1];
    }

    throw new Error('Failed to find tenant slug');
})();

const config = {
    apiBaseUrl: `${host}/api/v1`,
    tenantSlugBaseUrl: `/t/${tenantSlug}`,
    tenantSlug,
    TOKENS: 'TOKENS',
    mapConfigs,
    notificationsDefaultTime: 6000
};

export default config;
