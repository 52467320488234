import config from '../../../_config';

const { tenantSlugBaseUrl } = config;

const baseUrl = '/auth';
const baseUrlWithSlug = `${tenantSlugBaseUrl}${baseUrl}`;

const getMe = client => {
    const url = `${baseUrl}/me`;

    return client.get(url, {});
};

const login = (client, data) => {
    const url = `${baseUrlWithSlug}/login`;

    return client.post(url, {
        email: data.email,
        password: data.password
    });
};

const recoverPassword = (client, email) => {
    const url = `${baseUrlWithSlug}/recover-password`;

    return client.post(url, {
        email
    });
};

const resetPassword = (client, data) => {
    const url = `${baseUrl}/recover-password/confirmation`;

    return client.post(url, {
        token: data.token,
        password: data.password
    });
};

export default client => ({
    getMe: () => getMe(client),
    login: data => login(client, data),
    recoverPassword: email => recoverPassword(client, email),
    resetPassword: data => resetPassword(client, data)
});
