import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../notifications';

export const getDistricts = countryId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_DISTRICTS_START });

    try {
        const response = await apiSdk.territories.getDistricts(countryId);
        const { data } = response;

        dispatch({ type: actionTypes.GET_DISTRICTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchDistricts'));
        dispatch({ type: actionTypes.GET_DISTRICTS_FAIL });
    }
};

export const reset = () => ({
    type: actionTypes.RESET
});
