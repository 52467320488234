import { DEFAULT_ACTION } from '../constants';
import enumerators from './enumerators';

import * as actionTypes from './actionTypes';

export const openModal = (modal, payload) => {
    switch (modal) {
        case enumerators.CREATE_PROJECT:
            return { type: actionTypes.OPEN_CREATE_PROJECT, payload };
        case enumerators.CREATE_ALBUM:
            return { type: actionTypes.OPEN_CREATE_ALBUM, payload };
        case enumerators.IMAGE_PREVIEW:
            return { type: actionTypes.OPEN_IMAGE_PREVIEW, payload };
        case enumerators.CLASSIFICATION_PROJECT:
            return { type: actionTypes.OPEN_CLASSIFICATION_PROJECT, payload };
        case enumerators.UNFULFILLED:
            return { type: actionTypes.OPEN_UNFULFILLED_MODAL, payload };
        case enumerators.CREATE_USER:
            return { type: actionTypes.OPEN_CREATE_USER, payload };
        case enumerators.LICENSE_INFO:
            return { type: actionTypes.OPEN_LICENSE_INFO, payload };
        case enumerators.CONFIRM_MODAL:
            return { type: actionTypes.OPEN_CONFIRM_MODAL, payload };
        case enumerators.PRIOR_COMMUNICATION:
            return { type: actionTypes.OPEN_PRIOR_COMMUNICATION_INFO, payload };
        default:
            return DEFAULT_ACTION;
    }
};

export const closeModal = modal => {
    switch (modal) {
        case enumerators.CREATE_PROJECT:
            return { type: actionTypes.CLOSE_CREATE_PROJECT };
        case enumerators.CREATE_ALBUM:
            return { type: actionTypes.CLOSE_CREATE_ALBUM };
        case enumerators.IMAGE_PREVIEW:
            return { type: actionTypes.CLOSE_IMAGE_PREVIEW };
        case enumerators.CLASSIFICATION_PROJECT:
            return { type: actionTypes.CLOSE_CLASSIFICATION_PROJECT };
        case enumerators.UNFULFILLED:
            return { type: actionTypes.CLOSE_UNFULFILLED_MODAL };
        case enumerators.CREATE_USER:
            return { type: actionTypes.CLOSE_CREATE_USER };
        case enumerators.LICENSE_INFO:
            return { type: actionTypes.CLOSE_LICENSE_INFO };
        case enumerators.CONFIRM_MODAL:
            return { type: actionTypes.CLOSE_CONFIRM_MODAL };
        case enumerators.PRIOR_COMMUNICATION:
            return { type: actionTypes.CLOSE_PRIOR_COMMUNICATION_INFO };
        default:
            return DEFAULT_ACTION;
    }
};

export const resetModal = modal => {
    switch (modal) {
        case enumerators.RESET_MODALS:
            return { type: actionTypes.RESET };
        default:
            return DEFAULT_ACTION;
    }
};
