/* eslint-disable no-restricted-syntax */
function encodeQueryDeepObject(obj, prefix) {
    let str = {};
    let p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            const k = prefix ? `${prefix}[${p}]` : p;
            const v = obj[p];
            if (v !== null && typeof v === 'object') {
                const childResuls = encodeQueryDeepObject(v, k);
                str = {
                    ...str,
                    ...childResuls
                };
            } else {
                str = {
                    ...str,
                    [k]: v
                };
            }
        }
    }
    return str;
}

export const removeEmptyInfoAndRemovePropFromObject = (obj, removedProps = []) => {
    return Object.keys(obj).reduce((acc, key) => {
        const newAcc = acc;

        if (obj[key] !== null && obj[key] !== '') {
            if (removedProps.every(removedProp => removedProp !== key)) {
                newAcc[key] = obj[key];
            }
        }

        return newAcc;
    }, {});
};

export { encodeQueryDeepObject };
