import { get } from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';
import * as modalManagerActionTypes from '../../modal-manager/actionTypes';
import modalActionEnumeratos from '../../modal-manager/enumerators';

const initialStateProjectDetails = {
    reference: '',
    createDate: moment().format('DD/MM/YYYY'),
    projectResponsible: '',
    address: '',
    postalCode: ''
};

const initialState = {
    isLoading: false,
    projectDetails: initialStateProjectDetails,
    parishId: null,
    changeCluster: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: []
        },
        properties: {}
    }
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_PROJECT_START:
            return true;
        case actionTypes.SUBMIT_PROJECT_SUCCESS:
        case actionTypes.SUBMIT_PROJECT_FAIL:
            return false;
        case modalManagerActionTypes.OPEN_CREATE_PROJECT:
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const projectDetails = (state = initialState.projectDetails, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case modalManagerActionTypes.OPEN_CREATE_PROJECT: {
            if (action.payload?.type !== modalActionEnumeratos.TYPE_EDIT) {
                return initialState.projectDetails;
            }

            const {
                selectProject: { name, createdAt, projectResponsible, address, postalCode }
            } = action.payload;

            return {
                reference: name ?? initialState.name,
                createDate: moment(createdAt).format('DD/MM/YYYY') ?? moment().format('DD/MM/YYYY'),
                projectResponsible: projectResponsible ?? initialState.projectResponsible,
                address: address ?? initialState.address,
                postalCode: postalCode ?? initialState.postalCode
            };
        }
        case actionTypes.RESET:
            return initialState.projectDetails;
        default:
            return state;
    }
};

export const parishId = (state = initialState.parishId, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_PARISH_ID:
            return action.payload;
        case modalManagerActionTypes.OPEN_CREATE_PROJECT: {
            if (action.payload?.type !== modalActionEnumeratos.TYPE_EDIT) {
                return initialState.parishId;
            }

            return action.payload.selectProject?.territory?.parish?.id ?? initialState.parishId;
        }
        case actionTypes.RESET:
            return initialState.parishId;
        default:
            return state;
    }
};

export const changeCluster = (state = initialState.changeCluster, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.INSERT_COORDINATES:
            return {
                ...state,
                geometry: {
                    ...state.geometry,
                    coordinates: [[[Number(action.payload.longitude), Number(action.payload.latitude)], ...(state.geometry.coordinates[0] ?? [])]]
                }
            };
        case actionTypes.REMOVE_COORDINATES: {
            const coordinates = state.geometry.coordinates[0];
            coordinates.splice(action.payload, 1);

            return {
                ...state,
                geometry: {
                    ...state.geometry,
                    coordinates: [coordinates]
                }
            };
        }
        case modalManagerActionTypes.OPEN_CREATE_PROJECT: {
            if (action.payload?.type !== modalActionEnumeratos.TYPE_EDIT) {
                return initialState.changeCluster;
            }

            return action.payload?.selectProject?.changeCluster;
        }
        case actionTypes.RESET:
            return initialState.changeCluster;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getProjectsDetails = state => get(state, 'projectDetails', initialState.projectDetails);

export const getParishId = state => get(state, 'parishId', initialState.parishId);

export const getChangeCluster = state => get(state, 'changeCluster', initialState.changeCluster);

const reducer = combineReducers({
    isLoading,
    projectDetails,
    parishId,
    changeCluster
});

export default reducer;
