import * as actionTypes from './actionTypes';

export const closeNotification = () => ({
    type: actionTypes.CLOSE_NOTIFICATION
});

export const openNotification = (message, severity) => ({
    type: actionTypes.OPEN_NOTIFICATION,
    payload: {
        message,
        severity
    }
});

export const openSuccessNotification = message => ({
    type: actionTypes.OPEN_SUCCESS_NOTIFICATION,
    payload: {
        message
    }
});

export const openInfoNotification = message => ({
    type: actionTypes.OPEN_INFO_NOTIFICATION,
    payload: {
        message
    }
});

export const openWarningNotification = message => ({
    type: actionTypes.OPEN_WARNING_NOTIFICATION,
    payload: {
        message
    }
});

export const openErrorNotification = message => ({
    type: actionTypes.OPEN_ERROR_NOTIFICATION,
    payload: {
        message
    }
});
