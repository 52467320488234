import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme, Menu, IconButton } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

import ActionsGroupContext from '../../context/ActionsGroupContext';

import useStyles from './ActionsGroup.styles';

const propTypes = {
    children: PropTypes.node.isRequired
};

const ActionsGroup = ({children}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(null);

    const handleMenuClose = useCallback((ev) => {
        ev.stopPropagation();
        setMenuOpen(null);
    }, [setMenuOpen]);

    const handleMenuOnClick = useCallback((ev) => {
        ev.stopPropagation();
        setMenuOpen(ev.currentTarget);
    }, [setMenuOpen]);

    return (
        <ActionsGroupContext.Provider value={{ handleMenuClose }}>
            <div>
                {
                    isMobile ? 
                        (
                            <>
                                <IconButton
                                    className={classes.childButton}
                                    onClick={handleMenuOnClick}
                                >
                                    <MoreVertIcon fontSize="small" />
                                </IconButton>
                                <Menu
                                    anchorEl={menuOpen}
                                    keepMounted={false}
                                    open={Boolean(menuOpen)}
                                    onClose={handleMenuClose}
                                    PaperProps={{
                                        style: { minWidth: '8rem' }
                                    }}
                                >
                                    {children}
                                </Menu>
                            </>
                        ) :
                        children
                }
            </div>
        </ActionsGroupContext.Provider>
    );
};

ActionsGroup.propTypes = propTypes;

export default ActionsGroup;
