import { makeStyles } from '@material-ui/styles';
import BackgroundImage from '../../assets/background-cityoo.svg';

export default makeStyles(() => ({
    imageContainer: {
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
}));
