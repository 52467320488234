import config from '../../../_config';

const { tenantSlugBaseUrl } = config;

const baseUrl = '/projects';
const baseUrlWithSlug = `${tenantSlugBaseUrl}${baseUrl}`;

const list = client => {
    return client.get(baseUrlWithSlug, {});
};

const get = (client, projectId) => {
    const url = `${baseUrlWithSlug}/${projectId}`;

    return client.get(url);
};

const insert = (client, data) => {
    const url = `${baseUrlWithSlug}`;

    return client.post(url, data);
};

const update = (client, data, projectId) => {
    const url = `${baseUrlWithSlug}/${projectId}`;

    return client.put(url, data);
};

const setLegal = (client, projectId) => {
    const url = `${baseUrlWithSlug}/${projectId}/status/legal`;

    return client.put(url);
};

const setIllegal = (client, projectId) => {
    const url = `${baseUrlWithSlug}/${projectId}/status/illegal`;

    return client.put(url);
};

const getByClusterId = (client, clusterId) => {
    const url = `${baseUrlWithSlug}/change-cluster/${clusterId}`;

    return client.get(url);
};

const remove = (client, projectId) => {
    const url = `${baseUrlWithSlug}/${projectId}`;

    return client.delete(url);
};

export default client => ({
    list: () => list(client),
    get: projectId => get(client, projectId),
    insert: data => insert(client, data),
    update: (data, projectId) => update(client, data, projectId),
    setLegal: projectId => setLegal(client, projectId),
    setIllegal: projectId => setIllegal(client, projectId),
    getByClusterId: clusterId => getByClusterId(client, clusterId),
    remove: projectId => remove(client, projectId)
});
