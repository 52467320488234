import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        background: theme.palette.background.white,
        minHeight: '4.25rem',
        display: 'flex',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: theme.palette.secondary.main,
        padding: '0.5rem 1.5rem 0.75rem 2.5rem',
        cursor: 'pointer',
        '&.selected': {
            background: theme.palette.background.selected
        }
    },
    details: {
        flex: 1
    },
    actions: {
        paddingLeft: '0.5rem'
    },
    label: {
        lineHeight: '1rem'
    },
    stateBadge: {
        width: 'fit-content',
        color: theme.palette.text.white,
        fontSize: '0.625rem',
        lineHeight: '0.875rem',
        padding: '0 1rem',
        marginTop: '0.25rem',
        textTransform: 'uppercase',
        background: theme.palette.statuses.new,
        borderRadius: '1rem'
    }
}));
