import * as actionTypes from './actionTypes';

export const setSelectedCountry = country => ({
    type: actionTypes.SET_SELECTED_COUNTRY,
    payload: country
});

export const setSelectedDistrict = district => ({
    type: actionTypes.SET_SELECTED_DISTRICT,
    payload: district
});

export const setSelectedCounty = county => ({
    type: actionTypes.SET_SELECTED_COUNTY,
    payload: county
});

export const setSelectedParishes = parish => ({
    type: actionTypes.SET_SELECTED_PARISH,
    payload: parish
});

export const resetSelectedDistrict = () => ({
    type: actionTypes.RESET_SELECTED_DISTRICT
});

export const resetSelectedCounty = () => ({
    type: actionTypes.RESET_SELECTED_COUNTY
});

export const resetSelectedParish = () => ({
    type: actionTypes.RESET_SELECTED_PARISH
});
