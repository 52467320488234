import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import './_i18n';
import Themes from './themes';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import Notifications from './components/base-notifications';
import Fallback from './components/fallback';
import configureStore from './redux';

ReactDOM.render(
    <LayoutProvider>
        <Suspense fallback={<Fallback />}>
            <Provider store={configureStore}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ThemeProvider theme={Themes.default}>
                        <Notifications>
                            <CssBaseline />
                            <App />
                        </Notifications>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </Provider>
        </Suspense>
    </LayoutProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
