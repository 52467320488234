import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse } from '@material-ui/core';

import useStyles from './CollapsibleListItem.styles';
import { LIST_ITEM_HEADER, LIST_ITEM_BODY } from '../enumerators';

const propTypes = {
    children: PropTypes.node.isRequired
};

const CollapsibleListItem = ({children}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const handleOnClick = () => {
        setIsOpen((prevValue) => !prevValue);
    };

    const getChildItemHeaderWithProps = () => {
        const child = children.find(c => c.type.displayName === LIST_ITEM_HEADER);
        if (child && React.isValidElement(child)) {
            return React.cloneElement(child, { isCollapseOpen: isOpen });
        }
        return child;
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.action} onClick={handleOnClick}>
                { children && getChildItemHeaderWithProps() }
            </Box>
            {
                children &&
                children.some(child => child.type.displayName === LIST_ITEM_BODY) &&
                (
                    <Collapse in={isOpen} timeout="auto">
                        { children.find(child => child.type.displayName === LIST_ITEM_BODY) }
                    </Collapse>
                )
            }
        </Box>
    );
};

CollapsibleListItem.propTypes = propTypes;

export default CollapsibleListItem;
