import config from '../../../_config';

const { tenantSlugBaseUrl } = config;

const baseUrl = '/projects';
const baseUrlWithSlug = `${tenantSlugBaseUrl}${baseUrl}`;

const projectAlbumsUrl = projectId => `${baseUrlWithSlug}/${projectId}/albums`;

const list = (client, projectId) => {
    const url = `${projectAlbumsUrl(projectId)}`;

    return client.get(url, {});
};

const get = (client, projectId, albumId) => {
    const url = `${projectAlbumsUrl(projectId)}/${albumId}`;

    return client.get(url);
};

const insert = (client, projectId, data) => {
    const url = `${projectAlbumsUrl(projectId)}`;

    return client.post(url, data);
};

const update = (client, projectId, albumId, data) => {
    const url = `${projectAlbumsUrl(projectId)}/${albumId}`;

    return client.put(url, data);
};

const deletePhoto = (client, projectId, albumId, photoId) => {
    const url = `${projectAlbumsUrl(projectId)}/${albumId}/photos/${photoId}`;

    return client.delete(url);
};

export default client => ({
    list: projectId => list(client, projectId),
    get: (projectId, albumId) => get(client, projectId, albumId),
    insert: (projectId, data) => insert(client, projectId, data),
    update: (projectId, albumId, data) => update(client, projectId, albumId, data),
    deletePhoto: (projectId, albumId, photoId) => deletePhoto(client, projectId, albumId, photoId)
});
