import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../notifications';

export const getCountries = () => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_COUNTRIES_START });

    try {
        const response = await apiSdk.territories.getCountries();
        const { data } = response;

        dispatch({ type: actionTypes.GET_COUNTRIES_SUCCESS, payload: data });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchCountries'));
        dispatch({ type: actionTypes.GET_COUNTRIES_FAIL });
    }
};
