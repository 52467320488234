import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';

import useStyles from './PaginationControls.styles';

const propTypes = {
    pages: PropTypes.number.isRequired,
    page: PropTypes.number,
    onChange: PropTypes.func.isRequired
};

const defaultProps = {
    page: 1
};

// TODO: Router integration

const PaginationControls = ({ pages, page, onChange }) => {
    const classes = useStyles();

    return (
        <Pagination
            classes={classes}
            count={pages}
            defaultPage={page}
            shape="rounded"
            color="primary"
            size="large"
            onChange={onChange}
        />
    );
};

PaginationControls.propTypes = propTypes;
PaginationControls.defaultProps = defaultProps;

export default PaginationControls;
