import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';

import {
    actions as modalManagerActions,
    selectors as modalManagerSelectors,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';
import { actions as formTenantActions, selectors as formProjectSelectos } from '../../../redux/form/project';
import { selectors as projectSelectors } from '../../../redux/projects/project/details';

import Territories from './territories';
import SurveillanceArea from './surveillance-area';
import BaseDialog from '../../base-dialog';
import Loading from '../../base-loading';

import ProjectDetails from './project-details';
import useStyles from './CreateProject.styles';
import createProjectHelper from './helper';

const CreateProject = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    // state props
    const createProjectModal = useSelector(modalManagerSelectors.getCreateProject);
    const projectsDetails = useSelector(formProjectSelectos.getProjectsDetails);
    const parishId = useSelector(formProjectSelectos.getParishId);
    const changeCluster = useSelector(formProjectSelectos.getChangeCluster);
    const numberOfCoordinates = useSelector(formProjectSelectos.getNumberOfCoordinates);
    const isProjectSubmitLoading = useSelector(formProjectSelectos.getIsLoading);
    const selectProjectId = useSelector(projectSelectors.getProjectId);
    const createProjectModalType = useSelector(modalManagerSelectors.getCreateProjectType);

    const handleCloseDialog = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CREATE_PROJECT));
    }, [dispatch]);

    const handleSubmitProjectForm = useCallback(
        values => {
            if (createProjectModalType === modalManagerEnumerators.TYPE_EDIT) {
                dispatch(
                    formTenantActions.updateProject(
                        {
                            ...values,
                            parishId,
                            changeCluster
                        },
                        selectProjectId
                    )
                );
            } else {
                dispatch(
                    formTenantActions.submitProject({
                        ...values,
                        parishId,
                        changeCluster
                    })
                );
            }
        },
        [dispatch, parishId, changeCluster, createProjectModalType, selectProjectId]
    );

    return (
        <BaseDialog
            open={createProjectModal.isOpen}
            onClose={handleCloseDialog}
            title={createProjectModalType === modalManagerEnumerators.TYPE_EDIT ? t('projects.createModal.edit') : t('projects.createModal.create')}
        >
            <Formik initialValues={projectsDetails} onSubmit={handleSubmitProjectForm} validationSchema={createProjectHelper.getValidationSchema()}>
                {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
                    <Form>
                        {isProjectSubmitLoading ? (
                            <Box className={classes.loadingContainer}>
                                <Loading />
                            </Box>
                        ) : (
                            <>
                                <Box className={classes.container}>
                                    <ProjectDetails
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                    <Territories />
                                    <SurveillanceArea />
                                </Box>

                                <div className={classes.actions}>
                                    <Button onClick={handleCloseDialog} color="secondary" variant="contained">
                                        {t('cancel')}
                                    </Button>
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={numberOfCoordinates < 3 || !parishId}
                                        variant="outlined"
                                        className={classes.submitButton}
                                    >
                                        {t('save')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </BaseDialog>
    );
};

export default CreateProject;
