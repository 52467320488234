import moment from 'moment';

export const DATE_TIME_FORMATS = {
    DATE_DOTS: 'DD.MM.YYYY',
    DATE_SHORT: 'MM/DD',
    DATE_TIME_DOTS: 'DD.MM.YYYY - HH:mm'
};

export const dateSeparatorByDots = (date) => {
    return moment(date).format(DATE_TIME_FORMATS.DATE_DOTS);
};

export const shortDate = (date) => {
    return moment(date).format(DATE_TIME_FORMATS.DATE_SHORT);
};

export const dateTimeSeparatorByDots = (date) => {
    return moment(date).format(DATE_TIME_FORMATS.DATE_TIME_DOTS);
};
