/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme, Dialog, DialogContent } from '@material-ui/core';
import useStyles from './ImageLightbox.styles';

const propTypes = {
    open: PropTypes.bool.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    onClose: PropTypes.func.isRequired
};

const defaultProps = {
    alt: ''
};

const ImageLightbox = ({ open, src, alt, onClose }) => {
    const theme = useTheme();
    const classes = useStyles();
    const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));

    if (!src) return null; // this is needed as the src can disappear before closing animation finishes

    return (
        <Dialog fullWidth={fullWidth} maxWidth="lg" open={open} onClose={onClose} aria-labelledby="image-preview">
            <DialogContent className={classes.container}>
                <img className={classes.image} src={src} alt={alt} />
            </DialogContent>
        </Dialog>
    );
};

ImageLightbox.propTypes = propTypes;
ImageLightbox.defaultProps = defaultProps;

export default ImageLightbox;
