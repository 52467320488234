import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../../constants';
import { STATUSES_MAP_CONSTANTS } from '../../../../utils/project-status';
import * as actionTypes from './actionTypes';
import * as projectFormActionTypes from '../../../form/project/actionTypes';

const initialState = {
    isLoading: false,
    isLoadingClassification: false,
    isLoadingDelete: false,
    project: null
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PROJECT_SUCCESS:
        case actionTypes.GET_PROJECT_FAIL:
            return false;
        case actionTypes.GET_PROJECT_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const isLoadingClassification = (state = initialState.isLoadingClassification, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SET_LEGAL_CLASSIFICATION_SUCCESS:
        case actionTypes.SET_LEGAL_CLASSIFICATION_FAIL:
        case actionTypes.SET_ILLEGAL_CLASSIFICATION_SUCCESS:
        case actionTypes.SET_ILLEGAL_CLASSIFICATION_FAIL:
            return false;
        case actionTypes.SET_LEGAL_CLASSIFICATION_START:
        case actionTypes.SET_ILLEGAL_CLASSIFICATION_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoadingClassification;
        default:
            return state;
    }
};

export const isLoadingDelete = (state = initialState.isLoadingDelete, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.DELETE_PROJECT_SUCCESS:
        case actionTypes.DELETE_PROJECT_FAIL:
            return false;
        case actionTypes.DELETE_PROJECT_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoadingDelete;
        default:
            return state;
    }
};

export const project = (state = initialState.project, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SET_LEGAL_CLASSIFICATION_SUCCESS:
            return {
                ...state,
                status: state.id === action.payload.id ? STATUSES_MAP_CONSTANTS.LEGAL : state.status
            };
        case actionTypes.SET_ILLEGAL_CLASSIFICATION_SUCCESS:
            return {
                ...state,
                status: state.id === action.payload.id ? STATUSES_MAP_CONSTANTS.ILLEGAL : state.status
            };
        case projectFormActionTypes.UPDATE_SELECT_PROJECT:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.GET_PROJECT_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.project;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getIsLoadingClassification = state => get(state, 'isLoadingClassification', initialState.isLoadingClassification);

export const getIsLoadingDelete = state => get(state, 'isLoadingDelete', initialState.isLoadingClassification);

export const getProject = state => get(state, 'project', initialState.project);

const reducer = combineReducers({
    isLoading,
    project,
    isLoadingClassification,
    isLoadingDelete
});

export default reducer;
