import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@material-ui/core';

import useStyles from './CollapsibleListItemHeader.styles';
import { LIST_ITEM_HEADER } from '../enumerators';

const propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            title: PropTypes.string,
            width: PropTypes.string
        })
    ).isRequired,
    children: PropTypes.node.isRequired,
    isCollapseOpen: PropTypes.bool
};

const defaultProps = {
    isCollapseOpen: false
};

const CollapsibleListItemHeader = ({headers, children, isCollapseOpen}) => {
    const classes = useStyles();

    const getHeaderWidthByKey = (key) => headers.find(h => h.key === key)?.width;

    const getStyles = (width) => width ? { maxWidth: width } : {};

    const isColumnVisible = (child) => {
        if (!isCollapseOpen) {
            return true;
        }
        return !(headers.find(h => h.key === child.key)?.hideOnOpen);
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            className={classes.root}
        >
            { children && children.filter(isColumnVisible).map((child) => (
                <Box
                    key={child.key}
                    className={classnames(classes.childElement, {
                        fixed: !!getHeaderWidthByKey(child.key)
                    })}
                    style={getStyles(getHeaderWidthByKey(child.key))}
                >
                    {child}
                </Box>
            )) }
        </Box>
    );
};

CollapsibleListItemHeader.displayName = LIST_ITEM_HEADER;
CollapsibleListItemHeader.propTypes = propTypes;
CollapsibleListItemHeader.defaultProps = defaultProps;

export default CollapsibleListItemHeader;
