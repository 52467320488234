import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { keyBy } from 'lodash';
import {
    actions as modalManagerActions,
    selectors as modalManagerSelectors,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';
import { actions, selectors as formAlbumSelectors } from '../../../redux/form/album';
import { selectors as projectSelectors } from '../../../redux/projects/project/details';
import createAlbumHelper from './helper';

import BaseDialog from '../../base-dialog';
import Loading from '../../base-loading';
import BaseInput from '../../base-input';
import useStyles from './CreateAlbum.styles';
import FilesList from './files-list';

const CreateAlbum = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const createAlbumModal = useSelector(modalManagerSelectors.getCreateAlbum);
    const albumDetails = useSelector(formAlbumSelectors.getAlbumDetails);
    const isLoading = useSelector(formAlbumSelectors.getIsLoading);
    const projectId = useSelector(projectSelectors.getProjectId);
    const modalType = useSelector(modalManagerSelectors.getCreateAlbumType);
    const isEditMode = modalType === modalManagerEnumerators.TYPE_EDIT;

    const modalTitle = isEditMode ? t('projects.albums.modal.editAlbum') : t('projects.albums.modal.newAlbum');

    const onSubmit = useCallback(
        values => {
            if (isLoading) return;

            if (isEditMode) {
                const { id: albumId, name, comments, files } = albumDetails;

                const newFilesById = keyBy(values.files, 'id');
                const filesToRemove = files.filter(({ id }) => typeof newFilesById[id] === 'undefined').map(({ id }) => id);

                dispatch(
                    // we only want to send properties that have been updated
                    actions.updateAlbum(projectId, {
                        ...(name !== values.name && { name: values.name }),
                        ...(comments !== values.comments && { comments: values.comments }),
                        files: values.files,
                        filesToRemove,
                        albumId
                    })
                );
            } else {
                dispatch(actions.submitAlbum(projectId, values));
            }
        },
        [dispatch, projectId, isLoading, isEditMode, albumDetails]
    );

    const handleCloseDialog = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CREATE_ALBUM));
    }, [dispatch]);

    const ModalLoading = () => (
        <Box className={classes.loadingContainer}>
            <Loading />
        </Box>
    );

    return (
        <BaseDialog open={createAlbumModal.isOpen} onClose={handleCloseDialog} title={modalTitle}>
            <Formik initialValues={albumDetails} onSubmit={onSubmit} validationSchema={createAlbumHelper.getValidationSchema({ isEditMode })}>
                {({ errors, touched, values, dirty, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <Form className={classes.container}>
                        {isLoading ? (
                            <ModalLoading />
                        ) : (
                            <Box className={classes.fieldsContainer}>
                                <BaseInput
                                    label={t('projects.albums.modal.name')}
                                    id="name"
                                    name="name"
                                    required
                                    disabled={isLoading}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!(errors.hasOwnProperty('name') && touched.hasOwnProperty('name'))}
                                />

                                <BaseInput
                                    label={t('projects.albums.modal.comments')}
                                    id="comments"
                                    name="comments"
                                    required
                                    disabled={isLoading}
                                    value={values.comments}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!(errors.hasOwnProperty('comments') && touched.hasOwnProperty('comments'))}
                                />

                                <FilesList
                                    files={values.files}
                                    isRequired={!isEditMode}
                                    handleFilesChange={files => setFieldValue('files', files)}
                                    error={!!(errors.hasOwnProperty('files') && touched.hasOwnProperty('files'))}
                                />
                            </Box>
                        )}
                        <div className={classes.actions}>
                            <Button onClick={handleCloseDialog} color="secondary" variant="contained">
                                {t('cancel')}
                            </Button>
                            <Button onClick={handleSubmit} disabled={isLoading || !dirty} variant="outlined" className={classes.submitButton}>
                                {t('save')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </BaseDialog>
    );
};

export default CreateAlbum;
