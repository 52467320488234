import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Typography, Box } from '@material-ui/core';

import { shortDate } from '../../../utils/date-time';

import { ProjectStatusBadge } from '../../../components/project-status';

import useStyles from './ProjectTile.styles';

const propTypes = {
    project: PropTypes.shape({
        name: PropTypes.string.isRequired,
        address: PropTypes.string,
        postalCode: PropTypes.string,
        status: PropTypes.number.isRequired,
        createdAt: PropTypes.string
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool
};

const defaultProps = {
    isSelected: false
};

const ProjectTile = ({ project, onClick, isSelected }) => {
    const classes = useStyles();

    const onClickHandler = ev => {
        ev.stopPropagation();
        onClick(project);
    };

    return (
        <Box className={classnames(classes.root, { selected: isSelected })} onClick={onClickHandler}>
            <div className={classes.details}>
                <Typography variant="caption" component="p" className={classes.label}>
                    {project.name}
                </Typography>
                {(project.address || project.postalCode) && (
                    <Typography variant="caption" component="p" className={classes.label}>
                        {`${project.address ?? ''}${project.address ? ',' : ''} ${project.postalCode ?? ''}`}
                    </Typography>
                )}
                <ProjectStatusBadge status={project.status} />
            </div>
            <div className={classes.actions}>
                {project.createdAt && (
                    <Typography variant="caption" component="p" className={classes.label}>
                        {shortDate(project.createdAt)}
                    </Typography>
                )}
            </div>
        </Box>
    );
};

ProjectTile.propTypes = propTypes;
ProjectTile.defaultProps = defaultProps;

export default ProjectTile;
