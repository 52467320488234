import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        background: theme.palette.background.white,
        boxShadow: theme.customShadows.medium,
        padding: '1.5rem 2rem 0.5rem 3.125rem',
        marginBottom: '0.5rem'
    },
    header: {
        display: 'flex',
        marginBottom: '0.75rem'
    },
    title: {
        flex: 1,
        color: theme.palette.text.tabsSubtitles
    },
    actionBtn: {
        padding: 0
    },
    mapContainer: {
        margin: 0,
        height: '20rem'
    }
}));
