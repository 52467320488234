import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import useStyles from './LandingBackground.styles';

const propTypes = {
    children: PropTypes.node.isRequired
};

const LandingBackground = ({ children }) => {
    const classes = useStyles();

    return <Box className={classes.imageContainer}>{children}</Box>;
};

LandingBackground.propTypes = propTypes;

export default LandingBackground;
