import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        margin: '1rem'
    },
    ul: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default useStyles;
