import config from '../../../_config';

const { tenantSlugBaseUrl } = config;

const baseUrl = '/projects';
const baseUrlWithSlug = `${tenantSlugBaseUrl}${baseUrl}`;

const projectTimelineUrl = projectId => `${baseUrlWithSlug}/${projectId}/timeline`;

const list = (client, projectId) => {
    const url = `${projectTimelineUrl(projectId)}`;

    return client.get(url, {});
};

export default client => ({
    list: projectId => list(client, projectId)
});
