/* eslint-disable react/prop-types */
import React from 'react';

const LayoutStateContext = React.createContext();
const LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
    switch (action.type) {
        case 'OPEN_SIDEBAR':
            return { ...state, isSidebarOpened: action.payload };
        case 'TOGGLE_SIDEBAR':
            return { ...state, isSidebarOpened: !state.isSidebarOpened };
        case 'SET_FLOATING_SIDEBAR':
            return { ...state, isFloatingSidebar: action.payload };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function LayoutProvider({ children }) {
    const [state, dispatch] = React.useReducer(layoutReducer, {
        isSidebarOpened: false,
        isFloatingSidebar: false
    });

    return (
        <LayoutStateContext.Provider value={state}>
            <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
        </LayoutStateContext.Provider>
    );
}

function useLayoutState() {
    const context = React.useContext(LayoutStateContext);
    if (context === undefined) {
        throw new Error('useLayoutState must be used within a LayoutProvider');
    }
    return context;
}

function useLayoutDispatch() {
    const context = React.useContext(LayoutDispatchContext);
    if (context === undefined) {
        throw new Error('useLayoutDispatch must be used within a LayoutProvider');
    }
    return context;
}

function toggleSidebar(dispatch) {
    dispatch({
        type: 'TOGGLE_SIDEBAR'
    });
}

function openSidebar(dispatch) {
    dispatch({
        type: 'OPEN_SIDEBAR',
        payload: true
    });
}

function setFloatingSideBar(dispatch, payload) {
    dispatch({
        type: 'SET_FLOATING_SIDEBAR',
        payload
    });
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, openSidebar, setFloatingSideBar };
