const reducerName = '@cityoo-tenant/form/project/details';

export const RESET = `${reducerName}/RESET`;
export const UPDATE_SELECT_PROJECT = `${reducerName}/UPDATE_SELECT_PROJECT`;

export const SUBMIT_TERRITORY = `${reducerName}/SUBMIT_TERRITORY`;
export const REMOVE_TERRITORY = `${reducerName}/REMOVE_TERRITORY`;

export const INSERT_COORDINATES = `${reducerName}/INSERT_COORDINATES`;
export const REMOVE_COORDINATES = `${reducerName}/REMOVE_COORDINATES`;
export const SUBMIT_PARISH_ID = `${reducerName}/SUBMIT_PARISH_ID`;

export const SUBMIT_PROJECT_START = `${reducerName}/SUBMIT_PROJECT_START`;
export const SUBMIT_PROJECT_SUCCESS = `${reducerName}/SUBMIT_PROJECT_SUCCESS`;
export const SUBMIT_PROJECT_FAIL = `${reducerName}/SUBMIT_PROJECT_FAIL`;
