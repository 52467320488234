import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../notifications';

export const getParishes = countyId => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_PARISHES_START });

    try {
        const response = await apiSdk.territories.getParishes(countyId);
        const { data } = response;

        dispatch({ type: actionTypes.GET_PARISHES_SUCCESS, payload: data });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchParish'));
        dispatch({ type: actionTypes.GET_PARISHES_FAIL });
    }
};

export const reset = () => ({
    type: actionTypes.RESET
});
