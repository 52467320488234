import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardActionArea, CardMedia, Typography, Box, Hidden } from '@material-ui/core';
import useStyles from './ImageGrid.styles';

const propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            fileUrl: PropTypes.string,
            filename: PropTypes.string,
            caption: PropTypes.string
        })
    ).isRequired,
    imageRatio: PropTypes.oneOf(['wide', 'square']),
    hasCaptions: PropTypes.bool,
    hasBorderRadius: PropTypes.bool,
    hasIndex: PropTypes.bool,
    onGridItemClick: PropTypes.func,
    gridItemSizes: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number
    })
};

const defaultProps = {
    imageRatio: 'wide',
    hasCaptions: false,
    hasBorderRadius: false,
    hasIndex: false,
    onGridItemClick: null,
    gridItemSizes: {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2
    }
};

const ImageGrid = ({ images, imageRatio, hasCaptions, hasBorderRadius, hasIndex, onGridItemClick, gridItemSizes }) => {
    const classes = useStyles();
    const mediaClasses = `${classes.media} ${classes[imageRatio]}`;

    return (
        <div className={classes.container}>
            <Grid container spacing={3}>
                {images.map((image, index) => (
                    <Grid key={image.filename} item {...gridItemSizes}>
                        <Card className={!hasBorderRadius ? classes.noBorderRadius : ''}>
                            <CardActionArea onClick={() => onGridItemClick(image)}>
                                <CardMedia className={mediaClasses} image={image.fileUrl} title={image.filename} />
                            </CardActionArea>
                        </Card>
                        {hasCaptions && (
                            <Hidden mdDown>
                                <Box display="flex" justifyContent="center">
                                    <Typography className={classes.caption}>{`${hasIndex ? `${index + 1}.` : ''} ${image.caption}`}</Typography>
                                </Box>
                            </Hidden>
                        )}
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

ImageGrid.propTypes = propTypes;
ImageGrid.defaultProps = defaultProps;

export default ImageGrid;
